import React from "react"
import { useLocation, Navigate, Outlet } from "react-router-dom"
import { useSelector } from "react-redux"
import { selectCurrentToken, selectCurrentUser } from "../redux/auth/slice"
import { authentiCateUserRole } from "helpers"

const RequireAuth = props => {
  const token = useSelector(selectCurrentToken)
  const user = useSelector(selectCurrentUser)
  const location = useLocation()

  if (!token || !user) {
    return (
      <Navigate
        to={{ pathname: "/login" }}
        state={{ from: location }}
        replace
      />
    )
  }

  if (!authentiCateUserRole(user.role, props.userRole)) {
    const previousPath = location.state?.from || "/"
    return (
      <Navigate
        to={{ pathname: previousPath }}
        state={{ from: location.pathname }}
        replace
      />
    )
  }

  return <React.Fragment>{props.children}</React.Fragment>
}
export default RequireAuth
