import { showToaster } from "helpers";
import { apiSlice } from "../api"
const API_URL_PREFIX = "export-request"
export const exportRequestApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    exportRequestList: builder.query({
      query: (payload) => ({
        url: `/${API_URL_PREFIX}/files`,
        method: "POST",
        body: { ...payload },
      }),
    }),
    exportCSVDownload: builder.mutation({
      query: ({ userID,
        fileID,
        createDate,
        Type, }) =>
      ({
        url: `/${API_URL_PREFIX}/files/${Type}/${userID}/${fileID}`,
        method: "POST",
        responseHandler: async response => {
          if (response.status !== 200) {
            if (response.status == 404) {
              return;
            } else {
              showToaster("error", 'Unable to download !', 'Error');
              return;
            }
          }
          const link = document.createElement("a")
          link.href = URL.createObjectURL(await response.blob());
          link.download = `${createDate}-${Type}-${Date.now()}.csv`;
          document.body.append(link)
          link.click()
          link.remove();
        },
      }),
    }),
  }),
})

export const { useExportRequestListQuery, useExportCSVDownloadMutation } = exportRequestApiSlice
