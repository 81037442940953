import React from "react";
import {
  Col,
  Container, Row,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Helmet } from "react-helmet";
import MiniCard from "./MiniCard";
import EmailsWithStatus from "./Chart/EmailsWithStatus";
import Users from "./Chart/Users";
import Campaign from "./Chart/Campaign";
import File from "./Chart/File";
import { selectEmailRecords } from "redux/dashboard/slice";
import { useSelector } from "react-redux";
import { authentiCateUserRole } from "helpers";
import { selectCurrentUser } from "redux/auth/slice";
import config from "../../config";
import "./dashboard.scss"
const Dashboard = props => {
  const user = useSelector(selectCurrentUser);
  const isRootUser = authentiCateUserRole(user.role, config.userRoles.root.key);
  const { validationCampaign, scrapingCampaign, admin, emails, url, mailWizz } = useSelector(selectEmailRecords);
  const BreadcrumbDetails = {
    breadcrumbItem: {
      label: 'Dashboard',
      link: 'dashboard'
    },
    paths: []
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Dashboard | Email-Hunter</title>
      </Helmet>

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            breadcrumbDetails={BreadcrumbDetails}
          />
          <Row>
            <Col xl={12} lg={12}>
              <Row>
                <MiniCard
                  title="Email Validation"
                  iconClass="mdi mdi-email-check"
                  text={validationCampaign}
                  color={"primary"}
                />
                <MiniCard

                  title="Email Scrapping"
                  iconClass="mdi mdi-email-search"
                  text={scrapingCampaign}
                  color={"primary"}
                />
                {isRootUser &&
                  <MiniCard
                    title="Admins"
                    iconClass="mdi mdi-account-group"
                    text={admin}
                    color={"primary"}
                  />}
                <MiniCard
                  title="Emails"
                  iconClass="mdi mdi-email"
                  text={emails}
                  color={"primary"}
                />
                <MiniCard
                  title="Urls "
                  iconClass="mdi mdi-link-variant"
                  text={url}
                  color={"primary"}
                />
                <MiniCard
                  title="MailWizz"
                  iconClass="bx-mail-send"
                  text={mailWizz}
                  color={"dark"}
                />
              </Row>
            </Col>
            <Col lg="12" xl='6' sm="12" >
              <EmailsWithStatus />
            </Col>
            <Col lg="12" xl='6' sm="12">
              <File />
            </Col>
            <Col lg="12" xl='12' sm="12">
              <Campaign />
            </Col>
            {isRootUser &&
              <Col lg="12" xl='12' sm="12">
                <Users />
              </Col>}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};


export default Dashboard;
