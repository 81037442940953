import { createSlice } from "@reduxjs/toolkit"

const exportRequestSlice = createSlice({
    name: 'exportRequest',
    initialState: { list: [], totalRecord: 0 },
    reducers: {
        setExportRequestList: (state, action) => {
            const { files, count } = action.payload;
            state.list = files
            state.totalRecord = count
        },
    },
})
export const { setExportRequestList } = exportRequestSlice.actions;
export default exportRequestSlice.reducer;

export const selectExportRequestList = (state) => state.exportRequest.list;
export const selectExportRequestTotalRecords = (state) => state.exportRequest.totalRecord;