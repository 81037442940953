import React, { useState } from "react"

import {
  Row,
  Col,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
  Button,
  Spinner,
} from "reactstrap"

import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// actions
import { useForgotPasswordMutation } from "redux/auth/api"
import logodark from "../../assets/images/email-op-4.png"
import logolight from "../../assets/images/logo-light.png"
import { Helmet } from "react-helmet"
import CarouselPage from "./CarouselPage"
import { showError, showToaster } from "helpers"
import { useNavigate } from "react-router-dom"
import { EMAIL_REGEX } from "constants/regex"

const ForgotPassword = props => {
  const [forgotPassword, forgotPasswordAPI] = useForgotPasswordMutation()
  const navigate = useNavigate();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .matches(/^\S*$/, "Whitespace is not allowed")
        .required("Please enter your email")
        .email("Please enter valid email")
        .matches(EMAIL_REGEX, "Please enter valid email"),
    }),
    onSubmit: async values => {
      try {
        const result = await forgotPassword(values).unwrap()
        showToaster("success", result.message, 'Success');
        navigate("/login", { replace: true });
      } catch (error) {
        showError(error)
      }
    },
  })

return (
    <React.Fragment>
      <Helmet>
        <title>Forgot Password | Email Hunter</title>
      </Helmet>

      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />

            <Col xl={3}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5">
                      <Link to="dashboard" className="d-block auth-logo">
                        <img
                          src={logodark}
                          alt=""
                          height="18"
                          className="auth-logo-dark"
                        />
                        <img
                          src={logolight}
                          alt=""
                          height="18"
                          className="auth-logo-light"
                        />
                      </Link>
                    </div>
                    <div className="my-auto">
                      <div>
                        <h5 className="text-primary">Reset Password</h5>
                        <p className="text-muted">Re-Password with Skote.</p>
                      </div>

                      <div className="mt-4">
                        <div
                          className="alert alert-success text-center mb-4"
                          role="alert"
                        >
                          Enter your Email and instructions will be sent to you!
                        </div>

                        <Form
                          className="form-horizontal"
                          onSubmit={e => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                          }}
                        >
                          <div className="mb-3">
                            <Label className="form-label">Email</Label>
                            <Input
                              name="email"
                              className="form-control"
                              placeholder="Enter email"
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email &&
                                validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mt-3 text-end">
                            <Button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                              disabled={forgotPasswordAPI.isLoading}
                            >
                              {!forgotPasswordAPI.isLoading ? (
                                "Reset"
                              ) : (
                                <>
                                  <span>Sending Mail...</span>
                                  <Spinner size="sm"></Spinner>
                                </>
                              )}
                            </Button>
                          </div>
                        </Form>
                        <div className="mt-5 text-center">
                          <p>
                            Remember It ?{" "}
                            <Link
                              to="/login"
                              className="fw-medium text-primary"
                            >
                              {" "}
                              Sign In here{" "}
                            </Link>{" "}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} Email Hunter. Built with{" "}
                        <i className="mdi mdi-heart text-danger"></i> by
                        Webcodegenie
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ForgotPassword)
