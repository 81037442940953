import { showError, showToaster } from "helpers"
import PropTypes from "prop-types"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Modal, ModalBody, Button, Spinner } from "reactstrap"
import { useUserUpdateMutation } from "redux/users/api"
import { selectUser, setUser } from "redux/users/slice"

const StatusChangeModal = ({ show, setShow, setLoadList }) => {
  const dispatch = useDispatch()
  const user = useSelector(selectUser)
  const [update, UpdateAPI] = useUserUpdateMutation()

  const onCloseClick = () => {
    setShow(false)
    dispatch(setUser(null))
  }

  const onConfirm = async () => {
    try {
      const result = await update({
        id: user._id,
        payload: { active: !user.active },
      }).unwrap()
      showToaster(
        "success",
        `User Successfully ${user.active ? "In-active" : "Activated"}`,
        "Success"
      )
      setLoadList(state => !state)
      setShow(false)
      dispatch(setUser(null))
    } catch (error) {
      showError(error)
    }
  }

  return (
    <Modal
      size="md"
      isOpen={show}
      toggle={onCloseClick}
      centered={true}
      keyboard={false}
      backdrop={"static"}
    >
      <div className="modal-content">
        <ModalBody className="px-4 py-5 text-center">
          <button
            type="button"
            onClick={onCloseClick}
            className="btn-close position-absolute end-0 top-0 m-3"
          ></button>
          <p className="font-size-16 mb-4">
            <h3>{user && user.active ? "In-active User" : "Active User"}</h3>
          </p>
          <p className="text-muted font-size-16 mb-4">
            Are you sure you want to{" "}
            {user && user.active ? "In-active" : "Activate"} this user?
          </p>

          <div className="hstack gap-2 justify-content-center mb-0">
            <Button
              type="button"
              color={user && user.active ? "danger" : "success"}
              onClick={onConfirm}
              disabled={UpdateAPI.isLoading}
            >
              {!UpdateAPI.isLoading ? (
                user && user.active ? (
                  "Yes, In-active Now"
                ) : (
                  "Yes, Activate Now"
                )
              ) : (
                <>
                  <span>{"Saving Changes..."}</span>
                  <Spinner size="sm"></Spinner>
                </>
              )}
            </Button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onCloseClick}
            >
              Cancel
            </button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default StatusChangeModal
