import { apiSlice } from "../api"
import axios from "axios"
import config from "../../config"
import { setFileUploadProgress } from "./slice"
const API_URL_PREFIX = "campaign"
export const campaignsApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    campaignCreate: builder.mutation({
      query: payload => ({
        url: `/${API_URL_PREFIX}/create`,
        method: "POST",
        body: { ...payload },
      }),
    }),

    campaignUpdate: builder.mutation({
      queryFn: async ({ id, payload }, api) => {
        try {
          const token = api.getState().auth.token
          const result = await axios.post(
            `${config.API_URL}/${API_URL_PREFIX}/update/${id}`,
            payload,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              onUploadProgress: upload => {
                //Set the progress value to show the progress bar
                const uploadloadProgress = Math.round(
                  (100 * upload.loaded) / upload.total
                )
                api.dispatch(setFileUploadProgress(uploadloadProgress));
              },
            }
          )
          api.dispatch(setFileUploadProgress(0));
          return { data: result.data }
        } catch (error) {
          api.dispatch(setFileUploadProgress(0));
          throw error
        }
      },
    }),

    campaignList: builder.query({
      query: payload => ({
        url: `/${API_URL_PREFIX}/list`,
        method: "POST",
        body: { ...payload },
      }),
    }),

    campaignGet: builder.mutation({
      query: id => ({
        url: `/${API_URL_PREFIX}/get/${id}`,
        method: "GET",
      }),
    }),

    campaignFilter: builder.mutation({
      query: (name) => ({
        url: `/${API_URL_PREFIX}/filter?name=${name}`,
        method: "GET",
      }),
    }),

    campaignCheckVerificationStatus: builder.mutation({
      query: (id) => ({
        url: `/${API_URL_PREFIX}/check-verification-status/${id}`,
        method: "GET",
      }),
    }),

    campaignGetEmailStats: builder.mutation({
      query: id => ({
        url: `/${API_URL_PREFIX}/email-stats/${id}`,
        method: "GET",
      }),
    }),

  }),
})

export const {
  useCampaignCreateMutation,
  useCampaignUpdateMutation,
  useCampaignListQuery,
  useCampaignGetMutation,
  useCampaignFilterMutation,
  useCampaignCheckVerificationStatusMutation,
  useCampaignGetEmailStatsMutation
} = campaignsApiSlice
