import React, { Fragment, useEffect, useMemo, useState } from "react"
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  useFilters,
  usePagination,
} from "react-table"

import {
  Table,
  Row,
  Col,
  Button,
  Input,
  UncontrolledTooltip,
  Badge,
} from "reactstrap"
import {
  Filter,
  DefaultColumnFilter,
  SelectColumnFilter,
  GlobalFilter,
} from "../Filters"
import {
  FirstName,
  LastName,
  Email,
  ActivationStatus,
} from "../ColumnFormatters"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import {
  selectTotalRecordsCount,
  selectUsersList,
  setUsersList,
} from "redux/users/slice"
import { showError } from "helpers"
import { useUserListQuery } from "redux/users/api"
import LoadingSpinner from "components/Common/LoadingSpinner"
import { TableRangeFooterLabel } from "components/Common/TableRangeFooterLabel"

const List = ({
  load,
  onEditUser,
  onArchiveUser,
  onUserStatusChange,
  onPasswordChange,
  onSwitchUser
}) => {
  const data = useSelector(selectUsersList)
  const totalRecords = useSelector(selectTotalRecordsCount)
  const dispatch = useDispatch()
  const [pSize, setPSize] = useState(10)
  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "_id",
        // filterable: true,
        Cell: cellProps => {
          return <FirstName {...cellProps} />
        },
      },
      {
        Header: "First Name",
        accessor: "firstname",
        filterable: true,
        Cell: cellProps => {
          return <FirstName {...cellProps} />
        },
      },
      {
        Header: "Last Name",
        accessor: "lastname",
        filterable: true,
        Cell: cellProps => {
          return <LastName {...cellProps} />
        },
      },
      {
        Header: "Email",
        accessor: "email",
        filterable: true,
        Cell: cellProps => {
          return <Email {...cellProps} />
        },
      },
      {
        Header: "Status",
        accessor: "active",
        Filter: SelectColumnFilter,
        Cell: cellProps => {
          const user = cellProps.row.original
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  onUserStatusChange(user)
                }}
              >
                {user.active ? (
                  <>
                    <Badge
                      className={"font-size-12 badge-soft-success"}
                      id="activestatustooltip"
                    >
                      Active
                    </Badge>
                    <UncontrolledTooltip
                      placement="top"
                      target="activestatustooltip"
                    >
                      Make user In-active
                    </UncontrolledTooltip>
                  </>
                ) : (
                  <>
                    <Badge
                      className={"font-size-12 badge-soft-danger"}
                      id="inactivestatustooltip"
                    >
                      In-active
                    </Badge>
                    <UncontrolledTooltip
                      placement="top"
                      target="inactivestatustooltip"
                    >
                      Make user active
                    </UncontrolledTooltip>
                  </>
                )}
              </Link>
            </div>
          )
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        disableSortBy: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const user = cellProps.row.original
                  onEditUser(user._id)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const user = cellProps.row.original
                  onArchiveUser(user._id)
                }}
              >
                <i
                  className="mdi mdi-archive-arrow-down font-size-18"
                  id="archivetooltip"
                />
                <UncontrolledTooltip placement="top" target="archivetooltip">
                  Archive User
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-primary"
                onClick={() => {
                  const user = cellProps.row.original

                  onPasswordChange(user._id)
                }}
              >
                <i
                  className="mdi mdi-lock-reset font-size-18"
                  id="changepasswordtooltip"
                />
                <UncontrolledTooltip
                  placement="top"
                  target="changepasswordtooltip"
                >
                  Change Password
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-primary"
                onClick={() => {
                  const user = cellProps.row.original
                  onSwitchUser(user._id)
                }}
              >
                <i
                  className="mdi mdi-account-arrow-left font-size-18"
                  id={`id${cellProps.row.original._id}`}
                />
                <UncontrolledTooltip
                  placement="top"
                  target={`id${cellProps.row.original._id}`}
                >
                 {'Switch as ' + cellProps.row.original.firstname+' ' +cellProps.row.original.lastname}
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    visibleColumns,
    state: { pageIndex, pageSize, globalFilter, filters },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        sortBy: [
          {
            desc: true,
            id: "_id",
          },
        ],
        hiddenColumns: ["_id"],
      },
      autoResetFilters: false,
      autoResetGlobalFilter: false,
      autoResetSortBy: false,
      manualPagination: true,
      manualSortBy: true,
      manualFilters: true,
      autoResetPage: false,
      pageCount: Math.ceil((totalRecords || 10) / pSize),
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  )

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }

  const onChangeInPageSize = event => {
    setPageSize(Number(event.target.value))
    setPSize(Number(event.target.value))
  }

  const onGoToPage = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    gotoPage(page)
  }

  useEffect(() => {
    if (!data.length && totalRecords && pageIndex > 0) {
      gotoPage(pageIndex - 1)
    }
  }, [data, totalRecords, gotoPage])

  const list = useUserListQuery(state)

  if (list.isSuccess) {
    dispatch(setUsersList(list.data.data))
  }

  if (list.isError) {
    showError(list.error)
  }

  useEffect(() => {
    list.refetch()

    return () => {
      dispatch(
        setUsersList({
          list: [],
          count: 0,
        })
      )
    }
  }, [load, filters, state])

  return (
    <Fragment>
      <Row className="mb-2">
        <Col md={2}>
          <select
            className="form-select"
            value={pageSize}
            onChange={onChangeInPageSize}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
      </Row>

      <div className="table-responsive react-table">
        <Table bordered hover {...getTableProps()}>
          <thead className="table-light table-nowrap">
            <tr>
              <th
                colSpan={visibleColumns.length}
                style={{
                  textAlign: "left",
                }}
              >
                <GlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={state.globalFilter}
                  setGlobalFilter={setGlobalFilter}
                />
              </th>
            </tr>

            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.id}>
                    <div className="mb-2" {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                    <Filter column={column} />
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {(list.isFetching || list.isLoading) && (
              <tr>
                <td
                  colSpan={visibleColumns.length}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <LoadingSpinner />
                </td>
              </tr>
            )}

            {!page.length && !list.isFetching && !list.isLoading && (
              <tr>
                <td
                  colSpan={visibleColumns.length}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <p>
                    {" "}
                    No Users Found{" "}
                    <span
                      style={{
                        fontSize: "100px;",
                      }}
                    >
                      &#128581;
                    </span>
                  </p>
                </td>
              </tr>
            )}

            {!list.isFetching &&
              !list.isLoading &&
              page.map(row => {
                prepareRow(row)
                return (
                  <Fragment key={row.getRowProps().key}>
                    <tr>
                      {row.cells.map(cell => {
                        return (
                          <td key={cell.id} {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        )
                      })}
                    </tr>
                  </Fragment>
                )
              })}
          </tbody>
        </Table>
      </div>

      {totalRecords ? (
        <Row className="justify-content-md-end justify-content-center align-items-center">
          <Col className="col justify-content-start">
            <TableRangeFooterLabel
              pageIndex={pageIndex}
              pageSize={pageSize}
              totalRecords={totalRecords}
              label={"users"}
            />
          </Col>
          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                color="primary"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </Button>
              <Button
                color="primary"
                onClick={previousPage}
                disabled={!canPreviousPage}
              >
                {"<"}
              </Button>
            </div>
          </Col>
          <Col className="col-md-auto d-none d-md-block">
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </Col>
          <Col className="col-md-auto">
            <Input
              type="number"
              min={1}
              style={{ width: 70 }}
              max={pageOptions.length}
              defaultValue={pageIndex + 1}
              onChange={onGoToPage}
            />
          </Col>
          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                color="primary"
                onClick={nextPage}
                disabled={!canNextPage}
              >
                {">"}
              </Button>
              <Button
                color="primary"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </Button>
            </div>
          </Col>
        </Row>
      ) : <></>}
    </Fragment>
  )
}
export default List
