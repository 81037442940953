import { createSlice } from "@reduxjs/toolkit"

//constants
import {
    layoutTypes,
    layoutModeTypes,
    layoutWidthTypes,
    topBarThemeTypes,
    leftBarThemeImageTypes,
    leftSidebarTypes,
    leftSideBarThemeTypes,
} from "../../constants/layout";

const INIT_STATE = {
    layoutType: layoutTypes.VERTICAL,
    layoutModeType: layoutModeTypes.LIGHT,
    layoutWidth: layoutWidthTypes.FLUID,
    leftSideBarTheme: leftSideBarThemeTypes.DARK,
    leftSideBarThemeImage: leftBarThemeImageTypes.NONE,
    leftSideBarType: leftSidebarTypes.DEFAULT,
    topbarTheme: topBarThemeTypes.LIGHT,
    isPreloader: false,
    showRightSidebar: false,
    isMobile: false,
    showSidebar: true,
    leftMenu: false,
  }

const layoutSlice = createSlice({
    name: 'layout',
    initialState: INIT_STATE,
    reducers: {
        changeLayout: (state, action) => {
            state.layoutType = action.payload;
        },

        changePreloader: (state, action) => {
            state.isPreloader = action.payload;
        },

        changeLayoutMode: (state, action) => {
            state.layoutModeType = action.payload;
        },

        changeLayoutWidth: (state, action) => {
            state.layoutWidth = action.payload;
        },

        changeSidebarTheme: (state, action) => {
            state.leftSideBarTheme = action.payload;
        },

        changeSidebarThemeImage: (state, action) => {
            state.leftSideBarThemeImage = action.payload;
        },

        changeSidebarType: (state, action) => {
            state.leftSideBarType = action.payload;
        },

        changeTopbarTheme: (state, action) => {
            state.topbarTheme = action.payload;
        },

        showRightSidebarAction: (state, action) => {
            state.showRightSidebar = action.payload;
        },

        showSidebar: (state, action) => {
            state.showSidebar = action.payload;
        },

        toggleLeftmenu: (state, action) => {
            state.leftMenu = action.payload;
        }

    },
})

export const { 
    changeLayout,
    changeLayoutMode,
    changeLayoutWidth,
    changePreloader,
    changeSidebarTheme,
    changeSidebarThemeImage,
    changeSidebarType,
    changeTopbarTheme,
    showRightSidebarAction,
    showSidebar,
    toggleLeftmenu
} = layoutSlice.actions;

export default layoutSlice.reducer;