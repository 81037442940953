import config from "../config"
import toastr from "toastr"
import Swal from "sweetalert2"
const CampaignStatus = config.campaign.status;

export const showToaster = (type = "info", message, title = "", options) => {
  const opts = Object.assign({}, config.TOASTR_DEFAULT_OPTIONS, options)
  toastr.options = opts
  toastr[type](message, title)
}

export const showError = error => {
  console.log(error);
  let message =
    error.data && error.data.message
      ? error.data.message
      : "Something went wrong !"
  if (
    error.status == 400 &&
    error?.data?.validationErrors &&
    Array.isArray(error.data.validationErrors) &&
    error.data.validationErrors.length
  ) {
    message = error.data.validationErrors[0].msg || "Something went wrong"
  }
  showToaster("error", message, "Error")
}

export const authentiCateUserRole = (loggedInUserRole, requiredUserRole) => {
  const loggedInUserLevel = config.userRoles[loggedInUserRole].level
  const requiredUserLevel = config.userRoles[requiredUserRole].level
  return loggedInUserLevel <= requiredUserLevel
}

export const getColorByCampaignStatus = status => {
  const color = CampaignStatus[status]?.color || 'secondary';
  return color;
}

export const confirmAlert = (message = `You won't be able to revert/edit this, Please do confirm details first.`) => {
  return Swal.fire({
    title: "Are you sure?",
    text: message,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, do it!",
  })
}

