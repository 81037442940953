import React, { useCallback, useEffect, useMemo, useState } from "react"
import ShowMoreText from "react-show-more-text"
import { useSelector, useDispatch } from "react-redux"
import Swal from "sweetalert2"
import {
  Button,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Table,
  Spinner,
  Label,
} from "reactstrap"
import { showError, showToaster } from "helpers"
import Switch from "react-switch"
import {
  selectCampaign,
  setCampaign,
  setFileUploadProgress,
} from "redux/campaigns/slice"
import { useCampaignUpdateMutation } from "redux/campaigns/api"

function FieldMapping({ activeTab, setactiveTab, closeModal }) {
  const dispatch = useDispatch()
  const campaign = useSelector(selectCampaign)
  const [fieldMapState, setFieldMapState] = useState({})
  const [rows, setRows] = useState([[]])
  const [skipHeader, setSkipHeader] = useState(false)
  const [updateCampaign, UpdateCampaignAPI] = useCampaignUpdateMutation()

  useEffect(() => {
    dispatch(setFileUploadProgress(0))
  }, [])

  const parseRows = useCallback(rows => {
    return rows[0].map((_, colIndex) => rows.map(row => row[colIndex]))
  })

  useEffect(() => {
    if (campaign?.firstTwoRows?.length) {
      const rows = parseRows(
        campaign?.firstTwoRows?.length ? campaign?.firstTwoRows : [[]]
      )
      const defaultfieldMapState = rows.reduce(
        (prev, current, currentIndex) => {
          return { ...prev, [currentIndex]: null }
        },
        {}
      )
      setFieldMapState(defaultfieldMapState)
      setRows(rows)
    }
  }, [campaign])

  useEffect(() => {
    if (campaign?.fileFieldMapping) {
      setFieldMapState(state => {
        return {
          ...state,
          ...campaign.fileFieldMapping,
        }
      })
      setSkipHeader(campaign?.skipHeader || false)
    }
  }, [campaign?.fileFieldMapping, campaign?.skipHeader])

  const onFieldSelectionChange = event => {
    const value = event.target.value
    const index = event.target.id
    setFieldMapState(state => {
      const newState = {}
      for (const key in state) {
        if (key == index) {
          newState[key] = value
        } else {
          newState[key] = ""
        }
      }
      return newState
    })
  }

  const checkFieldSelected = () => {
    for (const key in fieldMapState) {
      if (fieldMapState[key]) {
        return {
          [key]: fieldMapState[key],
        }
      }
    }
    return false
  }

  const onBack = () => {
    setactiveTab("file-upload")
  }

  const onSubmit = async () => {
    const fieldMap = checkFieldSelected()
    if (!fieldMap) {
      return showToaster("error", "Please select field for mapping", "Error")
    }
    try {
      const data = {
        id: campaign._id,
        payload: {
          step: "file-field-mapping",
          fileFieldMapping: fieldMap,
          skipHeader: skipHeader,
        },
      }
      const result = await updateCampaign(data).unwrap()
      setactiveTab("mailwizz")
    } catch (error) {
      // console.log(error)
      showError(error)
    }
  }

  return (
    <React.Fragment>
      <Row className="justify-content-center">
        <Col lg={12}>
          <Card>
            <CardBody>
              <CardTitle className="h4">Field Mapping</CardTitle>
              <CardSubtitle className="card-title-desc">
                Please select fields according to data. If the first row is
                header, you can skip the header by cheking the below option.
              </CardSubtitle>

              <div className="table-responsive">
                <Table className="table mb-0">
                  <thead className="table-light">
                    <tr>
                      <th>Row 1</th>
                      <th>Row 2</th>
                      <th>Fields</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map((row, index) => {
                      return (
                        <tr key={index}>
                          {row.map((col, colIndex) => {
                            return (
                              <td title={col} key={colIndex}>
                                <ShowMoreText
                                  lines={3}
                                  width={220}
                                  expanded={false}
                                >
                                  {col}
                                </ShowMoreText>
                              </td>
                            )
                          })}
                          <td>
                            <div className="col-md-8">
                              <select
                                value={fieldMapState[index]}
                                className="form-control"
                                id={index}
                                onChange={onFieldSelectionChange}
                              >
                                <option value={""}>Select Field</option>
                                <option
                                  value={
                                    campaign?.type !== "email-scraping"
                                      ? "email"
                                      : "url"
                                  }
                                >
                                  {campaign?.type !== "email-scraping"
                                    ? "Email"
                                    : campaign?.subType === "email"
                                    ? "Email"
                                    : "URL"}
                                </option>
                              </select>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </div>
              <Row className="mt-3">
                <Col className="col-auto">
                  <Switch
                    className="mb-sm-8 mb-2"
                    onColor="#626ed4"
                    onChange={() => {
                      setSkipHeader(!skipHeader)
                    }}
                    checked={skipHeader}
                    name="skipHeader"
                  />
                </Col>
                <Col className="col-6 mt-2">
                  <div>
                    <Label className="form-label">
                      Do you want to skip <b>Header</b> (Row 1) ?
                    </Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col lg={12}>
          <div className="text-end">
            <Button
              className="btn btn-primary mx-3"
              color="primary"
              onClick={onBack}
              disabled={UpdateCampaignAPI.isLoading}
            >
              Previous
            </Button>
            <Button
              className="btn btn-primary save-user"
              color="primary"
              onClick={onSubmit}
              disabled={UpdateCampaignAPI.isLoading}
            >
              {!UpdateCampaignAPI.isLoading ? (
                "Save & Continue"
              ) : (
                <>
                  <span>Saving FieldMap...</span>
                  <Spinner size="sm"></Spinner>
                </>
              )}
            </Button>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default FieldMapping
