import React, { useCallback, useState } from "react"
import { Col, Row, Card, CardBody, Container, Button } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Helmet } from "react-helmet"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import List from "./List"
import { useUserGetMutation } from "redux/users/api"
import { showError } from "helpers"
import { useDispatch } from "react-redux"
import { setUser } from "redux/users/slice"
import RevokeFromArchiveModal from "../Modals/RevokeFromArchiveModal"

const ArchivedUsers = props => {
  const dispatch = useDispatch();
  const [openRevokeArchiveModal, setOpenRevokeArchiveModal] = useState(false);
  const [loadList, setLoadList] = useState(true);
  const [get, GetAPI] = useUserGetMutation();

  const onRevokeFromArchive = async (id) => {
    try {
      const result = await get(id).unwrap();
      dispatch(setUser(result.data));
      setOpenRevokeArchiveModal(true);
    } catch (error) {
      showError(error);
    }
  }

  const BreadcrumbDetails = {
    breadcrumbItem : {
      label : 'Archived',
      link : 'archived-users'
    },
    paths : [
      {  
        label: 'User',
        link: 'archived-users'
      }
    ]
  }


  return (
    <React.Fragment>

      <Helmet>
        <title>Archived Users | Email-Hunter</title>
      </Helmet>

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs breadcrumbDetails={BreadcrumbDetails}  />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <List
                    load={loadList}
                    onRevokeFromArchive={onRevokeFromArchive}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <RevokeFromArchiveModal show={openRevokeArchiveModal} setShow={setOpenRevokeArchiveModal} setLoadList={setLoadList}/>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ArchivedUsers
