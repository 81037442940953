import { apiSlice } from "../api"
const API_URL_PREFIX = "user"
export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    userList: builder.query({
      query: payload => ({
        url: `/${API_URL_PREFIX}/list`,
        method: "POST",
        body: { ...payload },
      }),
    }),

    userCreate: builder.mutation({
      query: payload => ({
        url: `/${API_URL_PREFIX}/create`,
        method: "POST",
        body: { ...payload },
      }),
    }),

    userUpdate: builder.mutation({
      query: ({ id, payload }) => {
        return {
          url: `/${API_URL_PREFIX}/update/${id}`,
          method: "PUT",
          body: { ...payload },
        }
      },
    }),

    userGet: builder.mutation({
      query: id => ({
        url: `/${API_URL_PREFIX}/get/${id}`,
        method: "GET",
      }),
    }),

    changePassowrd: builder.mutation({
      query: payload => ({
        url: `/${API_URL_PREFIX}/change-password`,
        method: "POST",
        body: { ...payload },
      }),
    }),

    userFilter: builder.mutation({
      query: (name) => ({
        url: `/${API_URL_PREFIX}/filter?search=${name}`,
        method: "GET",
      }),
    }),

    switchUser: builder.mutation({
      query: id => ({
        url: `/switch-user/${id}`,
        method: "GET",
      }),
    }),
  }),
})

export const {
  useUserListQuery,
  useUserCreateMutation,
  useUserUpdateMutation,
  useUserGetMutation,
  useChangePassowrdMutation,
  useUserFilterMutation,
  useSwitchUserMutation
} = usersApiSlice
