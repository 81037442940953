import { configureStore } from "@reduxjs/toolkit"
import { apiSlice } from "./api"
import authReducer from "./auth/slice"
import layoutReducer from "./layout/slice"
import userReducer from "./users/slice"
import campaignReducer from "./campaigns/slice"
import emailReducer from "./emails/slice"
import urlReducer from "./urls/slice"
import blackListDomainsReducer from "./black-list-domains/slice"
import urlEmailReducer from "./urlMailList/slice.js"
import dashboardReducer from "./dashboard/slice"
import exportRequestReducer from "./exportRequest/slice"
export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    layout: layoutReducer,
    users: userReducer,
    campaigns: campaignReducer,
    emails: emailReducer,
    urls: urlReducer,
    blackListDomains: blackListDomainsReducer,
    urlEmail: urlEmailReducer,
    dashboard: dashboardReducer,
    exportRequest: exportRequestReducer,

  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      apiSlice.middleware
    ),
  devTools: false,
})
