import React, {  useEffect, useState } from "react"
import { Badge, UncontrolledTooltip } from "reactstrap"
import { formatDate, wordTitleCase } from "../../helpers/data-formatters"
import config from "../../config"
import moment from "moment"
import { addDays } from "date-fns"
import FlipCountdown from '@rumess/react-flip-countdown';
// Render text in title case
const Text = cell => {
  return wordTitleCase(cell.value)
}

// Render name in title case, or '-' if no value
const Name = cell => {
  return cell.value ? (cell.value) : "-"
}

// Render type in title case, or '-' if no value
const Type = cell => {
  const icon = config.exportfile.status[cell.value].icon || ""
  return (<div >
    <i
      className={`mdi ${icon} align-middle mb-0 me-1 font-size-16`}
    />
    {config.exportfile.status[cell.value].key}
  </div>
  )

}

// Render date in formatted form
const DateColumn = ({ cellProps, expirdate }) => {
  if (!expirdate) {
    expirdate = addDays(cellProps.value, 10)
  }
  const expiryDate = moment(new Date(expirdate)).format("YYYY-MM-DD HH:mm:ss")
  const [remainingSeconds, setRemainingSeconds] = useState(null);
  const [display, setDisplay] = useState(false)
  let difference = moment(new Date(expiryDate)).diff(moment(), "seconds");
  useEffect(() => {
    // Use an effect to update 'remainingSeconds' and 'display' after 300 milliseconds
    const Timeout = setTimeout(() => {
      setRemainingSeconds(difference)
      setDisplay(true)
    }, 300);
    // Cleanup the timeout when the component unmounts or 'cellProps.value' changes
    return () => clearTimeout(Timeout);
  }, [cellProps.value])

  useEffect(() => {
    // Cleanup the timeout when the component unmounts or 'cellProps.value' changes
    if ((86400 * 2) + 600 > remainingSeconds) {
      if (remainingSeconds > 0) {
        const timerInterval = setInterval(() => {
          setRemainingSeconds(prevSeconds => prevSeconds - 1);
        }, 1000);
        // Cleanup the interval when the component unmounts or 'remainingSeconds' changes
        return () => {
          clearInterval(timerInterval);
        };
      }
    }
  }, [remainingSeconds]);
  return <div className="d-flex justify-content-between align-item-center" >
    <div className="align-self-center">
      {formatDate(cellProps.value)}
    </div>
    <div style={{ display: display && 86400 * 2 >= remainingSeconds && !(0 >= remainingSeconds) ? "block" : "none" }}>
      <UncontrolledTooltip
        placement="top"
        target={`id${cellProps?.row?.original?.file?.replace(/[-.:]/g, 'p')}`}
      >
        Expire In
      </UncontrolledTooltip>
      <div id={`id${cellProps?.row?.original?.file?.replace(/[-.:]/g, 'p')}`} style={{ display: "inline" }}>
        <FlipCountdown
          dayTitle='Days'
          hourTitle='Hours'
          minuteTitle='Min'
          secondTitle='Sec'
          hideYear
          hideMonth
          hideSecond={display ? !(remainingSeconds < 61) : false}
          hideDay={display ? (remainingSeconds < 86400) : false}
          hideMinute={display ? !(remainingSeconds < 86400) : false}
          hideHour={display ? (remainingSeconds < 61) : false}
          theme={"light"}
          size={"extra-small"}
          endAt={expiryDate} // Date/Time
          endAtZero
        />
      </div>
    </div>
    {0 >= remainingSeconds && remainingSeconds !== null && <div className="text-danger">Expired</div>}
  </div>
}
const DateFormat = cell => {
  return formatDate(cell.value)
}

// Render status as a colored badge
const Status = cell => {
  // Get the color based on the status value from the config
  const color = config.exportfile.status[cell.value].color || 'secondary'
  return (
    <Badge className={"font-size-12 badge-soft-" + color} pill>
      {/* Display the status key from the config */}
      {config.exportfile.status[cell.value].key}
    </Badge>
  )
}
export { Text, Name, DateFormat, Status, DateColumn, Type }