import { apiSlice } from "redux/api"
const API_URL_PREFIX = "dashboard"
export const dashboardApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    compaingsRecord: builder.mutation({
      query: (payload) => {
        return ({
          url: `/${API_URL_PREFIX}/campaign`,
          method: "POST",
          body: payload
        })
      },
    }),
    usersRecord: builder.mutation({
      query: (payload) => {
        return ({
          url: `/${API_URL_PREFIX}/user`,
          method: "POST",
          body: payload
        })
      },
    }),
    emailsRecord: builder.mutation({
      query: (payload) => {
        return ({
          url: `/${API_URL_PREFIX}/`,
          method: "POST",
          body: payload
        })
      },
    }),
    filesRecord: builder.mutation({
      query: (payload) => {
        return ({
          url: `/${API_URL_PREFIX}/file`,
          method: "POST",
          body: payload
        })
      },
    }),
  }),
})

export const { useCompaingsRecordMutation, useUsersRecordMutation, useEmailsRecordMutation,useFilesRecordMutation } = dashboardApiSlice 