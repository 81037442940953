import React, { useCallback, useState } from "react"
import { Col, Row, Card, CardBody, Container, Button } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Helmet } from "react-helmet"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import List from "./List"
import AddEditModal from "../Modals/AddEditModal"
import ArchiveModal from "../Modals/ArchiveModal"
import { useSwitchUserMutation, useUserGetMutation } from "redux/users/api"
import {  showError, showToaster } from "helpers"
import { useDispatch } from "react-redux"
import { setUser } from "redux/users/slice"
import StatusChangeModal from "../Modals/StatusChangeModal"
import PasswordChangeModal from "../Modals/PasswordChangeModal"
import { setCredentials } from "redux/auth/slice"

const Users = props => {
  const dispatch = useDispatch()
  const [isEdit, setIsEdit] = useState(false)
  const [openAddEditModal, setOpenAppEditModal] = useState(false)
  const [openArchiveModal, setOpenArchiveModal] = useState(false)
  const [openStatusChangeModal, setOpenStatusChangeModal] = useState(false)
  const [openPasswordChangeModal, setOpenPasswordChangeModal] = useState(false)
  const [loadList, setLoadList] = useState(true)
  const [get, GetAPI] = useUserGetMutation()
  const [switchUser] = useSwitchUserMutation()

  const onAddUser = () => {
    setOpenAppEditModal(true)
  }
  const onEditUser = async id => {
    try {
      const result = await get(id).unwrap()
      dispatch(setUser(result.data))
      setIsEdit(true)
      setOpenAppEditModal(true)
    } catch (error) {
      showError(error)
    }
  }
  const onArchiveUser = async id => {
    try {
      const result = await get(id).unwrap()
      dispatch(setUser(result.data))
      setOpenArchiveModal(true)
    } catch (error) {
      showError(error)
    }
  }

  const onUserStatusChange = async user => {
    try {
      const result = await get(user._id).unwrap()
      dispatch(setUser(result.data))
      setOpenStatusChangeModal(true)
    } catch (error) {
      showError(error)
    }
  }

  const onPasswordChange = async id => {
    try {
      const result = await get(id).unwrap()
      dispatch(setUser(result.data))
      setOpenPasswordChangeModal(true)
    } catch (error) {
      showError(error)
    }
  }
  const onSwitchUser = async (id) => {
    try {
      const result = await switchUser(id).unwrap()
      localStorage.setItem('IsSwitch', true);
      const username = result.data.user.firstname + ' '+result.data.user.lastname
      showToaster("success", `Switched as ${username}`,"Success");
      dispatch(setCredentials(result.data))
    } catch (error) {
      showError(error)
    }
  }
  const BreadcrumbDetails = {
    breadcrumbItem: {
      label: 'List',
      link: 'users'
    },
    paths: [
      {
        label: 'User',
        link: 'users'
      }
    ]
  }


  return (
    <React.Fragment>
      <Helmet>
        <title>Users | Email-Hunter</title>
      </Helmet>

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs breadcrumbDetails={BreadcrumbDetails} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <div className="text-sm-end">
                    <Button
                      type="button"
                      color="primary"
                      className="btn mb-2 me-2"
                      onClick={onAddUser}
                    >
                      <i className="mdi mdi-plus-circle-outline me-1" />
                      Create User
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xs="12">
              <Card>
                <CardBody>
                  <List
                    load={loadList}
                    setIsEdit={setIsEdit}
                    onEditUser={onEditUser}
                    onArchiveUser={onArchiveUser}
                    onUserStatusChange={onUserStatusChange}
                    onPasswordChange={onPasswordChange}
                    onSwitchUser={onSwitchUser}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <AddEditModal
            isEdit={isEdit}
            openModal={openAddEditModal}
            setOpenModal={setOpenAppEditModal}
            setLoadList={setLoadList}
            setIsEdit={setIsEdit}
          />

          <ArchiveModal
            show={openArchiveModal}
            setShow={setOpenArchiveModal}
            setLoadList={setLoadList}
          />

          <StatusChangeModal
            show={openStatusChangeModal}
            setShow={setOpenStatusChangeModal}
            setLoadList={setLoadList}
          />

          <PasswordChangeModal
            show={openPasswordChangeModal}
            setShow={setOpenPasswordChangeModal}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Users
