import React, { useEffect, useState } from "react"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import * as Yup from "yup"
import { useFormik, getIn } from "formik"
import { useSelector, useDispatch } from "react-redux"

import {
  Button,
  Col,
  Row,
  Form,
  Input,
  FormFeedback,
  Label,
  Spinner,
} from "reactstrap"
import { showError, showToaster } from "helpers"
import { useUserUpdateMutation } from "redux/users/api"
import { selectCurrentUser, setAuthUser } from "redux/auth/slice"
import { EMAIL_REGEX } from "constants/regex"

function MailWizzSettings() {
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const [tokenShow, setTokenShow] = useState(false);
  const [userUpdate, UserUpdateAPI] = useUserUpdateMutation();

  // formik validation & initialization
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      mailWizz: {
        baseUrl: user?.settings?.mailWizz?.baseUrl || "",
        token: user?.settings?.mailWizz?.token || "",
        fromName: user?.settings?.mailWizz?.fromName || "",
        fromEmail: user?.settings?.mailWizz?.fromEmail || "",
        replyTo: user?.settings?.mailWizz?.replyTo || "",
      },
    },
    validationSchema: Yup.object().shape({
      mailWizz: Yup.object().shape({
        token: Yup.string().trim()
        .required("Please enter Token")
        .matches(/^\S*$/, 'Whitespace is not allowed'),
        baseUrl: Yup.string()
          .trim()
          .matches(/^\S*$/, 'Whitespace is not allowed')
          .required("Please enter Base URL")
          .url("Enter valid base url"),
        fromName: Yup.string()
          .trim()
          .required("Please enter From Name")
          .min(3, "From name should have minimum 3 characters")
          .max(20, "From name should have maximum 20 characters"),
        fromEmail: Yup.string()
          .trim()
          .matches(/^\S*$/, 'Whitespace is not allowed')
          .required("Please enter From Email")
          .matches(EMAIL_REGEX, "Enter valid Email")
          .email("Enter valid Email"),
        replyTo: Yup.string()
          .trim()
          .matches(/^\S*$/, 'Whitespace is not allowed')
          .required("Please Enter Reply To Email")
          .matches(EMAIL_REGEX, "Enter valid Email")
          .email("Enter valid Email"),
      }),
    }),
    onSubmit: async (values) => {
      try {
        const result = await userUpdate({
          id: user._id,
          payload: { settings: values },
        }).unwrap();
        showToaster("success", "Changes Successfully Saved", "Success")
        formik.resetForm();
        dispatch(setAuthUser(result.data));
      } catch (error) {
        // console.log(error);
        showError(error);
      }
    },
  });

  return (
    <React.Fragment>
      <Form
        onSubmit={e => {
          e.preventDefault()
          formik.handleSubmit()
          return false
        }}
      >
        <Row>
          <Col className="col-12">
            {/* password */}
            <div className="mb-3">
              <Label className="form-label">Token*</Label>
              <div className="input-group auth-pass-inputgroup">
                <Input
                  autoComplete="new-password"
                  name="mailWizz.token"
                  type={tokenShow ? "text" : "password"}
                  placeholder="Enter Token"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={getIn(formik.values, "mailWizz.token") || ""}
                  invalid={
                    getIn(formik.touched, "mailWizz.token") &&
                    getIn(formik.errors, "mailWizz.token")
                      ? true
                      : false
                  }
                />
                <button
                  onClick={() => setTokenShow(!tokenShow)}
                  className="btn btn-light "
                  type="button"
                  id="password-addon"
                >
                  <i
                    className={`mdi ${
                      !tokenShow ? "mdi-eye-outline" : "mdi-eye-off-outline"
                    }`}
                  ></i>
                </button>
                {getIn(formik.touched, "mailWizz.token") &&
                getIn(formik.errors, "mailWizz.token") ? (
                  <FormFeedback type="invalid">
                    {getIn(formik.errors, "mailWizz.token")}
                  </FormFeedback>
                ) : null}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="col-6">
            <div className="mb-3">
              <Label className="form-label">Base URL*</Label>
              <div className="input-group auth-pass-inputgroup">
                <Input
                  name="mailWizz.baseUrl"
                  type="text"
                  placeholder="Enter Base Url"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={getIn(formik.values, "mailWizz.baseUrl") || ""}
                  invalid={
                    getIn(formik.touched, "mailWizz.baseUrl") &&
                    getIn(formik.errors, "mailWizz.baseUrl")
                      ? true
                      : false
                  }
                />
                {getIn(formik.touched, "mailWizz.baseUrl") &&
                getIn(formik.errors, "mailWizz.baseUrl") ? (
                  <FormFeedback type="invalid">
                    {getIn(formik.errors, "mailWizz.baseUrl")}
                  </FormFeedback>
                ) : null}
              </div>
            </div>
          </Col>
          <Col className="col-6">
            <div className="mb-3">
              <Label className="form-label">From Name*</Label>
              <div className="input-group auth-pass-inputgroup">
                <Input
                  name="mailWizz.fromName"
                  type="text"
                  placeholder="Enter From Name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={getIn(formik.values, "mailWizz.fromName") || ""}
                  invalid={
                    getIn(formik.touched, "mailWizz.fromName") &&
                    getIn(formik.errors, "mailWizz.fromName")
                      ? true
                      : false
                  }
                />
                {getIn(formik.touched, "mailWizz.fromName") &&
                getIn(formik.errors, "mailWizz.fromName") ? (
                  <FormFeedback type="invalid">
                    {getIn(formik.errors, "mailWizz.fromName")}
                  </FormFeedback>
                ) : null}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="col-6">
            <div className="mb-3">
              <Label className="form-label">From Email*</Label>
              <div className="input-group auth-pass-inputgroup">
                <Input
                  name="mailWizz.fromEmail"
                  type="text"
                  placeholder="Enter From Email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={getIn(formik.values, "mailWizz.fromEmail") || ""}
                  invalid={
                    getIn(formik.touched, "mailWizz.fromEmail") &&
                    getIn(formik.errors, "mailWizz.fromEmail")
                      ? true
                      : false
                  }
                />
                {getIn(formik.touched, "mailWizz.fromEmail") &&
                getIn(formik.errors, "mailWizz.fromEmail") ? (
                  <FormFeedback type="invalid">
                    {getIn(formik.errors, "mailWizz.fromEmail")}
                  </FormFeedback>
                ) : null}
              </div>
            </div>
          </Col>
          <Col className="col-6">
            <div className="mb-3">
              <Label className="form-label">Reply To Email*</Label>
              <div className="input-group auth-pass-inputgroup">
                <Input
                  name="mailWizz.replyTo"
                  type="text"
                  placeholder="Enter Reply To Email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={getIn(formik.values, "mailWizz.replyTo") || ""}
                  invalid={
                    getIn(formik.touched, "mailWizz.replyTo") &&
                    getIn(formik.errors, "mailWizz.replyTo")
                      ? true
                      : false
                  }
                />
                {getIn(formik.touched, "mailWizz.replyTo") &&
                getIn(formik.errors, "mailWizz.replyTo") ? (
                  <FormFeedback type="invalid">
                    {getIn(formik.errors, "mailWizz.replyTo")}
                  </FormFeedback>
                ) : null}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-end">
              <Button
                type="submit"
                className="btn btn-success save-user"
                color="success"
                disabled={UserUpdateAPI.isLoading}
              >
                {!UserUpdateAPI.isLoading ? (
                  "Validate & Save"
                ) : (
                  <>
                    <span>Validating MailWizz Token ....</span>
                    <Spinner size="sm"></Spinner>
                  </>
                )}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  )
}

export default MailWizzSettings
