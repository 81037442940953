import React from "react"
import { Link, useLocation } from "react-router-dom"
import { Badge } from "reactstrap"
import { formatBigNumber, wordTitleCase, wordsTitleCase } from "../../helpers/data-formatters"
import { getColorByCampaignStatus } from "helpers"
import config from "../../config"

const Text = cell => {
  return wordTitleCase(cell.value)
}

const Name = ({ cell, onEditCampaign, currentLoggedInUser }) => {
  const campaign = cell.row.original
  const userRole = currentLoggedInUser?.role
  if (campaign?.status === config.campaign.status.draft.value) {
    // let admin allow campaign edit
    if (userRole === config.userRoles.admin.key) {
      return (
        <Link
          to="#"
          className="text-primary"
          onClick={() => {
            onEditCampaign(campaign._id)
          }}
        >
          {cell.value ? cell.value : ""}
        </Link>
      )
    }

    if (userRole != config.userRoles.admin.key) {
      return cell.value ? cell.value : ""
    }
  }

  const path = `/campaigns/${campaign._id}`
  const location = useLocation()
  return (
    <Link
      to={path}
      state={{ from: location }}
      title="Click here to view campaign details"
    >
      {wordTitleCase(cell.value)}
    </Link>
  )
}

const ListName = cell => {
  return wordTitleCase(cell.value)
}

const Status = cell => {
  const color = getColorByCampaignStatus(cell.value)
  return (
    <Badge className={"font-size-12 badge-soft-" + color} pill>
      {config.campaign.status[cell.value].key}
    </Badge>
  )
}

const UserName = cell => {
  let name = cell.value.email
  if (cell.value.firstname || cell.value.lastname) {
    name = " ";
    if (cell.value.firstname) {
      name += cell.value.firstname
    }
    if(cell.value.lastname) {
      name += " " + cell.value.lastname
    }
  }

  return wordsTitleCase(name.trim())
}

const ListId = cell => {
  return (
    <Link to="#" className="text-body fw-bold">
      {cell.value ? cell.value : ""}
    </Link>
  )
}

const ListStatus = cell => {
  let color = "info"
  switch (cell.value) {
    case "pending":
      color = "warning"
      break
    case "in-progress":
      color = "primary"
      break
    case "pause":
      color = "success"
      break
    case "completed":
      color = "dark"
      break
  }

  return (
    <Badge className={"font-size-12 badge-soft-" + color} pill>
      {wordTitleCase(cell.value)}
    </Badge>
  )
}

const MailCount = cell => {
  return formatBigNumber(cell.value)
}
export { Text, Name, Status, ListId, ListStatus, MailCount, ListName, UserName }
