import { createSlice } from "@reduxjs/toolkit"
// import { useNavigate } from "react-router-dom";
const authSlice = createSlice({
    name: 'auth',
    initialState: { user: null, token: null, logout: false, selfLogout: false, IsSwitch: localStorage.getItem('IsSwitch') || false },
    reducers: {
        setCredentials: (state, action) => {
            const { user, accessToken } = action.payload
            state.user = user;
            state.token = accessToken;
            state.logout = false;
            state.IsSwitch = localStorage.getItem('IsSwitch') || false;
        },
        logOut: (state, action) => {
            state.user = null;
            state.token = null;
            state.logout = true;
            state.selfLogout = action.payload?.selfLogout || false;
        },
        setAuthUser: (state, action) => {
            state.user = action.payload
        }
    },
})

export const { setCredentials, logOut, setAuthUser } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentToken = (state) => state.auth.token;
export const selectLogout = (state) => state.auth.logout;
export const selectSelfLogout = (state) => state.auth.selfLogout;
export const selectIsSwitch = (state) => state.auth.IsSwitch;