import React, { useEffect, useMemo, useState } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useSelector, useDispatch } from "react-redux"
import {
  Button,
  Col,
  Row,
  Form,
  Input,
  FormFeedback,
  Label,
  Spinner,
  CardBody,
  Card,
  Alert,
  UncontrolledTooltip,
  FormGroup,
  Badge,
} from "reactstrap"
import { showError, showToaster } from "helpers"
import {
  useCampaignCreateMutation,
  useCampaignUpdateMutation,
} from "redux/campaigns/api"
import { selectCampaign, setCampaign } from "redux/campaigns/slice"
//import Images
import verification from "../../../../../assets/images/verification-img.png"
// import verification from "../../../../../assets/images/coming-soon.svg"

function FirstStep({ activeTab, setactiveTab }) {
  const dispatch = useDispatch()
  const campaign = useSelector(selectCampaign)
  // const [showInfo, setShowInfo] = useState(false)
  const [wantToSearchInMasterEmail, setWantToSearchInMasterEmail] =
    useState(false)

  const [wantToVerifyScrappedEmail, setWantToVerifyScrappedEmail] =
    useState(false)

  useEffect(() => {
    if (campaign) {
      setWantToSearchInMasterEmail(campaign?.checkInEmailMaster || false)
      setWantToVerifyScrappedEmail(campaign?.wantToVerifyScrappedEmail || false)
    }
  }, [campaign])

  const [createCampaign, CreateCampaignAPI] = useCampaignCreateMutation()
  const [updateCampaign, UpdateCampaignAPI] = useCampaignUpdateMutation()

  const initialValue = {
    name: campaign?.name || "",
    type: campaign?.type || "email-validation",
    subType: campaign?.subType || "email",
    requestTimeout: campaign?.requestTimeout || 5,
    isOnlyForDomain: campaign?.isOnlyForDomain === undefined ? true : campaign?.isOnlyForDomain,
  }

  const validationSchema = {
    name: Yup.string()
      .trim()
      .required("Please enter Campaign Name")
      .min(3, "Campaign name should have minimum 3 characters")
      .max(20, "Campaign name should have maximum 20 characters"),
  }

  // formik validation & initialization
  const formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: initialValue,
    validationSchema: Yup.object(validationSchema),
    onSubmit: async values => {
      const payload = {
        ...values,
        checkInEmailMaster: wantToSearchInMasterEmail,
        wantToVerifyScrappedEmail: wantToVerifyScrappedEmail,
      }
      try {
        if (isValuesChanged) {
          let result
          if (!campaign) {
            result = await createCampaign(payload).unwrap()
          } else {
            result = await updateCampaign({
              id: campaign._id,
              payload,
            }).unwrap()
          }
          dispatch(setCampaign(result.data))
          // showToaster("success", result.message, "Success")
        }
        setactiveTab("file-upload")
      } catch (error) {
        showError(error)
      }
    },
  });

  useEffect(() => {
    if (campaign && campaign.campaignProgress !== "info") {
      if (campaign.type && campaign.type !== formik.values.type) {
        showToaster(
          "warning",
          "This Action Will Erase Your Uploaded File",
          "Warning"
        )
      }

      if (campaign.subType && campaign.subType !== formik.values.subType) {
        showToaster(
          "warning",
          "This Action Will Erase Your Uploaded File",
          "Warning"
        )
      }
    }
  }, [formik.dirty && formik.values.type, formik.values.subType])

  const isValuesChanged = useMemo(() => {
    if (campaign) {
      return (
        formik.dirty ||
        campaign?.checkInEmailMaster !== wantToSearchInMasterEmail ||
        campaign?.wantToVerifyScrappedEmail !== wantToVerifyScrappedEmail
      )
    }
    return formik.dirty
  }, [
    formik.dirty,
    campaign?.checkInEmailMaster,
    wantToSearchInMasterEmail,
    wantToVerifyScrappedEmail,
    campaign?.wantToVerifyScrappedEmail,
  ])

  return (
    <React.Fragment>
      <Form
        onSubmit={e => {
          e.preventDefault()
          formik.handleSubmit()
          return false
        }}
      >
        <Row>
          <Col className="col-md-6 col-sm-12">
            <Card className="mb-4 bg-white h-100">
              <CardBody>
                <Row className="justify-content-left">
                  <Col className="col-md-12">
                    {/* campaign name  */}
                    <div className="mb-3">
                      <Label className="form-label">Campaign Name*</Label>
                      <Input
                        name="name"
                        type="text"
                        placeholder="Enter Campaign Name Here"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name || ""}
                        invalid={
                          formik.touched.name && formik.errors.name
                            ? true
                            : false
                        }
                      />
                      {formik.touched.name && formik.errors.name ? (
                        <FormFeedback type="invalid">
                          {formik.errors.name}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row className="justify-content-left">
                  <Col>
                    <Label className="form-label">Campaign Type* </Label>
                    <div>
                      <input
                        type="radio"
                        id="emailValidation"
                        name="type"
                        className="btn-check"
                        value={"email-validation"}
                        checked={formik.values.type === "email-validation"}
                        onChange={() =>
                          formik.setFieldValue("type", "email-validation")
                        }
                      />
                      <label
                        className="btn btn-outline-primary"
                        htmlFor="emailValidation"
                      >
                        <span>Validation</span>{" "}
                        <i className="mdi mdi-email-check"></i>
                      </label>
                      <input
                        type="radio"
                        id="emailScrpping"
                        name="type"
                        className="btn-check"
                        value={"email-scraping"}
                        checked={formik.values.type === "email-scraping"}
                        onChange={() =>
                          formik.setFieldValue("type", "email-scraping")
                        }
                      />
                      <label
                        className="btn btn-outline-primary mx-2"
                        htmlFor="emailScrpping"
                      >
                        <span>Scraping</span> <i className="mdi mdi-web"></i>
                      </label>
                    </div>
                  </Col>
                </Row>

                {formik.values.type === "email-scraping" && (
                  <>
                    <Row className="justify-content-left mt-2">
                      <Col className="col-md-12 col-lg-6">
                        <Label className="form-label">File Type* :</Label>
                        <div>
                          <input
                            type="radio"
                            id="email"
                            name="subType"
                            className="btn-check"
                            value={"email"}
                            checked={formik.values.subType === "email"}
                            onChange={() =>
                              formik.setFieldValue("subType", "email")
                            }
                          />
                          <label
                            className="btn btn-outline-success"
                            htmlFor="email"
                          >
                            <span>Email</span> <i className="mdi mdi-email"></i>
                          </label>
                          <input
                            type="radio"
                            id="url"
                            name="subType"
                            className="btn-check"
                            value={"url"}
                            checked={formik.values.subType === "url"}
                            onChange={() =>
                              formik.setFieldValue("subType", "url")
                            }
                          />
                          <label
                            className="btn btn-outline-success mx-1"
                            htmlFor="url"
                          >
                            <span>URL</span> <i className="mdi mdi-web"></i>
                          </label>
                        </div>
                      </Col>
                      <Col>
                        <Label className="form-label">
                          Request Timeout* :{" "}
                          <span className="h5">
                            <Badge color="primary" pill>
                              {formik.values.requestTimeout} sec
                            </Badge>
                          </span>
                        </Label>
                        <div className="mt-1">
                          <Input
                            type="range"
                            className="form-range"
                            id="requestTimeout"
                            name="requestTimeout"
                            value={formik.values.requestTimeout}
                            min={5}
                            max={60}
                            onChange={e => {
                              formik.setFieldValue("requestTimeout", Number(e.target.value));
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
                {/* for url scraping option keep domain or keep whole url  */}
                {formik.values.subType === "url" && formik.values.type === "email-scraping" && (
                  <Row className="justify-content-left mt-2">
                    <Col className="col-md-12">
                      <Label className="form-label">Scrapping from*:</Label>
                      <div>
                        <input
                          type="radio"
                          id="onlydomain"
                          name="isOnlyForDomain"
                          className="btn-check"
                          value={"onlydomain"}
                          checked={formik.values.isOnlyForDomain === true}
                          onChange={() =>
                            formik.setFieldValue("isOnlyForDomain", true)
                          }
                        />

                        <label
                          id="keepdomain"
                          className="btn  btn-outline-primary mx-1"
                          htmlFor="onlydomain"
                        >
                          <span>keep domain</span> <i className="mdi mdi-web"></i>
                        </label>
                        <UncontrolledTooltip
                          placement="top"
                          target="keepdomain"
                        >
                         Use only domain for scrapping
                        </UncontrolledTooltip>
                        <input
                          type="radio"
                          id="withsubdomain"
                          name="isOnlyForDomain"
                          className="btn-check"
                          value={"withsubdomain"}
                          checked={formik.values.isOnlyForDomain === false}
                          onChange={() =>
                            formik.setFieldValue("isOnlyForDomain", false)
                          }
                        />
                        <label
                         id="keepsubdomain"
                          className="btn btn-outline-primary"
                          htmlFor="withsubdomain"
                        >
                          <span>keep whole url</span> <i className="mdi mdi-link-variant"></i>
                        </label>
                        <UncontrolledTooltip
                          placement="top"
                          target="keepsubdomain"
                        >
                          Use whole URL for scrapping
                        </UncontrolledTooltip>
                      </div>
                    </Col>
                  </Row>
                )}

                {formik.values.type === "email-scraping" && (
                  <Row className="justify-content-left mt-2">
                    <Col>
                      <div className="form-check form-checkbox-outline form-check-primary mb-3">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="wantToVerifyScrappedEmail"
                          name="wantToVerifyScrappedEmail"
                          onChange={() => {
                            setWantToVerifyScrappedEmail(
                              !wantToVerifyScrappedEmail
                            )
                          }}
                          checked={wantToVerifyScrappedEmail}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="wantToVerifyScrappedEmail"
                        >
                          Validate Scrapped Emails ?
                        </label>
                      </div>
                    </Col>
                  </Row>
                )}

                {(formik.values.type === "email-validation" ||
                  (formik.values.type === "email-scraping" &&
                    wantToVerifyScrappedEmail)) && (
                  <Row className="justify-content-left mt-1">
                    <Col>
                      <div className="form-check form-checkbox-outline form-check-primary mb-3">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="wantToSearchInMasterEmail"
                          name="wantToSearchInMasterEmail"
                          onChange={() => {
                            setWantToSearchInMasterEmail(
                              !wantToSearchInMasterEmail
                            )
                          }}
                          checked={wantToSearchInMasterEmail}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="wantToSearchInMasterEmail"
                        >
                          Validate Emails Using Internal Database?
                        </label>
                      </div>
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>
          </Col>
          <Col className="col-md-6 col-sm-12">
            <Card className="mb-4 bg-white h-100">
              <img src={verification} alt="" className="img-fluid d-block" />
            </Card>
          </Col>
        </Row>

        {/* <Row>
          <Col className="col-6">
            <div>
              <Label className="form-label">
                Validate Emails Using Internal Database?
              </Label>
            </div>
            <div className="form-check form-switch form-switch-lg mb-3">
              <input
                type="checkbox"
                className="form-check-input"
                onChange={() => {
                  setWantToSearchInMasterEmail(!wantToSearchInMasterEmail)
                }}
                checked={wantToSearchInMasterEmail}
                name="wantToSearchInMasterEmail"
              />
            </div>
          </Col>
        </Row> */}

        {/* Actions Buttons */}
        <Row className="mt-3">
          <Col>
            <div className="text-end">
              <Button
                type="submit"
                className="btn btn-primary"
                color="primary"
                disabled={
                  CreateCampaignAPI.isLoading || UpdateCampaignAPI.isLoading
                }
              >
                {CreateCampaignAPI.isLoading || UpdateCampaignAPI.isLoading ? (
                  <>
                    <span>
                      {`${campaign ? "Saving" : "Creating"} Campaign...`}
                    </span>
                    <Spinner size="sm"></Spinner>
                  </>
                ) : (
                  `${
                    campaign
                      ? campaign && isValuesChanged
                        ? "Update & Continue"
                        : "Next"
                      : "Create & Continue"
                  }`
                )}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  )
}

export default FirstStep
