import React from "react"
import { useLocation, Navigate, Outlet } from "react-router-dom"
import { useSelector } from "react-redux"
import { selectCurrentToken, selectSelfLogout } from "../redux/auth/slice"

const RedirectToDashboard = () => {
  const token = useSelector(selectCurrentToken)
  const isSelfLogout = useSelector(selectSelfLogout)
  const location = useLocation()
  let redirectRoute = "/dashboard"
  if (!isSelfLogout && location?.state?.from) {
    if (location?.state?.from?.pathname) {
      redirectRoute = `${location.state.from.pathname}`
      if (location?.state?.from?.search) {
        redirectRoute += location.state.from.search
      }
    }
  }
  return token ? (
    <Navigate to={redirectRoute} state={{ from: location }} replace />
  ) : (
    <Outlet />
  )
}
export default RedirectToDashboard
