import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

// Redux
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import ChangePasswordModal from "components/Common/Modals/ChangePassword"

// users
import userProfile from "../../../assets/images/default_profile.png"
import { logOut, selectCurrentUser, selectIsSwitch, setCredentials } from "redux/auth/slice"
import { components } from "react-select"
import { useSwitchBackMutation, } from "redux/auth/api"
import {  showError, showToaster } from "helpers"
// import { split } from "lodash"

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const [openChangePassowrdModal, setOpenChangePasswordModal] = useState(false)
  const dispatch = useDispatch()
  const navigate=useNavigate()
  const user = useSelector(selectCurrentUser)
  const IsSwitched = useSelector(selectIsSwitch)
  const [switchBack] = useSwitchBackMutation()

  const onLogout = () => {
    dispatch(logOut({ selfLogout: true }))
  }
  const onSwitchBack = async () => {
    try {
      const result = await switchBack().unwrap()
      localStorage.removeItem('IsSwitch');
      const username = result.data.user?.firstname + ' '+result.data.user?.lastname
      showToaster("success", `Switched back as ${username}`,"Success");
      dispatch(setCredentials(result.data));
      navigate("/users", { replace: true });
    } catch (error) {
      showError(error)
    }
  };
  const onChangePassword = () => {
    if (!openChangePassowrdModal) {
      setOpenChangePasswordModal(true)
    }
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={userProfile}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{`${user.firstname}  ${user.lastname} `}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {/* <DropdownItem tag="a" disabled={true}>
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {"Profile"}{" "}
          </DropdownItem> */}
          <DropdownItem
            onClick={() => {
              onChangePassword()
            }}
            className="dropdown-item"
          >
            <i className="bx bx bxs-lock font-size-15 align-middle me-1 text-primary" />
            <span>{"Change Password"}</span>
          </DropdownItem>
          <div className="dropdown-divider" />
          {IsSwitched ? <DropdownItem
            onClick={() => {
              onSwitchBack()
            }}
            className="dropdown-item"
          >
            <i className="mdi mdi-account-supervisor font-size-16 align-middle me-1 text-danger" />
            <span>{"Switch Back"}</span>
          </DropdownItem> : <DropdownItem
            onClick={() => {
              onLogout()
            }}
            className="dropdown-item"
          >
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{"Logout"}</span>
          </DropdownItem>
          }
        </DropdownMenu>
      </Dropdown>

      <ChangePasswordModal
        show={openChangePassowrdModal}
        setShow={setOpenChangePasswordModal}
      />
    </React.Fragment>
  )
}

export default withRouter(ProfileMenu)
