import React, { useEffect, useMemo, useState } from "react"
import { isEmpty } from "lodash"
import * as Yup from "yup"
import { useFormik, getIn } from "formik"
import { useSelector, useDispatch } from "react-redux"
import Select from "react-select"
import {
  Button,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  Form,
  Input,
  FormFeedback,
  Label,
  Spinner,
  Alert,
  ModalBody,
  ModalHeader,
  Modal,
} from "reactstrap"
import { showError, showToaster } from "helpers"
import { selectCurrentUser } from "redux/auth/slice"
import { selectCampaign } from "redux/campaigns/slice"
import { Link } from "react-router-dom"
import { useMailWizzListCreateMutation } from "redux/mailWizzLists/api"
import { EMAIL_REGEX } from "constants/regex"
import { useCampaignGetEmailStatsMutation } from "redux/campaigns/api"
import { wordTitleCase } from "helpers/data-formatters"

function CreateMailWizzList({ open, setOpen, setReloadCampaign }) {
  const [emailStatus, setEmailStatus] = useState([])
  const [emailStatusOption, setEmailStatusOption] = useState([])

  const [createList, CreateListAPI] = useMailWizzListCreateMutation()
  const [getEmailStats, GetEmailStatsAPI] = useCampaignGetEmailStatsMutation()
  const dispatch = useDispatch()
  const user = useSelector(selectCurrentUser)
  const campaign = useSelector(selectCampaign)
  const mailWizz = user?.settings?.mailWizz
  const initialValue = {
    list: {
      name: ``,
      fromName: mailWizz?.fromName || "",
      fromEmail: mailWizz?.fromEmail || "",
      replyTo: mailWizz?.replyTo || "",
      description: ``,
      subject: ``,
    },
  }

  useEffect(() => {
    ;(async () => {
      try {
        if(!!campaign?._id) {
          const result = await getEmailStats(campaign._id).unwrap()
          const data = result.data
          const emailStatusToBeAllow = []
  
          for (const status in data) {
            if (data[status]) {
              emailStatusToBeAllow.push({
                label: wordTitleCase(status),
                value: status,
              })
            }
          }
          setEmailStatusOption(emailStatusToBeAllow);
        };
      } catch (error) {
        console.log("error while finding email status in add list modal", error)
      }
    })();
  }, [campaign, setEmailStatusOption])

  //   const confirmAlert = () => {
  //     return Swal.fire({
  //       title: "Are you sure?",
  //       text: "You won't be able to revert/edit this, Please do confirm details first.",
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonColor: "#3085d6",
  //       cancelButtonColor: "#d33",
  //       confirmButtonText: "Yes, save it!",
  //     })
  //   }

  const validationSchema = {
    list: Yup.object({
      name: Yup.string()
        .trim()
        .required("Please enter List Name")
        .min(3, "List name should have minimum 3 characters")
        .max(20, "List name should have maximum 20 characters"),
      fromName: Yup.string()
        .trim()
        .required("Please enter From Name")
        .min(3, "From name should have minimum 3 characters")
        .max(20, "From name should have maximum 20 characters"),
      fromEmail: Yup.string()
        .trim()
        .required("Please enter From Email")
        .matches(/^\S*$/, "Whitespace is not allowed")
        .matches(EMAIL_REGEX, "Enter valid Email"),
      replyTo: Yup.string()
        .trim()
        .required("Please enter Reply To Email")
        .matches(/^\S*$/, "Whitespace is not allowed")
        .matches(EMAIL_REGEX, "Enter valid Email"),

      description: Yup.string()
        .trim()
        .required("Please enter Description")
        .min(3, "Description should have minimum 3 characters")
        .max(30, "Description name should have maximum 30 characters"),

      subject: Yup.string()
        .trim()
        .required("Please enter Subject")
        .min(3, "Subject should have minimum 3 characters")
        .max(20, "Subject name should have maximum 20 characters"),
    }),
  }

  // formik validation & initialization
  const formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: initialValue,
    validationSchema: Yup.object(validationSchema),
    onSubmit: async values => {
      if (!emailStatus?.length) {
        return showToaster("error", "Please select Email Status", "Required")
      }

      values = {
        list: {
          ...values.list,
          emailStatus: emailStatus.map(status => status.value),
        },
      }

      const payload = {
        ...values,
        campaignId: campaign._id,
      }
      try {
        const result = await createList(payload).unwrap()
        showToaster("success", result.message, "Success")
        if (setReloadCampaign) {
          setReloadCampaign(state => !state)
        }
        setOpen(false)
      } catch (error) {
        showError(error)
      }
    },
  })

  const toggle = () => {
    setOpen(false)
    formik.resetForm()
  }

  const onEmailStatusSelect = option => {
    setEmailStatus(option)
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={open}
        toggle={toggle}
        backdrop={"static"}
        centered={true}
        size={"lg"}
      >
        <ModalHeader toggle={toggle} tag="h4">
          <i className="bx bx-mail-send bx-spin"></i> Create MailWizz List
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={e => {
              e.preventDefault()
              formik.handleSubmit()
              return false
            }}
          >
            <Row className="justify-content-center">
              {!mailWizz && (
                <Col className="col-8">
                  <Alert color="danger" className="text-center">
                    Oops! You are unable to create list with{" "}
                    <a
                      href="https://forum.mailwizz.com/tags/login/"
                      rel="noreferrer"
                      target={"_blank"}
                    >
                      <b>MailWizZ</b>
                    </a>{" "}
                    until you configure your mailWizz account.
                    <br />
                    <Link to="/settings" className="alert-link">
                      click here to configure mailWizz account.
                    </Link>
                  </Alert>
                </Col>
              )}

              {mailWizz && (
                <Col className="col-12">
                  <Card className="my-2">
                    <CardBody>
                      <Row>
                        <Col className="col-6">
                          {/* campaign name  */}
                          <div className="mb-3">
                            <Label className="form-label"> List Name*</Label>
                            <Input
                              name="list.name"
                              type="text"
                              placeholder="Enter List Name Here"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={getIn(formik.values, "list.name") || ""}
                              invalid={
                                getIn(formik.touched, "list.name") &&
                                getIn(formik.errors, "list.name")
                                  ? true
                                  : false
                              }
                            />
                            {getIn(formik.touched, "list.name") &&
                            getIn(formik.errors, "list.name") ? (
                              <FormFeedback type="invalid">
                                {getIn(formik.errors, "list.name")}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col className="col-6">
                          {/* campaign name  */}
                          <div className="mb-3">
                            <Label className="form-label">
                              List Description*
                            </Label>
                            <Input
                              name="list.description"
                              type="text"
                              placeholder="Enter List Description Here"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={
                                getIn(formik.values, "list.description") || ""
                              }
                              invalid={
                                getIn(formik.touched, "list.description") &&
                                getIn(formik.errors, "list.description")
                                  ? true
                                  : false
                              }
                            />
                            {getIn(formik.touched, "list.description") &&
                            getIn(formik.errors, "list.description") ? (
                              <FormFeedback type="invalid">
                                {getIn(formik.errors, "list.description")}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col className="col-6">
                          {/* campaign name  */}
                          <div className="mb-3">
                            <Label className="form-label">From Email*</Label>
                            <Input
                              name="list.fromEmail"
                              type="text"
                              placeholder="Enter From Email here"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={
                                getIn(formik.values, "list.fromEmail") || ""
                              }
                              invalid={
                                getIn(formik.touched, "list.fromEmail") &&
                                getIn(formik.errors, "list.fromEmail")
                                  ? true
                                  : false
                              }
                            />
                            {getIn(formik.touched, "list.fromEmail") &&
                            getIn(formik.errors, "list.fromEmail") ? (
                              <FormFeedback type="invalid">
                                {getIn(formik.errors, "list.fromEmail")}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col className="col-6">
                          {/* campaign name  */}
                          <div className="mb-3">
                            <Label className="form-label">From Name*</Label>
                            <Input
                              name="list.fromName"
                              type="text"
                              placeholder="Enter From Name here"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={
                                getIn(formik.values, "list.fromName") || ""
                              }
                              invalid={
                                getIn(formik.touched, "list.fromName") &&
                                getIn(formik.errors, "list.fromName")
                                  ? true
                                  : false
                              }
                            />
                            {getIn(formik.touched, "list.fromName") &&
                            getIn(formik.errors, "list.fromName") ? (
                              <FormFeedback type="invalid">
                                {getIn(formik.errors, "list.fromName")}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col className="col-6">
                          {/* campaign name  */}
                          <div className="mb-3">
                            <Label className="form-label">Subject*</Label>
                            <Input
                              name="list.subject"
                              type="text"
                              placeholder="Enter Subject Here"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={getIn(formik.values, "list.subject") || ""}
                              invalid={
                                getIn(formik.touched, "list.subject") &&
                                getIn(formik.errors, "list.subject")
                                  ? true
                                  : false
                              }
                            />
                            {getIn(formik.touched, "list.subject") &&
                            getIn(formik.errors, "list.subject") ? (
                              <FormFeedback type="invalid">
                                {getIn(formik.errors, "list.subject")}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col className="col-6">
                          {/* campaign name  */}
                          <div className="mb-3">
                            <Label className="form-label">
                              Reply To Email*
                            </Label>
                            <Input
                              name="list.replyTo"
                              type="text"
                              placeholder="Enter Reply To email here"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={getIn(formik.values, "list.replyTo") || ""}
                              invalid={
                                getIn(formik.touched, "list.replyTo") &&
                                getIn(formik.errors, "list.replyTo")
                                  ? true
                                  : false
                              }
                            />
                            {getIn(formik.touched, "list.replyTo") &&
                            getIn(formik.errors, "list.replyTo") ? (
                              <FormFeedback type="invalid">
                                {getIn(formik.errors, "list.replyTo")}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <div className="mb-3">
                            <label className="control-label">
                              Email Status
                            </label>
                            <Select
                              value={emailStatus}
                              styles={{
                                control: (baseStyles, state) => {
                                  return {
                                    ...baseStyles,
                                    borderColor: state.hasValue
                                      ? "grey"
                                      : "red",
                                  }
                                },
                              }}
                              isMulti={true}
                              onChange={e => {
                                onEmailStatusSelect(e)
                              }}
                              options={emailStatusOption}
                              className="select2-selection"
                            />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              )}
            </Row>

            <Row>
              <Col>
                <div className="text-end">
                  <Button
                    type="submit"
                    className="btn btn-success save-user"
                    color="success"
                    disabled={CreateListAPI.isLoading}
                  >
                    {!CreateListAPI.isLoading ? (
                      "Create & Send"
                    ) : (
                      <>
                        <span>Creating List...</span>
                        <Spinner size="sm"></Spinner>
                      </>
                    )}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default CreateMailWizzList
