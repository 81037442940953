import React from "react"
import { Input } from "reactstrap"
import AsyncSelect from "react-select/async"
import { useAsyncDebounce } from "react-table"
import { campaign } from "../../config";
import { useUserFilterMutation } from "redux/users/api";
const campaignStatus = campaign.status;

export const Filter = ({ column }) => {
  return (
    <div style={{ marginTop: 5 }}>
      {column.canFilter && column.render("Filter")}
    </div>
  )
}

export const DefaultColumnFilter = ({
    column: {
        filterValue,
        setFilter,
        preFilteredRows: { length },
    },
}) => {
    const [value, setValue] = React.useState(filterValue);
    const onChange = useAsyncDebounce(value => {
        setFilter(value || undefined); 
    }, 500)

    return (
        <Input
            value={value || ''}
            onChange={(e) => {
                setValue(e.target.value)
                onChange(e.target.value);
            }}
            placeholder={`Search...`}
        />
    );
};

export const CampaignStatusFilter = ({ column }) => {
  const { filterValue, setFilter } = column;
  return (
    <select
      id="custom-select"
      className="form-select"
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}
    >  
      <option value="">All</option>
      {
        Object.values(campaignStatus).map((status, i) => {
          return <option className={`text-${status.color}`} key={i} value={status.value}>{status.key}</option>
        })
      }
    </select>
  )
}

export const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) => {
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 500)

  return (
    <Input
      value={value || ""}
      onChange={e => {
        setValue(e.target.value)
        onChange(e.target.value)
      }}
      placeholder={`Global Search...`}
    />
  )
}

export const AdminFilter = ({ column }) => {
  const { filterValue, setFilter } = column
  const [userFilter, UserFilterAPI] = useUserFilterMutation()
  const loadUsers = useAsyncDebounce(async value => {
    try {
      const result = await userFilter(value).unwrap()
      return result.data || []
    } catch (error) {
      console.log(error);
      console.log("Error during fetching campaign filter")
      return []
    }
  }, 500)

  const styles = {
    // container: base => {
    //   return {
    //     ...base,
    //     width: "400px",
    //   }
    // },
  }

  return (
    <div className="ajax-select mt-lg-0 select2-container">
      <AsyncSelect
        isMulti
        cacheOptions
        loadOptions={loadUsers}
        value={filterValue}
        onChange={selectedOptions => {
          setFilter(selectedOptions)
        }}

        placeholder={"Search..."}
        noOptionsMessage={() => "No Users Found"}
        loadingMessage={() => "Searching Users"}
        styles={styles}
      />
    </div>
  )
}

