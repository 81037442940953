import React, { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
import { Helmet } from "react-helmet"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap"

// import images
import profile from "../../assets/images/profile-img.png"
import logoImg from "../../assets/images/logo.svg"
import lightlogo from "../../assets/images/logo-light.svg"
import tokenExpiredImage from "../../assets/images/token-expired.jpg"
import {
  useCheckTokenValidityMutation,
  useResetPasswordMutation,
} from "redux/auth/api"
import { showError, showToaster } from "helpers"

const ResetPassword = () => {
  const params = useParams()
  const navigate = useNavigate()
  const [resetPassword, ResetPasswordAPI] = useResetPasswordMutation()
  const [checkTokenValidity, CheckTokenValidityAPI] =
    useCheckTokenValidityMutation()
  const [passwordShow, setPasswordShow] = useState(false)
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false)
  const [tokenExpired, setTokenExpired] = useState(false)

  useEffect(() => {
    ;(async () => {
      if (params.userId && params.token) {
        try {
          const result = await checkTokenValidity(params)
          setTokenExpired(result?.data?.data?.expired || false)
        } catch (error) {
          setTokenExpired(true)
        }
      }
    })()
  }, [])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .trim()
        .matches(/^\S*$/, "Whitespace is not allowed")
        .required("Please enter New Password")
        .min(8, "New Password should have minimum 8 characters")
        .max(20, "New Password should have maximum 20 characters"),
      confirmNewPassword: Yup.string()
        .required("Please enter Confirm New Password")
        .matches(/^\S*$/, "Whitespace is not allowed")
        .oneOf(
          [Yup.ref("newPassword"), null],
          "Confirm New Password must be same as New Password"
        ),
    }),
    onSubmit: async values => {
      try {
        const payload = {
          ...params,
          newPassword: values.newPassword,
        }
        const result = await resetPassword(payload).unwrap()
        showToaster("success", result.message, "Success")
        navigate("/login", { replace: true })
      } catch (error) {
        showError(error)
        setTokenExpired(true)
      }
    },
  })

  return (
    <React.Fragment>
      <Helmet>
        <title>Reset Password | Email Hunter</title>
      </Helmet>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Reset Password</h5>
                      </div>
                    </Col>
                    <Col xs={5} className="align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <div className="auth-logo">
                      <Link to="/" className="auth-logo-light">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={lightlogo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                      <Link to="/" className="auth-logo-dark">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logoImg}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                  </div>

                  {CheckTokenValidityAPI.isLoading ? (
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                        textAlign: "center",
                      }}
                      className="text-center"
                    >
                      <Spinner
                        type="grow"
                        className="m-5"
                        color="primary"
                        style={{
                          height: "3rem",
                          width: "3rem",
                        }}
                      />
                    </div>
                  ) : (
                    <>
                      {!tokenExpired ? (
                        <div className="p-2">
                          <Form
                            className="form-horizontal"
                            onSubmit={e => {
                              e.preventDefault()
                              validation.handleSubmit()
                              return false
                            }}
                          >
                            {/* password */}
                            <div className="mb-3">
                              <Label className="form-label">
                                New Password*
                              </Label>
                              <div className="input-group auth-pass-inputgroup">
                                <Input
                                  name="newPassword"
                                  type={passwordShow ? "text" : "password"}
                                  placeholder="Enter New Password"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.newPassword || ""}
                                  invalid={
                                    validation.touched.newPassword &&
                                    validation.errors.newPassword
                                      ? true
                                      : false
                                  }
                                  onPaste={e => {
                                    e.preventDefault()
                                    return false
                                  }}
                                  onCopy={e => {
                                    e.preventDefault()
                                    return false
                                  }}
                                />
                                <button
                                  onClick={() => setPasswordShow(!passwordShow)}
                                  className="btn btn-light "
                                  type="button"
                                  id="password-addon"
                                >
                                  <i
                                    className={`mdi ${
                                      !passwordShow
                                        ? "mdi-eye-outline"
                                        : "mdi-eye-off-outline"
                                    }`}
                                  ></i>
                                </button>
                                {validation.touched.newPassword &&
                                validation.errors.newPassword ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.newPassword}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </div>

                            {/* confirm password */}
                            <div className="mb-3">
                              <Label className="form-label">
                                Confirm New Password*
                              </Label>
                              <div className="input-group auth-pass-inputgroup">
                                <Input
                                  name="confirmNewPassword"
                                  type={
                                    confirmPasswordShow ? "text" : "password"
                                  }
                                  placeholder="Enter Confirm Password"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.confirmNewPassword || ""
                                  }
                                  invalid={
                                    validation.touched.confirmNewPassword &&
                                    validation.errors.confirmNewPassword
                                      ? true
                                      : false
                                  }
                                  onPaste={e => {
                                    e.preventDefault()
                                    return false
                                  }}
                                  onCopy={e => {
                                    e.preventDefault()
                                    return false
                                  }}
                                />
                                <button
                                  onClick={() =>
                                    setConfirmPasswordShow(!confirmPasswordShow)
                                  }
                                  className="btn btn-light "
                                  type="button"
                                  id="password-addon"
                                >
                                  <i
                                    className={`mdi ${
                                      !confirmPasswordShow
                                        ? "mdi-eye-outline"
                                        : "mdi-eye-off-outline"
                                    }`}
                                  ></i>
                                </button>
                                {validation.touched.confirmNewPassword &&
                                validation.errors.confirmNewPassword ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.confirmNewPassword}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </div>
                            <div className="text-end">
                              <button
                                className="btn btn-primary w-md "
                                type="submit"
                                disabled={ResetPasswordAPI.isLoading}
                              >
                                {!ResetPasswordAPI.isLoading ? (
                                  "Save"
                                ) : (
                                  <>
                                    <span>Saving...</span>
                                    <Spinner size="sm"></Spinner>
                                  </>
                                )}
                              </button>
                              <div className="mt-5 text-center">
                                <p>
                                  Go To{" "}
                                  <Link
                                    to="/login"
                                    className="fw-medium text-primary"
                                  >
                                    {" "}
                                    Login
                                  </Link>{" "}
                                  Page
                                </p>
                              </div>
                            </div>
                          </Form>
                        </div>
                      ) : (
                        <>
                          <Row className="justify-content-center mt-lg-5">
                            <Col xl="12" sm="8">
                              <Card>
                                <CardBody>
                                  <h5 className="text-center font-bold">
                                    Token Expired{" "}
                                    <i className="fas fa-sad-cry"></i>
                                  </h5>
                                  <div className="text-center">
                                    <Row className="justify-content-center mb-2">
                                      <Col sm="8" xs="8">
                                        <div>
                                          <img
                                            src={tokenExpiredImage}
                                            alt=""
                                            className="img-fluid"
                                          />
                                        </div>
                                        <div className="mt-5 text-center">
                                          <p>
                                            Go To{" "}
                                            <Link
                                              to="/login"
                                              className="fw-medium text-primary"
                                            >
                                              {" "}
                                              Login
                                            </Link>{" "}
                                            Page
                                          </p>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      )}
                    </>
                  )}
                </CardBody>
              </Card>
              <div className="mt-4 mt-md-5 text-center">
                <p className="mb-0">
                  © {new Date().getFullYear()} Email Hunter. Built with{" "}
                  <i className="mdi mdi-heart text-danger"></i> by Webcodegenie
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ResetPassword
