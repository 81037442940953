import React, { useEffect, useState } from 'react'
import { Row, Col, Card, CardBody } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import { formatBigNumber } from 'helpers/data-formatters';
import { useDispatch, useSelector } from 'react-redux';
import { useUsersRecordMutation } from 'redux/dashboard/api';
import { selectUserRecords, setUserRecord } from 'redux/dashboard/slice';
import { addMonths } from 'date-fns';
import moment from 'moment';
import { showError } from 'helpers';
const Users = () => {
    const [duration, setDuration] = useState(`${addMonths(new Date(), -11)}`);
    const dispatch = useDispatch();
    const [getUsersRecord, GetUserApi] = useUsersRecordMutation()
    const usersRecord = useSelector(selectUserRecords)
    const [selectedRange, setSelectedRange] = useState([addMonths(new Date(), -11), new Date()]);
    const [chartData, setChartData] = useState({ count: [], Xaxis: [] })
    const handeClick = (month) => {
        setDuration(month)
        const rangeDate = addMonths(new Date(month), 11);
        setSelectedRange([new Date(month), rangeDate])
    }
    useEffect(() => {
        const getData = async () => {
            const startdate = moment(selectedRange[0]).format("YYYY-MM");
            const enddate = moment(selectedRange[1]).format("YYYY-MM-DD");
            const timeDuration = { startDate: startdate, endDate: enddate }
            try {
                const result = await getUsersRecord(timeDuration).unwrap()
                dispatch(setUserRecord(result.data))
            } catch (error) {
                showError(error)
            }
        }
        getData()

    }, [duration])
    useEffect(() => {
        const chartdata = { count: [], Xaxis: [] }
        if (usersRecord?.user) {
            usersRecord?.user.map(data => {
                const { date, count } = data
                chartdata.count = [...chartdata.count, count]
                chartdata.Xaxis = [...chartdata.Xaxis, moment(date).format("MMM 'YY")]
            })
            setChartData({ count: chartdata.count, Xaxis: chartdata.Xaxis })
        }
    }, [usersRecord?.user])
    const options = {
        chart: {
            toolbar: "false",
            dropShadow: {
                enabled: !0,
                color: "#000",
                top: 18,
                left: 7,
                blur: 8,
                opacity: 0.2,
            },
        },
        xaxis: {
            categories: chartData.Xaxis,
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },
        dataLabels: {
            enabled: !1,
        },
        colors: ['#1d39e2', '#DF362D', '#eb347d', "#47B39C", "#FFC154", "#EC6B56"],
        stroke: {
            curve: "smooth",
            width: 3,
        },
    };

    const series = [
        {
            name: "Admins",
            data: chartData.count,
        },
    ];
    return (
        <React.Fragment>
            <Col>
                <Card>
                    <CardBody>
                        <div className="clearfix">
                            <div className="float-end">
                                <div className="input-group input-group-sm">
                                    <select
                                        className="form-select form-select-sm"
                                        value={duration}
                                        onChange={(e) => {
                                            handeClick(e.target.value);
                                        }}
                                    >
                                        <option value={`${addMonths(new Date(), -11)}`}>Last 12 Month</option>
                                        <option value={`${new Date().getFullYear() - 1}`}>{new Date().getFullYear() - 1} </option>
                                        <option value={`${new Date().getFullYear() - 2}`}>{new Date().getFullYear() - 2}</option>
                                    </select>
                                    <div className="input-group-append">
                                        <label className="input-group-text">year</label>
                                    </div>
                                </div>
                            </div>
                            <h4 className="card-title mb-4">Admin Users</h4>
                        </div>
                        <Row>
                            <Col xl={3}>
                                <Row>
                                    <Col xl="12" lg='4' sm="4" className="mt-4">
                                        <div className="social-source text-center mt-3">
                                            <p className="font-size-15 mb-4">
                                                <i
                                                    className={"mdi mdi-checkbox-blank-circle text-success align-middle mb-0 me-1"}
                                                />
                                                Active                                                <p className="text-black font-size-15 mb-0">{formatBigNumber(usersRecord?.Active)}</p>
                                            </p>
                                        </div>
                                    </Col >
                                    <Col xl="12" lg='4' sm="4" className="mt-4">
                                        <div className="social-source text-center mt-3">
                                            <p className="font-size-15 mb-4">
                                                <i
                                                    className={"mdi mdi-checkbox-blank-circle text-danger align-middle mb-0 me-1"}
                                                />
                                                In-Active
                                                <p className="text-black font-size-15 mb-0">{formatBigNumber(usersRecord?.inActive)}</p>
                                            </p>
                                        </div>
                                    </Col >
                                    <Col xl="12" lg='4' sm="4" className="mt-4">
                                        <div className="social-source text-center mt-3">
                                            <p className="font-size-15 mb-4">
                                                <i
                                                    className={"mdi mdi-archive-arrow-down text-success h3 align-middle mb-0 me-1"}
                                                />
                                                Archived
                                                <p className="text-black font-size-15 mb-0">{formatBigNumber(usersRecord?.isArchived)}</p>
                                            </p>
                                        </div>
                                    </Col >
                                </Row>
                            </Col>
                            <Col xl="9">
                                <div id="line-chart" dir="ltr">
                                    <ReactApexChart
                                        series={series}
                                        options={options}
                                        type="line"
                                        height={320}
                                        className="apex-charts"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col >
        </React.Fragment >
    )
}

export default Users