import React, { useEffect, useRef, useCallback } from "react"
import { useLocation } from "react-router-dom"
import PropTypes from "prop-types"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import withRouter from "components/Common/withRouter"
import { Link } from "react-router-dom"
import { authentiCateUserRole } from "helpers"
import { useSelector } from "react-redux"
import { selectCurrentUser } from "redux/auth/slice"
import config from "../../config"

const SidebarContent = props => {
  const ref = useRef()
  const user = useSelector(selectCurrentUser)
  const activateParentDropdown = useCallback(item => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }, [])

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i]
      const parent = items[i].parentElement

      if (item && item.classList.contains("active")) {
        item.classList.remove("active")
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show")
        }

        parent.classList.remove("mm-active")
        const parent2 = parent.parentElement

        if (parent2) {
          parent2.classList.remove("mm-show")

          const parent3 = parent2.parentElement
          if (parent3) {
            parent3.classList.remove("mm-active") // li
            parent3.childNodes[0].classList.remove("mm-active")

            const parent4 = parent3.parentElement // ul
            if (parent4) {
              parent4.classList.remove("mm-show") // ul
              const parent5 = parent4.parentElement
              if (parent5) {
                parent5.classList.remove("mm-show") // li
                parent5.childNodes[0].classList.remove("mm-active") // a tag
              }
            }
          }
        }
      }
    }
  }

  const path = useLocation()
  const activeMenu = useCallback(() => {
    const pathName = path.pathname
    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")

    removeActivation(items)

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }, [path.pathname, activateParentDropdown])

  useEffect(() => {
    ref.current.recalculate()
  }, [])

  useEffect(() => {
    new MetisMenu("#side-menu")
    activeMenu()
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    activeMenu()
  }, [activeMenu])

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {authentiCateUserRole(user.role, config.userRoles.admin.key) && (
              <li>
                <Link to="/dashboard">
                  <i className="bx bx-home-circle"></i>
                  <span>Dashboard</span>
                </Link>
              </li>
            )}

            <li style={{ display: authentiCateUserRole(user.role, config.userRoles.root.key) ? "block" : "none" }}>
              <Link to="/#" className="has-arrow" >
                <i className="bx bx-user-circle"></i>
                <span>User</span>
              </Link>
              <ul className="sub-menu">
                {authentiCateUserRole(user.role, config.userRoles.root.key) && (
                  <>
                    <li>
                      <Link to="/users">
                        <i className="bx bx-list-ul"></i>
                        <span>List</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/archived-users">
                        <i className="bx bx-archive-in"></i>
                        <span>Archived</span>
                      </Link>
                    </li>
                  </>
                )}
              </ul>
            </li>

            {authentiCateUserRole(user.role, config.userRoles.admin.key) && (
              <>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bx-bolt-circle"></i>
                    <span>Campaign</span>
                  </Link>

                  <ul className="sub-menu">
                    <li>
                      <Link to="/campaigns">
                        <i className="bx bx-list-ul"></i>
                        <span>List</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/archived-campaigns">
                        <i className="bx bx-archive-in"></i>
                        <span>Archived</span>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/emails">
                    <i className="bx bx-envelope"></i>
                    <span>Emails</span>
                  </Link>
                </li>
                <li>
                  <Link to="/urls">
                    <i className="mdi mdi-web"></i>
                    <span>Urls</span>
                  </Link>
                </li>
                <li>
                  <Link to="/exportrequest">
                    <i className="mdi mdi-file-export-outline"></i>
                    <span>Export Request</span>
                  </Link>
                </li>
                <li>
                  <Link to="/verify-emails">
                    <span
                      className="badge rounded-pill bg-success float-end"
                      key="t-new"
                    >
                      New
                    </span>
                    <i className="mdi mdi-email-check-outline"></i>
                    <span>Verify Emails</span>
                  </Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bx-cog"></i>
                    <span>Settings</span>
                  </Link>

                  <ul className="sub-menu">
                    <li>
                      <Link to="/settings">
                        <i className="bx bx-mail-send"></i>
                        <span>
                          {user.role === config.userRoles.root.key
                            ? "Mail Verify"
                            : "MailWizZ"}
                        </span>
                      </Link>
                    </li>
                    {authentiCateUserRole(
                      user.role,
                      config.userRoles.root.key
                    ) && (
                      <li>
                        <Link to="/black-listed-domains">
                          <i className="mdi mdi-skull-crossbones"></i>
                          <span>Black Listed Domains</span>
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
              </>
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
}

export default withRouter(SidebarContent)
