import React, { useCallback, useState } from "react"
import { Col, Row, Card, CardBody, Container, Button } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Helmet } from "react-helmet"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import { showError } from "helpers"
import { useDispatch } from "react-redux"
import List from "./List"
import { useCampaignGetMutation } from "redux/campaigns/api"
import { setCampaign } from "redux/campaigns/slice"
import RevokeFromArchiveModal from "../Modals/RevokeFromArchiveModal"
import AddEditModal from "../Modals/AddEdit/Index"

const ArchievedCampaigns = props => {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false)
  const [loadList, setLoadList] = useState(true)
  const [openRevokeArchiveModal, setOpenRevokeArchiveModal] = useState(false);
  const [openAddCampaignModal, setOpenAddCampaignModal] = useState(false)
  const [getCampaign, GetCampaignApi] = useCampaignGetMutation();

  const onEditCampaign = async id => {
    try {
      const result = await getCampaign(id).unwrap()
      dispatch(setCampaign(result.data))
      setIsEdit(true)
      setOpenAddCampaignModal(true)
    } catch (error) {
      showError(error)
    }
  }

  const onRevokeFromArchive = async id => {
    try {
      const result = await getCampaign(id).unwrap()
      dispatch(setCampaign(result.data))
      setOpenRevokeArchiveModal(true)
    } catch (error) {
      showError(error)
    }
  }

  const BreadcrumbDetails = {
    breadcrumbItem: {
      label: "Archived",
      link: "archived-campaigns",
    },
    paths: [
      {
        label: "Campaign",
        link: "archived-campaigns",
      },
    ],
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Campaigns | Email-Hunter</title>
      </Helmet>

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs breadcrumbDetails={BreadcrumbDetails} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <List
                    load={loadList}
                    onRevokeFromArchive={onRevokeFromArchive}
                    onEditCampaign={onEditCampaign}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <RevokeFromArchiveModal
            show={openRevokeArchiveModal}
            setShow={setOpenRevokeArchiveModal}
            setLoadList={setLoadList}
          />

          <AddEditModal
            open={openAddCampaignModal}
            setOpen={setOpenAddCampaignModal}
            setLoadList={setLoadList}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ArchievedCampaigns
