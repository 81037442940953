import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Routes, Route, useNavigate, useLocation } from "react-router-dom"
import { layoutTypes } from "./constants/layout"
// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes"

// Import all middleware
import RequireAuth from "./routes/RequireAuth"
// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"
import "toastr/build/toastr.min.css"
import {
  setCredentials,
  logOut,
  selectCurrentToken,
  selectCurrentUser,
  selectLogout,
} from "redux/auth/slice"
import { useLogoutMutation, useRefreshMutation } from "redux/auth/api"
import FullPageLoader from "./components/Common/FullPageLoader"
import RedirectToDashboard from "routes/RedirectToDashboard"

const getLayout = layoutType => {
  return VerticalLayout
}

const RefreshExcludePaths = ["/reset-password", "/login"]

const App = () => {
  const [loading, setLoading] = useState(true)
  const { layoutType } = useSelector(state => ({
    layoutType: state.layout.layoutType,
  }))

  const locationState = useLocation()
  const isLogout = useSelector(selectLogout)

  const [refresh, refreshAPI] = useRefreshMutation()
  const [logout, logoutAPI] = useLogoutMutation()
  const dispatch = useDispatch()

  useEffect(() => {
    ;(async () => {
      if (
        locationState.pathname != "/login" &&
        !locationState.pathname.startsWith("/reset-password") &&
        locationState.pathname != "/forgot-password"
      ) {
        try {
          const result = await refresh().unwrap()
          if (result.status == 200) {
            dispatch(setCredentials(result.data))
          }
          setLoading(false)
        } catch (error) {
          setLoading(false)
          dispatch(logOut())
        }
      } else {
        setLoading(false)
      }
    })()
  }, [])

  useEffect(() => {
    if (
      isLogout &&
      locationState.pathname != "/login" &&
      !locationState.pathname.startsWith("/reset-password") &&
      locationState.pathname != "/forgot-password"
    ) {
      ;(async () => {
        try {
          setLoading(true)
          const result = await logout().unwrap()
          setLoading(false)
        } catch (error) {
          setLoading(false)
        }
      })()
    }
  }, [isLogout, locationState])

  const Layout = getLayout(layoutType)
  return (
    <React.Fragment>
      {!loading ? (
        <Routes>
          <Route element={<RedirectToDashboard />}>
            {publicRoutes.map((route, idx) => (
              <Route
                path={route.path}
                element={<NonAuthLayout>{route.component}</NonAuthLayout>}
                key={idx}
                exact={true}
              />
            ))}
          </Route>

          {authProtectedRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <RequireAuth userRole={route.userRole}>
                  <Layout>{route.component}</Layout>
                </RequireAuth>
              }
              key={idx}
              exact={true}
            />
          ))}
        </Routes>
      ) : (
        <FullPageLoader />
      )}
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

export default App
