import React, {  useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import Breadcrumbs from "../../components/Common/Breadcrumb"
import List from './List'
import { useExportCSVDownloadMutation } from 'redux/exportRequest/api'
import { showToaster } from 'helpers'
import { useSearchParams } from 'react-router-dom'
import "./Export.css"
const ExportRequest = (props) => {
    const [downloadfile, downloadfileAPI] = useExportCSVDownloadMutation()
    const [searchParams, setSearchParams] = useSearchParams()
    const [load, setload] = useState(true)

    const onDownload = async (userID, fileID, createDate, Type) => {
        try {
            const result = await downloadfile({ userID: userID, fileID: fileID, createDate: createDate, Type: Type }).unwrap()
        } catch (error) {
            showToaster("info", "File has been expired!", 'Info');
            setload(load => !load)
        }
    }
    useEffect(() => {
        if (searchParams.get("download")) {
            onDownload(searchParams.get("download"))
                .finally(() => {
                    searchParams.delete("download")
                    setSearchParams(searchParams)
                });
        }
    }, [searchParams])

    const BreadcrumbDetails = {
        breadcrumbItem: {
            label: 'Export Request',
            link: 'exportrequest'
        },
        paths: []
    }
    return (
        <React.Fragment>
            <Helmet>
                <title>Urls | Email-Hunter</title>
            </Helmet>

            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs breadcrumbDetails={BreadcrumbDetails} />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <List
                                        onDownload={onDownload}
                                        load={load}

                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default ExportRequest