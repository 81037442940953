import { campaign } from "config"
import { showError } from "helpers"
import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useParams } from "react-router-dom"
import { Badge, Card, CardBody, CardImg, Col, Container, Row, Spinner } from "reactstrap"
import { useUrlGetMutation } from "redux/urls/api"
import { selectUrl, setUrl } from "redux/urls/slice"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Urlsimg from "../../../assets/images//gif/Urldetaile.gif"
import { formatBigNumber, formatDate, wordTitleCase, wordsTitleCase } from "helpers/data-formatters"
import config from "../../../config"
import CreateMailList from "./MailList"
import MiniCard from "./MiniCard"
const UrlStatus = config.url.status
const UrlsDetails = props => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const url = useSelector(selectUrl)
    const [getUrl, GetUrlAPI] = useUrlGetMutation()
    useEffect(() => {
        ; (async () => {
            try {
                const result = await getUrl(id).unwrap()
                dispatch(setUrl(result.data.urlDetails))
            } catch (error) {
                showError(error)
            }
        })()
    }, [id])

    useEffect(() => {
        return () => {
            dispatch(setUrl(null))
        }
    }, [])
    const BreadcrumbDetails = {
        breadcrumbItem: {
            label: url?.domain.toString(),
            link: `Urls/${url?._id.toString()}`,
        },
        paths: [
            {
                label: "Urls",
                link: "Urls",
            },
        ],
    }
    return (
        <React.Fragment>
            <Helmet>
                <title>Domain Details | Email-Hunter</title>
            </Helmet>

            <div className="page-content">
                <Container fluid>
                    {/* Loader  */}
                    {GetUrlAPI.isLoading && (
                        <div
                            style={{
                                height: "100%",
                                width: "100%",
                                textAlign: "center",
                            }}
                            className="text-center"
                        >
                            <Spinner
                                type="grow"
                                className="m-5"
                                color="primary"
                                style={{
                                    height: "3rem",
                                    width: "3rem",
                                }}
                            />
                        </div>
                    )}
                    {/* Loader End  */}

                    {/* urls Details Start */}
                    {!GetUrlAPI.isLoading && url && (
                        <>
                            <Breadcrumbs breadcrumbDetails={BreadcrumbDetails} />
                            <Row>
                                <Col xl={3} lg={4} className="bg-transparent">
                                    <Card className="overflow-hidden">
                                        <CardBody >
                                            <div className="hstack gap-2">
                                                <h5 className="fw-semibold">Domain Details</h5>
                                            </div>
                                            <div className="text-center">
                                                <CardImg
                                                    className="img-fluid bg-transparent p-4 h-50 w-75"
                                                    src={Urlsimg}
                                                    alt="a"
                                                />
                                            </div>
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <tbody>
                                                        <tr>
                                                            <th scope="col">Domain</th>
                                                            <td scope="col">
                                                                {wordsTitleCase(url?.domain)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Wordpress Site</th>
                                                            <td>
                                                                <Badge className={"font-size-12 badge-soft-" + [url?.isWordPress ? "success" : "danger"]} pill>
                                                                {wordsTitleCase ( url?.isWordPress ? "Yes" : "No")}
                                                                </Badge>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Status</th>
                                                            <td>
                                                                <Badge className={"font-size-12 badge-soft-" + UrlStatus[url?.status == 'In-progress' ? 'progress' : url?.status]} pill>
                                                                    {wordTitleCase(url?.status)}
                                                                </Badge>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Campaign</th>
                                                            <td>
                                                                {wordsTitleCase(url?.campaignName)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Response Time</th>
                                                            <td>
                                                                {formatBigNumber(url?.responseTime)} Sec
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Protocol</th>
                                                            <td>
                                                                {url?.Protocol}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Created On</th>
                                                            <td>{formatDate(url?.createdOn)}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Updated On</th>
                                                            <td>{formatDate(url?.updatedOn)}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col xl={9} lg={8}>
                                    <Row>
                                        <MiniCard
                                            title="#Sub Urls"
                                            iconClass="mdi mdi-link-variant"
                                            text={url.totalSubUrl}
                                            color={"primary"}
                                        />
                                        <MiniCard
                                            title="#Emails"
                                            iconClass="mdi mdi-email-search"
                                            text={url.emails}
                                            color={"info"}
                                        />
                                    </Row>
                                    <CreateMailList />
                                </Col>
                            </Row>

                        </>
                    )}
                    {/* urls Details End */}
                </Container>
            </div >
        </React.Fragment >
    )
}

export default UrlsDetails