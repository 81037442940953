import React, { useState } from "react"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Helmet } from "react-helmet"
import {
  Col,
  Row,
  Card,
  Container,
  Button,
  Progress,
  Spinner,
} from "reactstrap"
import { useSelector } from "react-redux"
import TagInput from "components/Common/TagInput"
import { showError, showToaster } from "helpers"
import Dropzone from "react-dropzone"
import { formatFileSize } from "helpers/data-formatters"
import CsvIcon from "../../../assets/images/icon/csv.png"
import { useUploadBlackListedDomainsMutation } from "redux/black-list-domains/api"
import { URL_REGEX } from "constants/regex"
import { selectBlackListDomainFileUploadProgress } from "redux/black-list-domains/slice"
import List from "./List"

const KeyCodes = {
  comma: 188,
  enter: 13,
  newLine: 10,
}

const delimiters = [KeyCodes.comma, KeyCodes.enter, KeyCodes.newLine]

const BlackListedDomains = props => {
  const uploadProgress = useSelector(selectBlackListDomainFileUploadProgress)
  const [wantToImport, setWantToImport] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState([])
  const [urls, setUrls] = useState([])
  const [loadList, setLoadList] = useState(false)
  const [uploadDomain, UploadDomainAPI] = useUploadBlackListedDomainsMutation()

  const BreadcrumbDetails = {
    breadcrumbItem: {
      label: "Black Listed Domains",
      link: "black-listed-domains",
    },
    paths: [],
  }

  const handleAddition = tag => {
    const text = tag.text.trim().replaceAll(/(\n||\n)/g, "")
    tag.text = text.replaceAll(/["']/g, "")
    const isValid = tag.text.match(URL_REGEX)
    if (!isValid) {
      return
    }

    // check url is already exists or not, if exists skip that url
    if (urls.findIndex(value => value.text === tag.text) >= 0) {
      showToaster("info", "Already Exists !!")
      return
    }
    setUrls(previousUrls => {
      return [...previousUrls, tag.text]
    })
  }

  const onRemove = idx => {
    setUrls(urls.filter((url, index) => index !== idx))
  }

  const onClear = () => {
    setUrls([])
  }

  const handleDropeedFile = files => {
    const Files = files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatFileSize(file.size),
      })
    )
    setSelectedFiles(Files)
  }

  const onFileUploadError = error => {
    showError(error)
  }

  const onUpload = async () => {
    const formData = new FormData()
    if (wantToImport && selectedFiles.length) {
      formData.append("csv", selectedFiles[0])
    }
    if (!wantToImport && urls.length) {
      formData.append("urls", JSON.stringify(urls))
    }
    try {
      await uploadDomain(formData).unwrap()
      setSelectedFiles([])
      setUrls([])
      setLoadList(!loadList)
      showToaster(
        "success",
        `${wantToImport ? "File" : "Domains"} Successfully Uploaded`,
        "Success"
      )
    } catch (error) {
      showError(error)
    }
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Black Listed Domains | Email-Hunter</title>
      </Helmet>

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs breadcrumbDetails={BreadcrumbDetails} />

          <Row>
            <Col className="text-start col-md-6">
              <div className="form-check form-switch form-switch-lg ms-2">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="wantToSearchInMasterEmail"
                  name="wantToSearchInMasterEmail"
                  disabled={UploadDomainAPI.isLoading}
                  onChange={() => {
                    setWantToImport(!wantToImport)
                  }}
                  checked={wantToImport}
                />
                <label
                  className="form-check-label"
                  htmlFor="wantToSearchInMasterEmail"
                >
                  Import From File ?
                </label>
              </div>
            </Col>
            <Col className="col-md-6">
              <div className="text-end">
                {urls.length ? (
                  <Button
                    color={"secondary"}
                    className="btn me-2"
                    onClick={onClear}
                    disabled={UploadDomainAPI.isLoading}
                  >
                    Clear
                  </Button>
                ) : (
                  <></>
                )}
                <Button
                  color="primary"
                  onClick={onUpload}
                  disabled={
                    UploadDomainAPI.isLoading ||
                    (wantToImport && !selectedFiles.length) ||
                    (!wantToImport && !urls.length)
                  }
                >
                  <i className="bx bx-upload me-2"></i>
                  {!UploadDomainAPI.isLoading ? (
                    "Upload"
                  ) : (
                    <>
                      <span>Uploading...</span>
                      <Spinner size="sm"></Spinner>
                    </>
                  )}
                </Button>
              </div>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col>
              <Card>
                <div className="email-wrapper border rounded-3">
                  <div className="d-flex h-100 flex-column">
                    <div className="row justify-content-center h-100">
                      <div className="col-lg-9 align-self-center">
                        <div className="text-center">
                          {!wantToImport ? (
                            <>
                              <Row>
                                <Col className="col-md-12 text-center">
                                  <TagInput
                                    handleAddition={handleAddition}
                                    delimiters={delimiters}
                                    placeholder={
                                      "Write Or Paste Comma Separated Domains Here"
                                    }
                                    allowUnique={true}
                                  />
                                </Col>
                              </Row>
                              {urls.length ? (
                                <div className="d-flex flex-wrap gap-2 justify-content-center mb-3 mt-3">
                                  {urls.map((url, idx) => {
                                    return (
                                      <button
                                        type="button"
                                        title="Copy URL"
                                        onClick={e => {
                                          oncopy(url)
                                        }}
                                        className={`btn btn-primary`}
                                        key={idx}
                                      >
                                        {url}

                                        <i
                                          title="Remove URL"
                                          className="mdi mdi-close-octagon-outline ms-2 pe-auto"
                                          onClick={e => {
                                            onRemove(idx)
                                            e.stopPropagation()
                                          }}
                                        ></i>
                                      </button>
                                    )
                                  })}
                                </div>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <>
                              <Dropzone
                                onError={onFileUploadError}
                                // disabled={UpdateCampaignAPI.isLoading}
                                accept={{
                                  "text/csv": [".csv"],
                                }}
                                maxFiles={1}
                                onDrop={files => {
                                  handleDropeedFile(files)
                                }}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div className="">
                                    <div
                                      className="dz-message needsclick"
                                      {...getRootProps()}
                                    >
                                      <input {...getInputProps()} />
                                      <div className="mb-3">
                                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                                      </div>
                                      <h4>
                                        Drop files here or click to upload.
                                      </h4>
                                    </div>
                                    <div
                                      className="dropzone-previews"
                                      id="file-previews"
                                    >
                                      {selectedFiles.map((f, i) => {
                                        return (
                                          <Card
                                            className="mt-1 mb-1 shadow border-none rounded-3 bg-transparent dz-processing dz-image-preview dz-success dz-complete"
                                            key={i + "-file"}
                                          >
                                            <div className="p-2">
                                              <Row className="align-items-center justify-content-center">
                                                <Col className="col-auto">
                                                  <img
                                                    data-dz-thumbnail=""
                                                    className="avatar-sm rounded bg-light"
                                                    alt={f.name}
                                                    src={CsvIcon}
                                                  />
                                                </Col>
                                                <Col className="col-auto">
                                                  <p
                                                    to="#"
                                                    className="text-muted font-weight-bold"
                                                  >
                                                    {f.name}
                                                  </p>
                                                  <p className="mb-0">
                                                    <strong>
                                                      {f.formattedSize}
                                                    </strong>
                                                  </p>
                                                </Col>
                                                {UploadDomainAPI.isLoading &&
                                                  wantToImport && (
                                                    <Col className="col-8 text-center">
                                                      <Progress
                                                        value={uploadProgress}
                                                        color="warning"
                                                        className="progress-xl"
                                                      >
                                                        {uploadProgress} %
                                                      </Progress>
                                                    </Col>
                                                  )}
                                              </Row>
                                            </div>
                                          </Card>
                                        )
                                      })}
                                    </div>
                                  </div>
                                )}
                              </Dropzone>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col>
              <List load={loadList} />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BlackListedDomains
