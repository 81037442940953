import React, { useEffect, useRef, useState } from "react"
import { Input, Label } from "reactstrap"
import AsyncSelect from "react-select/async"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

import { useAsyncDebounce } from "react-table"
import { campaign } from "../../config"
import { useCampaignFilterMutation } from "redux/campaigns/api"
import moment from "moment";

export const Filter = ({ column }) => {
  return (
    <div style={{ marginTop: 5 }}>
      {column.canFilter && column.render("Filter")}
    </div>
  )
}

export const DefaultColumnFilter = ({
  column: {
    filterValue,
    setFilter,
    preFilteredRows: { length },
  },
}) => {
  const [value, setValue] = React.useState(filterValue)
  const onChange = useAsyncDebounce(value => {
    setFilter(value || undefined)
  }, 500)

  return (
    <Input
      value={value || ""}
      onChange={e => {
        setValue(e.target.value)
        onChange(e.target.value)
      }}
      placeholder={`Search...`}
    />
  )
}

export const CampaignFilter = ({ column }) => {
  const { filterValue, setFilter } = column
  const [campaignFilter, CampaignFilterAPI] = useCampaignFilterMutation()
  const loadCampaigns = useAsyncDebounce(async value => {
    try {
      const result = await campaignFilter(value).unwrap()
      return result.data || []
    } catch (error) {
      console.log(error);
      console.log("Error during fetching campaign filter")
      return []
    }
  }, 500)

  const styles = {
    // container: base => {
    //   return {
    //     ...base,
    //     width: "400px",
    //   }
    // },
  }

  return (
    <div className="ajax-select mt-lg-0 select2-container">
      <AsyncSelect
        isMulti
        cacheOptions
        loadOptions={loadCampaigns}
        value={filterValue}
        onChange={selectedOptions => {
          console.log("Changed", selectedOptions)
          setFilter(selectedOptions)
        }}

        placeholder={"Search..."}
        noOptionsMessage={() => "No Campaigns Found"}
        loadingMessage={() => "Searching Campaigns"}
        styles={styles}
      />
    </div>
  )
}

export const DateRangeFilter = ({ column }) => {
  const { filterValue, setFilter } = column;
  const flatPickerRef = useRef(null);
  return (
    <Flatpickr
      className="form-control d-block"
      placeholder="Search By Date Range..."
      value={filterValue}
      ref={flatPickerRef}
      options={{
        mode: "range",
        showMonths:2,
        dateFormat: "YYYY-MM-DD",
        parseDate: (datestr, format) => {
          return moment(datestr, format, true).toDate();
        },
        formatDate: (date, format, locale) => {
          return moment(date).format(format);
        },
      }}
      onChange={(dates) => {
        if(dates.length == 2 || dates.length == 0) {
          setFilter(dates);
        }
      }}
    />
  )
}

export const StatusFilter = ({ column }) => {
  const { filterValue, setFilter } = column
  return (
    <select
      id="custom-select"
      className="form-select"
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}
    >
      <option value="">All</option>
      <option value="pending">Pending</option>
      <option value='ok'>Ok</option>
      <option value='fail'>Fail</option>
      <option value='unknown'>Unknown</option>
    </select>
  )
}

export const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) => {
  const [value, setValue] = React.useState(globalFilter)

  const [campaignFilter, CampaignFilterAPI] = useCampaignFilterMutation()
  const loadCampaigns = useAsyncDebounce(async value => {
    try {
      const result = await campaignFilter(value).unwrap()
      return result.data || []
    } catch (error) {
      console.log(error);
      console.log("Error during fetching campaign filter")
      return []
    }
  }, 500);

  return (
    <div className="ajax-select mt-lg-0 select2-container">
      <AsyncSelect
        isMulti
        cacheOptions
        loadOptions={loadCampaigns}
        value={value}
        onChange={selectedOptions => {
          setGlobalFilter(selectedOptions)
        }}

        placeholder={"Search By Campaign..."}
        noOptionsMessage={() => "No Campaigns Found"}
        loadingMessage={() => "Searching Campaigns"}
        // styles={styles}
      />
    </div>
  )
}
