import React, { useEffect, useMemo, useState } from "react"
import Dropzone from "react-dropzone"
import { isEmpty } from "lodash"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useSelector, useDispatch } from "react-redux"
import {
  Form,
  Progress,
  Row,
  Col,
  Button,
  Spinner,
  Card,
  CardBody,
} from "reactstrap"
import { showError, showToaster } from "helpers"
import Swal from "sweetalert2"
import {
  selectCampaign,
  selectFileUploadProgress,
  setCampaign,
  setFileUploadProgress,
} from "redux/campaigns/slice"
import { useCampaignUpdateMutation } from "redux/campaigns/api"
import { Link } from "react-router-dom"
import CsvIcon from "../../../../../assets/images/icon/csv.png"
import SampleCSVImage from "../../../../../assets/images/sample-csv.png"
import config from "../../../../../config"

function SecondStep({ activeTab, setactiveTab }) {
  const dispatch = useDispatch()
  const [newFileSelected, setNewFileSelected] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState([])
  const campaign = useSelector(selectCampaign)
  const uploadProgress = useSelector(selectFileUploadProgress)
  const [updateCampaign, UpdateCampaignAPI] = useCampaignUpdateMutation()

  const onFileUploadError = error => {
    showError(error)
  }

  const SampleCSVPath = useMemo(() => {
    let fileName = "sample-email.csv"
    if (
      campaign &&
      campaign.type === "email-scraping" &&
      campaign.subType === "url"
    ) {
      fileName = "sample-url.csv"
    }
    return `${config.API_URL}/public/${fileName}`
  }, [campaign])

  useEffect(() => {
    if (campaign && campaign.fileDetails) {
      const file = Object.assign(
        { ...campaign.fileDetails },
        {
          formattedSize: formatBytes(campaign.fileDetails.size),
          name: campaign.fileDetails.originalname,
        }
      )
      setSelectedFiles([file])
    }

    // Cleanup
    return () => {
      setNewFileSelected(false)
      setSelectedFiles([])
    }
  }, [campaign])

  const handleDropeedFile = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setSelectedFiles(files)
    setNewFileSelected(true)
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const onSubmit = async () => {
    try {
      if (newFileSelected) {
        const formData = new FormData()
        formData.append("csv", selectedFiles[0])
        formData.append("step", "file-upload")
        const result = await updateCampaign({
          id: campaign._id,
          payload: formData,
        }).unwrap()
        UpdateCampaignAPI.reset()
        dispatch(setCampaign(result.data))
      }
      setactiveTab("file-field-mapping")
    } catch (error) {
      showError(error)
      dispatch(setFileUploadProgress(0))
    }
  }

  const onBack = () => {
    setactiveTab("info")
  }

  return (
    <React.Fragment>
      <Form>
        <Row className="justify-content-center">
          <Col className="col-6">
            <Card>
              <CardBody>
                <div className="text-center">
                  <Row className="justify-content-center">
                    <Col lg="10">
                      <h4 className="mt-4 fw-semibold">Sample File</h4>
                      <p className="text-muted mt-3">
                        {campaign &&
                        campaign.type === "email-scraping" &&
                        campaign.subType === "url" ? (
                          <>
                            File must have <code>.csv</code> extension and
                            should contain an <b>url</b> column
                          </>
                        ) : (
                          <>
                            File must have <code>.csv</code> extension and
                            should contain an <b>email</b> column.
                          </>
                        )}
                      </p>

                      <div className="mt-4">
                        <a
                          href={SampleCSVPath}
                          rel="noreferrer"
                          target="_blank"
                          download={true}
                          className="btn btn-warning"
                        >
                          Download{" "}
                          <i className="bx bx-download align-baseline ms-1"></i>
                        </a>
                      </div>
                    </Col>
                  </Row>
                  {/* <Row className="justify-content-center mt-3 mb-1">
                    <Col sm={6} xs={10} md={10} xl={10} xxl={12}>
                      <div className="shadow p-1 bg-white rounded">
                        <img
                          src={SampleCSVImage}
                          alt=""
                          className="img-fluid img-thumbnail"
                        />
                      </div>
                    </Col>
                  </Row> */}
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col className="col-6">
            <Card>
              <CardBody>
                <div className="text-center">
                  <Dropzone
                    onError={onFileUploadError}
                    disabled={UpdateCampaignAPI.isLoading}
                    accept={{
                      "text/csv": [".csv"],
                    }}
                    maxFiles={1}
                    onDrop={files => {
                      handleDropeedFile(files)
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone">
                        <div
                          className="dz-message needsclick mt-2"
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <div className="mb-3">
                            <i className="display-4 text-muted bx bxs-cloud-upload" />
                          </div>
                          <h4>Drop files here or click to upload.</h4>
                        </div>
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {selectedFiles.map((f, i) => {
                            return (
                              <Card
                                className="mt-5 mb-1 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center justify-content-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={CsvIcon}
                                      />
                                    </Col>
                                    <Col className="col-auto">
                                      <p
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {f.name}
                                      </p>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                    {UpdateCampaignAPI.isLoading && (
                                      <Col className="col-8 text-center">
                                        <Progress
                                          value={uploadProgress}
                                          color="warning"
                                          className="progress-xl"
                                        >
                                          {uploadProgress} %
                                        </Progress>
                                      </Col>
                                    )}
                                  </Row>
                                </div>
                              </Card>
                            )
                          })}
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <div className="text-end">
              <Button
                className="btn btn-primary mx-3"
                color="primary"
                onClick={onBack}
                disabled={UpdateCampaignAPI.isLoading}
              >
                Previous
              </Button>
              <Button
                className="btn btn-primary save-user"
                color="primary"
                onClick={onSubmit}
                disabled={UpdateCampaignAPI.isLoading || !selectedFiles.length}
              >
                {!UpdateCampaignAPI.isLoading ? (
                  `${newFileSelected ? "Upload & Continue" : "Next"}`
                ) : (
                  <>
                    <span>
                      {uploadProgress === 100
                        ? " Building Field Map..."
                        : " Uploading file..."}
                    </span>
                    <Spinner size="sm"></Spinner>
                  </>
                )}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  )
}

export default SecondStep
