import { apiSlice } from "redux/api"
const API_URL_PREFIX = "url"
export const urldetaileApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    urlMailList: builder.query({
      query: (payload) => {
        const { state, id } = payload
        return ({
          url: `/${API_URL_PREFIX}/email/${id}`,
          method: "POST",
          body: { ...state }
        })
      },
    }),
  }),
})

export const { useUrlMailListQuery } = urldetaileApiSlice