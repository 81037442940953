import { apiSlice } from "../api"
const API_URL_PREFIX = "list"
export const maillWizzListSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    mailWizzListByCampaign: builder.mutation({
      query: campaignId => {
        return ({
          url: `/${API_URL_PREFIX}/${campaignId}`,
          method: "GET",
        })
      },
    }),

    mailWizzListCreate: builder.mutation({
      query: payload => ({
        url: `/${API_URL_PREFIX}/create`,
        method: "POST",
        body: { ...payload },
      }),
    }),

    mailWizzListUpdate: builder.mutation({
      query: ({ id, payload }) => {
        return {
          url: `/${API_URL_PREFIX}/${id}`,
          method: "PUT",
          body: { ...payload },
        }
      },
    }),

    // userGet: builder.mutation({
    //   query: id => ({
    //     url: `/${API_URL_PREFIX}/get/${id}`,
    //     method: "GET",
    //   }),
    // }),
  }),
})

export const {
  useMailWizzListByCampaignMutation,
  useMailWizzListUpdateMutation,
  useMailWizzListCreateMutation
} = maillWizzListSlice
