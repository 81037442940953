import { apiSlice } from "../api"

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    login: builder.mutation({
      query: credentials => ({
        url: "/login",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    refresh: builder.mutation({
      query: () => ({
        url: "/refresh",
        type: "GET",
      }),
      extraOptions: {
        customRefresh: true,
      },
    }),
    logout: builder.mutation({
      query: () => ({
        url: "/logout",
        method: "GET",
      }),
    }),
    forgotPassword: builder.mutation({
      query: payload => ({
        url: "/forgot-password",
        method: "POST",
        body: { ...payload },
      }),
    }),
    resetPassword: builder.mutation({
      query: payload => ({
        url: "/reset-password",
        method: "POST",
        body: { ...payload },
      }),
    }),

    checkTokenValidity: builder.mutation({
      query: payload => ({
        url: "/check-token-validity",
        method: "POST",
        body: { ...payload },
      }),
    }),
    switchBack: builder.mutation({
      query: () => ({
        url: "/switch-back",
        method: "GET",
      }),
    }),  
  }),
})
export const {
  useLoginMutation,
  useRefreshMutation,
  useLogoutMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useCheckTokenValidityMutation,
  useSwitchBackMutation,
} = authApiSlice
