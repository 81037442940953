import { createSlice } from "@reduxjs/toolkit"

const emailSlice = createSlice({
    name: 'emails',
    initialState: { list: [], totalRecord: 0 },
    reducers: {
        setEmailsList: (state, action) => {
            const { list, count } = action.payload;
            state.list = list
            state.totalRecord = count
        },
    },
})

export const { setEmailsList } = emailSlice.actions;
export default emailSlice.reducer;

export const selectEmailsList = (state) => state.emails.list;
export const selectEmailTotalRecords = (state) => state.emails.totalRecord;