import React, { useState } from "react"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useSelector, useDispatch } from "react-redux"
import { useBeforeunload } from "react-beforeunload"

import {
  Button,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Spinner,
} from "reactstrap"
import { selectUser, setUser } from "redux/users/slice"
import { showError, showToaster } from "helpers"
import { useUserUpdateMutation } from "redux/users/api"
import { PASSWORD_REGEX } from "constants/regex"

function PasswordChangeModal({ show, setShow }) {
  const dispatch = useDispatch()
  const user = useSelector(selectUser)

  const [passwordShow, setPasswordShow] = useState(false)
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false)

  useBeforeunload(() => "You may loss your data")
  const [update, UpdateAPI] = useUserUpdateMutation()

  // formik validation & initialization
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .trim()
        .matches(/^\S*$/, "Whitespace is not allowed")
        // .matches(
        //   PASSWORD_REGEX,
        //   "New Password must contain 8 to 20 alphanumeric characters including 1 uppercase, 1 lowercase, 1 digit and 1 special characters"
        // )
        .required("Please enter New Password")
        .min(8, "New Password should have minimum 8 characters")
        .max(20, "New Password should have maximum 20 characters"),
      confirmPassword: Yup.string()
        .required("Please enter Confirm Password")
        .matches(/^\S*$/, "Whitespace is not allowed")
        .oneOf(
          [Yup.ref("password"), null],
          "Confirm Password must be same as Password"
        ),
    }),
    onSubmit: async values => {
      try {
        const result = await update({
          id: user._id,
          payload: values,
        }).unwrap()
        showToaster("success", "Password Sucessfully Changed", "Success")
        setShow(false)
        dispatch(setUser(null))
        validation.resetForm()
      } catch (error) {
        showError(error)
      }
    },
  })

  const onCloseClick = () => {
    setShow(false)
    dispatch(setUser(null))
  }

  return (
    <React.Fragment>
      <Modal isOpen={show} toggle={onCloseClick} centered={true}  backdrop={"static"} >
        <ModalHeader toggle={onCloseClick} tag="h4">
          Change Password
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <Row>
              <Col className="col-12">
                {/* password */}
                <div className="mb-3">
                  <Label className="form-label">New Password*</Label>
                  <div className="input-group auth-pass-inputgroup">
                    <Input
                      name="password"
                      type={passwordShow ? "text" : "password"}
                      placeholder="Enter New Password"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.password || ""}
                      invalid={
                        validation.touched.password &&
                        validation.errors.password
                          ? true
                          : false
                      }
                      onPaste={e => {
                        e.preventDefault()
                        return false
                      }}
                      onCopy={e => {
                        e.preventDefault()
                        return false
                      }}
                    />
                    <button
                      onClick={() => setPasswordShow(!passwordShow)}
                      className="btn btn-light "
                      type="button"
                      id="password-addon"
                    >
                      <i
                        className={`mdi ${
                          !passwordShow
                            ? "mdi-eye-outline"
                            : "mdi-eye-off-outline"
                        }`}
                      ></i>
                    </button>
                    {validation.touched.password &&
                    validation.errors.password ? (
                      <FormFeedback type="invalid">
                        {validation.errors.password}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>

                {/* confirm password */}
                <div className="mb-3">
                  <Label className="form-label">Confirm Password*</Label>
                  <div className="input-group auth-pass-inputgroup">
                    <Input
                      name="confirmPassword"
                      type={confirmPasswordShow ? "text" : "password"}
                      placeholder="Enter Confirm Password"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.confirmPassword || ""}
                      invalid={
                        validation.touched.confirmPassword &&
                        validation.errors.confirmPassword
                          ? true
                          : false
                      }
                      onPaste={e => {
                        e.preventDefault()
                        return false
                      }}
                      onCopy={e => {
                        e.preventDefault()
                        return false
                      }}
                    />
                    <button
                      onClick={() =>
                        setConfirmPasswordShow(!confirmPasswordShow)
                      }
                      className="btn btn-light "
                      type="button"
                      id="password-addon"
                    >
                      <i
                        className={`mdi ${
                          !confirmPasswordShow
                            ? "mdi-eye-outline"
                            : "mdi-eye-off-outline"
                        }`}
                      ></i>
                    </button>
                    {validation.touched.confirmPassword &&
                    validation.errors.confirmPassword ? (
                      <FormFeedback type="invalid">
                        {validation.errors.confirmPassword}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <Button
                    type="submit"
                    className="btn btn-success save-user"
                    color="success"
                    disabled={UpdateAPI.isLoading}
                  >
                    {!UpdateAPI.isLoading ? (
                      "Change Password"
                    ) : (
                      <>
                        <span>Saving New Password...</span>
                        <Spinner size="sm"></Spinner>
                      </>
                    )}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default PasswordChangeModal
