import { createSlice, createSelector} from "@reduxjs/toolkit"

const blackListDomainSlice = createSlice({
    name: 'blackListDomains',
    initialState: { list: [], totalRecord: 0, uploadProgress : 0 },
    reducers: {
        setFileUploadProgress: (state, action) => {
            state.uploadProgress = action.payload;
        },
        setDomainList: (state, action) => {
            const { list, count } = action.payload;
            state.list = list
            state.totalRecord = count
        },
    },
})

export const { setFileUploadProgress, setDomainList } = blackListDomainSlice.actions;
export default blackListDomainSlice.reducer;

export const selectBlackListDomainFileUploadProgress = (state) => state.blackListDomains.uploadProgress;
export const selectBlackListDomainList = (state) => state.blackListDomains.list;
export const selectBlackListDomainTotalRecords = (state) => state.blackListDomains.totalRecord;