import React, { useEffect, useState } from "react"
import {
  Col,
  Row,
  Card,
  CardBody,
  Container,
  Spinner,
  CardTitle,
  CardImg,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Helmet } from "react-helmet"
import Swal from "sweetalert2"
import { Link, useParams, useLocation } from "react-router-dom"
import {
  useCampaignCheckVerificationStatusMutation,
  useCampaignGetMutation,
  useCampaignUpdateMutation,
} from "redux/campaigns/api"
import {
  showError,
  getColorByCampaignStatus,
  confirmAlert,
  showToaster,
} from "helpers"
import { selectCampaign, setCampaign } from "redux/campaigns/slice"
import { useDispatch, useSelector } from "react-redux"
import { formatDate, wordsTitleCase } from "helpers/data-formatters"
import Files from "./Files"
import CreateMailWizzList from "../Modals/AddListModal"
import { selectCurrentUser } from "redux/auth/slice"
import config from "../../../config"
import MailWizzLists from "./MailWizzLists"
import MiniCard from "./MiniCard"
import ScrapingGIF from "../../../assets/images/gif/scraping3.gif"
const CampaignStatus = config.campaign.status
const CampaignDetails = props => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const location = useLocation()
  const campaign = useSelector(selectCampaign)
  const currentUser = useSelector(selectCurrentUser)
  const [getCampaign, GetCampaignAPI] = useCampaignGetMutation()
  const [updateCampaign, UpdateCampaignAPI] = useCampaignUpdateMutation()
  const [checkVerificationStatus, CheckVericationStatusAPI] =
    useCampaignCheckVerificationStatusMutation()
  const [openListAddModal, setOpenListAddModal] = useState(false)
  const [reload, setReload] = useState(false)

  const isAvailableForSentToMailWizz =
    campaign &&
    [
      CampaignStatus.completed.value,
      CampaignStatus.readyForMailWizz.value,
      CampaignStatus.mailWizzProcessing.value,
    ].includes(campaign?.status)

  const isAvailableForPauseAndResume =
    campaign &&
    ![
      CampaignStatus.completed.value,
      CampaignStatus.draft.value,
      CampaignStatus.noValidEmailInFile.value,
      CampaignStatus.readyForProcessFile.value,
      CampaignStatus.fileProcessing.value,
      CampaignStatus.noValidURLInFile.value,
      CampaignStatus.inFinalizeEmailQueue.value,
      CampaignStatus.finalizingEmails.value,
    ].includes(campaign?.status)

  useEffect(() => {
    ;(async () => {
      try {
        const result = await getCampaign(id).unwrap()
        dispatch(setCampaign(result.data))
      } catch (error) {
        showError(error)
      }
    })()
  }, [id, reload])

  useEffect(() => {
    return () => {
      dispatch(setCampaign(null))
    }
  }, [])

  const onSend = () => {
    setOpenListAddModal(true)
  }

  const onPauseResume = async () => {
    try {
      const confirmationMessage = `Are you sure want to ${
        campaign.pause ? "resume" : "pause"
      } this campaign ?`
      const confirmation = await confirmAlert(confirmationMessage)
      if (confirmation.isConfirmed) {
        const payload = {
          pause: !campaign.pause,
        }
        await updateCampaign({
          id: campaign._id,
          payload,
        }).unwrap()
        setReload(!reload)
        showToaster(
          "success",
          `Campaign  Successfully ${campaign.pause ? "Resumed" : "Paused"}`
        )
      }
    } catch (error) {
      showError(error)
    }
  }

  const onStopScraping = async () => {
    try {
      const confirmationMessage = `Are you sure want to stop scraping ?`
      const confirmation = await confirmAlert(confirmationMessage)
      if (confirmation.isConfirmed) {
        const payload = {
          stopStatus: "in-progress",
        }
        await updateCampaign({
          id: campaign._id,
          payload,
        }).unwrap()
        setReload(!reload)
        showToaster("success", `Campaign will be stop in a moment`)
      }
    } catch (error) {
      showError(error)
    }
  }

  const onCheckVerification = async () => {
    try {
      const result = await checkVerificationStatus(campaign._id).unwrap()
      setReload(!reload)
      const type = result?.data?.isReadyAndDownloaded ? "success" : "info"
      const message = result?.data?.isReadyAndDownloaded
        ? "Campaign Successfully Verified"
        : "Campaign is not verified yet !!"
      showToaster(type, message)
    } catch (error) {
      showError(error)
    }
  }

  const onVerify = async () => {
    try {
      const confirmationMessage = `Are you sure want to verify campaign emails  ?`
      const confirmation = await Swal.fire({
        title: "Are you sure?",
        text: confirmationMessage,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, do it!",
        input: "checkbox",
        inputPlaceholder: "Validate Emails Using Internal Database ?",
      })
      if (confirmation.isConfirmed) {
        const payload = {
          status: CampaignStatus.readyForUploadToMailVerify.value,
          checkInEmailMaster: !!confirmation.value,
        }
        await updateCampaign({
          id: campaign._id,
          payload,
        }).unwrap()
        setReload(!reload)
        showToaster("success", `Campaign Successfully Sent For Verification`)
      }
    } catch (error) {
      showError(error)
    }
  }

  const BreadcrumbDetails = {
    breadcrumbItem: {
      label: campaign?.name.toString(),
      link: `campaigns/${campaign?._id.toString()}`,
    },
    paths: [
      {
        label: "Campaigns",
        link: `${
          location?.state?.from?.pathname == `/campaigns`
            ? "campaigns"
            : location?.state?.from?.pathname == "/archived-campaigns"
            ? "archived-campaigns"
            : "campaigns"
        }`,
      },
      {
        label: `${
          location?.state?.from?.pathname == `/campaigns`
            ? "List"
            : location?.state?.from?.pathname == "/archived-campaigns"
            ? "Archieved"
            : "List"
        }`,
        link: `${
          location?.state?.from?.pathname == `/campaigns`
            ? "campaigns"
            : location?.state?.from?.pathname == "/archived-campaigns"
            ? "archived-campaigns"
            : "campaigns"
        }`,
      },
    ],
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Campaign Details | Email-Hunter</title>
      </Helmet>

      <div className="page-content">
        <Container fluid>
          {/* Loader  */}
          {GetCampaignAPI.isLoading && (
            <div
              style={{
                height: "100%",
                width: "100%",
                textAlign: "center",
              }}
              className="text-center"
            >
              <Spinner
                type="grow"
                className="m-5"
                color="primary"
                style={{
                  height: "3rem",
                  width: "3rem",
                }}
              />
            </div>
          )}
          {/* Loader End  */}

          {/* Campaign Details Start */}
          {!GetCampaignAPI.isLoading && campaign && (
            <>
              <Breadcrumbs breadcrumbDetails={BreadcrumbDetails} />
              
              <Row>
                <Col xl={3} lg={4} className="bg-transparent">
                  {/* <Card className="bg-transparent">
                    <CardImg className="img-fluid bg-transparent" src={ScrapingGIF} alt="Skote" />
                  </Card> */}
                  <Card className="overflow-hidden">
                    <CardBody>
                      <div className="hstack gap-2">
                        <h5 className="fw-semibold">Campaign</h5>
                        <div className="text-end">
                          <i
                            className={`bx bx-rotate-right bx-border bx-tada-hover`}
                            onClick={() => setReload(!reload)}
                            title="Reload Campaign Details"
                            style={{ cursor: "pointer" }}
                          ></i>
                        </div>
                      </div>

                      {campaign.status === CampaignStatus.scraping.value &&
                      !campaign.pause &&
                      !campaign.isArchived ? (
                        <div className="text-center">
                          <CardImg
                            className="img-fluid bg-transparent p-4 h-50 w-75"
                            src={ScrapingGIF}
                            alt=""
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                      <div className="table-responsive">
                        <table className="table">
                          <tbody>
                            <tr>
                              <th scope="col">Name</th>
                              <td scope="col">
                                {wordsTitleCase(campaign.name)}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">Type</th>
                              <td>
                                <h6>
                                  {" "}
                                  <span className="badge badge-soft-success">
                                    {campaign.type}
                                  </span>
                                </h6>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">Status</th>
                              <td>
                                <span
                                  className={`badge badge-soft-${getColorByCampaignStatus(
                                    campaign.status
                                  )}`}
                                >
                                  {CampaignStatus[campaign.status].key}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">Created At</th>
                              <td>{formatDate(campaign.createdOn)}</td>
                            </tr>
                            <tr>
                              <th scope="row">Updated At</th>
                              <td>{formatDate(campaign.updatedOn)}</td>
                            </tr>
                            {campaign.lastVerificationCheckAt ? (
                              <tr>
                                <th scope="row">Last Verification</th>
                                <td>
                                  {formatDate(campaign.lastVerificationCheckAt)}
                                </td>
                              </tr>
                            ) : (
                              <></>
                            )}

                            {currentUser &&
                              currentUser.role ===
                                config.userRoles.root.key && (
                                <tr>
                                  <th scope="row">Created By</th>
                                  <td>
                                    {campaign.user.firstname}{" "}
                                    {campaign.user.lastname}
                                  </td>
                                </tr>
                              )}
                          </tbody>
                        </table>
                      </div>
                      {currentUser &&
                      currentUser.role === config.userRoles.admin.key ? (
                        <div className="hstack gap-2">
                          {isAvailableForSentToMailWizz ? (
                            <button
                              onClick={onSend}
                              className="btn btn-soft-primary w-100"
                              disabled={!isAvailableForSentToMailWizz}
                              title={
                                isAvailableForSentToMailWizz
                                  ? "Send to new mail wizz list"
                                  : `Campaign isn't ready yet for send to mailwizz `
                              }
                            >
                              Send
                            </button>
                          ) : (
                            <></>
                          )}

                          {CampaignStatus.mailVerifying.value ==
                            campaign.status &&
                          campaign?.emailVerify?.fileStatus != "completed" ? (
                            <button
                              onClick={onCheckVerification}
                              className="btn btn-soft-primary w-100"
                              title={"Check Email Verification Status"}
                              disabled={CheckVericationStatusAPI.isLoading}
                            >
                              {!CheckVericationStatusAPI.isLoading ? (
                                "Check Verification"
                              ) : (
                                <>
                                  <span>Checking...</span>
                                  <Spinner size="sm"></Spinner>
                                </>
                              )}
                            </button>
                          ) : (
                            <></>
                          )}
                          {isAvailableForPauseAndResume ? (
                            <button
                              className={`btn btn-soft-${
                                campaign.pause ? "warning" : "danger"
                              } w-100`}
                              onClick={onPauseResume}
                              disabled={
                                UpdateCampaignAPI.isLoading ||
                                campaign.stopStatus === "in-progress"
                              }
                            >
                              {campaign.pause ? "Resume" : "Pause"}
                            </button>
                          ) : (
                            <></>
                          )}

                          {campaign.status === CampaignStatus.scraping.value &&
                          !campaign.pause &&
                          campaign.stopStatus !== "completed" ? (
                            <>
                              <button
                                className={`btn btn-soft-dark w-100`}
                                onClick={onStopScraping}
                                disabled={
                                  UpdateCampaignAPI.isLoading ||
                                  campaign.stopStatus === "in-progress"
                                }
                              >
                                {campaign.stopStatus === "pending" && "Stop"}
                                {campaign.stopStatus === "in-progress" &&
                                  "Stopping"}
                              </button>
                            </>
                          ) : (
                            <></>
                          )}

                          {campaign.status === CampaignStatus.completed.value &&
                          !campaign.isVerified ? (
                            <>
                              <button
                                className={`btn btn-soft-dark w-100`}
                                onClick={onVerify}
                                disabled={UpdateCampaignAPI.isLoading}
                              >
                                Verify
                              </button>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </CardBody>
                  </Card>
                  <Files
                    files={campaign.files}
                    campaignStatus={campaign.status}
                    campaign={campaign._id}
                    mailCounts={campaign.mailCounts}
                    campaignName={campaign.name}
                    isVerified={campaign.isVerified}
                  />
                </Col>
                <Col xl={9} lg={8}>
                  <Row>
                    {campaign.type === "email-scraping" ? (
                      <>
                        <MiniCard
                          title="Total Domains"
                          iconClass="mdi mdi-web"
                          text={campaign?.url?.total}
                          color={"primary"}
                        />
                        <MiniCard
                          title="Valid Domains"
                          iconClass={
                            campaign.status ===
                              CampaignStatus.fileProcessing.value &&
                            campaign.type !== "email-validation"
                              ? "bx bx-loader-circle bx-spin"
                              : "bx-check-circle"
                          }
                          text={campaign?.url?.valid}
                          color={"info"}
                        />

                        {campaign.status === CampaignStatus.scraping.value ? (
                          <>
                            <MiniCard
                              title="Sub Urls"
                              iconClass="mdi mdi-web"
                              text={campaign?.url?.sub}
                              color={"dark"}
                            />

                            {campaign.stopStatus === "pending" ? (
                              <>
                                <MiniCard
                                  title="Pending Domains"
                                  iconClass="mdi mdi-web-clock"
                                  text={campaign?.url?.pending}
                                  color={"danger"}
                                />
                              </>
                            ) : (
                              <>
                                <MiniCard
                                  title="Aborted Domains"
                                  iconClass="mdi mdi-crosshairs-off"
                                  text={campaign?.url?.aborted}
                                  color={"danger"}
                                />
                              </>
                            )}

                            <MiniCard
                              title="Domains Being Scraped"
                              iconClass="mdi mdi-progress-clock"
                              text={campaign?.url?.inProgress}
                              color={"warning"}
                            />

                            <MiniCard
                              title="Scraped Domains"
                              iconClass="mdi mdi-progress-check"
                              text={campaign?.url?.complete}
                              color={"success"}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                        <MiniCard
                          title="Scraped Emails"
                          iconClass="mdi mdi-email-receive"
                          text={campaign?.email?.scraped}
                          color={"info"}
                        />
                      </>
                    ) : (
                      <></>
                    )}

                    <MiniCard
                      title="Total Emails"
                      iconClass="bx-hash"
                      text={campaign?.email?.total}
                    />
                    <MiniCard
                      title="Valid Emails"
                      iconClass={
                        campaign.status ===
                          CampaignStatus.fileProcessing.value &&
                        campaign.type === "email-validation"
                          ? "bx bx-loader-circle bx-spin"
                          : "bx-check-circle"
                      }
                      text={campaign?.email?.valid}
                      color={"info"}
                      hoverText={
                        campaign.status === CampaignStatus.fileProcessing.value
                          ? "Your file is being processed for Email Verification"
                          : "Valid Emails"
                      }
                    />
                    <MiniCard
                      title="MailWizz List"
                      iconClass="bx-mail-send"
                      text={campaign?.lists?.length}
                      color={"dark"}
                    />
                  </Row>
                  <MailWizzLists campaignId={campaign._id} />
                </Col>
              </Row>
            </>
          )}

          <CreateMailWizzList
            open={openListAddModal}
            setOpen={setOpenListAddModal}
            setReloadCampaign={setReload}
          />
          {/* Campaign Details End */}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CampaignDetails
