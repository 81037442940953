import { createSlice } from "@reduxjs/toolkit"

const urlSlice = createSlice({
    name: 'urls',
    initialState: { list: [], url:null, totalRecord: 0, },
    reducers: {
        setUrl: (state, action) => {
            state.url = action.payload;
        },
        setUrlsList: (state, action) => {
            const { list, count } = action.payload;
            state.list = list
            state.totalRecord = count
        },
    },
})

export const {setUrl, setUrlsList } = urlSlice.actions;
export default urlSlice.reducer;

export const selectUrlList = (state) => state.urls.list;
export const selectUrl = (state) => state.urls.url;
export const selectUrlTotalRecords = (state) => state.urls.totalRecord;