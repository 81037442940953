import { formatBigNumber, formatFileSize } from "helpers/data-formatters"
import React, { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import { Card, CardBody, CardTitle } from "reactstrap"
import config from "../../../config"
import { useGetFileMutation } from "redux/files/api"
const CampaignStatus = config.campaign.status
const Files = ({ campaign, files, campaignStatus, mailCounts, campaignName, isVerified }) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const CampaignFiles = config.campaign.files
  const showUnknownFile = [
    CampaignStatus.completed.value,
    CampaignStatus.mailVerified.value,
    CampaignStatus.inFinalizeEmailQueue.value,
    CampaignStatus.readyForMailWizz.value,
    CampaignStatus.finalizingEmails.value,
    CampaignStatus.mailWizzProcessing.value,
  ].includes(campaignStatus)

  const [getFile, GetFileAPI] = useGetFileMutation()
  const onDownloadFile = async fileName => {
    const cName = campaignName.toString().toLowerCase().split(" ").join("-");
    const data = await getFile({
      campaignId: campaign,
      fileName: `${fileName}`,
      campaignName: cName
    }).unwrap();
  }

  useEffect(() => {
    if (searchParams.get("download")) {
      onDownloadFile(searchParams.get("download"))
        .finally(() => {
          searchParams.delete("download")
          setSearchParams(searchParams)
        });
    }
  }, [searchParams])

  return (
    <Card className="mt-1">
      <CardBody>
        <CardTitle className="mb-4">Files</CardTitle>
        <div className="mt-4">
          {Object.values(CampaignFiles).map((file, i) => {
            const File = files.find(f => f.name == file.value);
            if (File && ["unknown", "ok", "fail", "all"].includes(File.name) && !isVerified) {
              return <></>
            }

            // do not show processed file before whole file is being processed
            if (
              File &&
              File.name == "processed" &&
              CampaignStatus.fileProcessing.value === campaignStatus
            ) {
              return <></>
            }

            return File ? (
              <Card
                className="border shadow-sm bg-light mb-2 mt-2"
                key={"_file_" + i}
                title={`Click Here To Download ${file.note}`}
              >
                <a
                  // href={`${CampaignDirPath}${File.file}`}
                  className="text-body mt-2"
                  rel="noreferrer"
                  onClick={e => {
                    e.preventDefault()
                    onDownloadFile(File.name)
                  }}
                >
                  <div className="p-2">
                    <div className="d-flex">
                      <div className="avatar-xs align-self-center me-4">
                        <div
                          className={`avatar-title rounded bg-transparent text-${file.color} font-size-20 mx-1`}
                        >
                          <i className="fas fa-2x fa-file-csv"></i>
                        </div>
                      </div>

                      <div className="overflow-hidden me-auto">
                        <h5 className="font-size-13 text-truncate mb-1">
                          {file.key}
                        </h5>
                        <p className="text-muted text-truncate mb-0">
                          {formatFileSize(File.size)}
                        </p>
                      </div>

                      <div className="ml-2">
                        <p className="text-muted">
                          {formatBigNumber(mailCounts[file.value])}
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </Card>
            ) : (
              <></>
            )
          })}
        </div>
      </CardBody>
    </Card>
  )
}

export default Files
