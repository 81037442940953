import config from "../config"
import React from "react"
import { Navigate } from "react-router-dom"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"

// Dashboard
import Dashboard from "../pages/Dashboard"
import Users from "../pages/Users/Users/Index"
import ArchivedUsers from "pages/Users/ArchivedUsers/Index"
import Campaigns from "../pages/Campaigns/Campaigns/Index"
import ArchievedCampaigns from "pages/Campaigns/ArchievedCampaigns/Index"
import Settings from "pages/Settings/Index"
import CampaignDetails from "pages/Campaigns/CampaignDetails"
import Emails from "../pages/Emails/Emails/Index"
import Urls from "../pages/Urls/Urls/Index";
import VerifyEmails from "../pages/VerifyEmails/Index"
import BlackListedDomains from "../pages/Settings/BlackListedDomains/Index"
import ForgotPassword from "pages/Authentication/ForgotPassword"
import ResetPassword from "pages/Authentication/ResetPassword"
import UrlsDetails from "pages/Urls/UrlsDetails/Index"
import ExportRequest from "pages/ExportRequest/Index"

const authProtectedRoutes = [
  {
    path: "/dashboard",
    component: <Dashboard />,
    userRole: config.userRoles.admin.key,
  },
  {
    path: "/users", component: <Users />,
    userRole: config.userRoles.root.key,
  },
  {
    path: "/archived-users",
    component: <ArchivedUsers />,
    userRole: config.userRoles.root.key,
  },
  {
    path: "/settings",
    component: <Settings />,
    userRole: config.userRoles.admin.key,
  },
  {
    path: "/black-listed-domains",
    component: <BlackListedDomains />,
    userRole: config.userRoles.root.key,
  },

  //campaign related routes
  {
    path: "/campaigns",
    component: <Campaigns />,
    userRole: config.userRoles.admin.key,
  },
  {
    path: "/archived-campaigns",
    component: <ArchievedCampaigns />,
    userRole: config.userRoles.admin.key,
  },
  {
    path: "/campaigns/:id",
    component: <CampaignDetails />,
    userRole: config.userRoles.admin.key,
  },

  {
    path: "/emails",
    component: <Emails />,
    userRole: config.userRoles.admin.key,
  },
  // urls related routes
  {
    path: "/urls",
    component: <Urls />,
    userRole: config.userRoles.admin.key,
  },
  {
    path: "/urls/:id",
    component: <UrlsDetails />,
    userRole: config.userRoles.admin.key,
  },
  {
    path: "/exportrequest",
    component: <ExportRequest />,
    userRole: config.userRoles.admin.key,
  },
  {
    path: "/verify-emails",
    component: <VerifyEmails />,
    userRole: config.userRoles.admin.key,
  },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    userRole: config.userRoles.admin.key,
    component: <Navigate to="/dashboard" />,
  },
]

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgotPassword /> },
  { path: "/reset-password/:userId/:token", component: <ResetPassword />}
]

export { authProtectedRoutes, publicRoutes }
