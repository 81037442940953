import React, { useEffect, useMemo, useState } from "react"
import { isEmpty } from "lodash"
import * as Yup from "yup"
import { useFormik, getIn } from "formik"
import { useSelector, useDispatch } from "react-redux"
import {
  Button,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  Form,
  Input,
  FormFeedback,
  Label,
  Spinner,
  Alert,
} from "reactstrap"
import { showError, showToaster } from "helpers"
import Switch from "react-switch"
import { selectCurrentUser } from "redux/auth/slice"
import { useCampaignUpdateMutation } from "redux/campaigns/api"
import { selectCampaign, setCampaign } from "redux/campaigns/slice"
import { Link } from "react-router-dom"
import Select from "react-select"

function FourthStep({ closeModal, setactiveTab }) {
  const dispatch = useDispatch()
  const user = useSelector(selectCurrentUser)
  const [wantToSentMailWiz, setWantToSentMailWiz] = useState(false)
  const campaign = useSelector(selectCampaign)
  const [emailStatus, setEmailStatus] = useState([{ value: "ok", label: "Ok" }])

  const mailWizz = user?.settings?.mailWizz
  const [updateCampaign, UpdateCampaignAPI] = useCampaignUpdateMutation()
  const initialValue = {
    list: {
      name: ``,
      fromName: mailWizz?.fromName || "",
      fromEmail: mailWizz?.fromEmail || "",
      replyTo: mailWizz?.replyTo || "",
      description: ``,
      subject: ``,
    },
  }

  const validationSchema = {
    list: Yup.object({
      name: wantToSentMailWiz
        ? Yup.string()
            .trim()
            .required("Please enter List Name")
            .min(3, "List name should have minimum 3 characters")
            .max(20, "List name should have maximum 20 characters")
        : Yup.string(),
      fromName: wantToSentMailWiz
        ? Yup.string()
            .trim()
            .required("Please enter From Name")
            .min(3, "From name should have minimum 3 characters")
            .max(20, "From name should have maximum 20 characters")
        : Yup.string(),
      fromEmail: wantToSentMailWiz
        ? Yup.string()
            .trim()
            .required("Please enter From Email")
            .matches(/^\S*$/, "Whitespace is not allowed")
            .email("Enter valid email address")
        : Yup.string(),

      replyTo: wantToSentMailWiz
        ? Yup.string()
            .trim()
            .required("Please enter Reply To Email")
            .matches(/^\S*$/, "Whitespace is not allowed")
            .email("Enter valid email address")
        : Yup.string(),

      description: wantToSentMailWiz
        ? Yup.string()
            .trim()
            .required("Please enter Description")
            .min(3, "Description should have minimum 3 characters")
            .max(30, "Description name should have maximum 30 characters")
        : Yup.string(),

      subject: wantToSentMailWiz
        ? Yup.string()
            .trim()
            .required("Please enter Subject")
            .min(3, "Subject should have minimum 3 characters")
            .max(20, "Subject name should have maximum 20 characters")
        : Yup.string(),
    }),
  }

  // formik validation & initialization
  const formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: initialValue,
    validationSchema: Yup.object(validationSchema),
    onSubmit: async values => {
      if (wantToSentMailWiz && !emailStatus?.length) {
        return showToaster("error", "Please select Email Status", "Required")
      }

      values = {
        list: {
          ...values.list,
          emailStatus: emailStatus || [].map(status => status.value),
        },
      }

      const payload = {
        ...values,
        sendToMailWizz: wantToSentMailWiz,
        step: "mailwizz",
      }
      try {
        const result = await updateCampaign({
          id: campaign._id,
          payload,
        }).unwrap()
        dispatch(setCampaign(null))
        closeModal()
        showToaster("success", "Campaign Successfully Created", "Success")
        setactiveTab("info")
      } catch (error) {
        showError(error)
      }
    },
  })

  const onBack = () => {
    setactiveTab("file-field-mapping")
  }

  const onEmailStatusSelect = option => {
    setEmailStatus(option)
  }

  return (
    <React.Fragment>
      <Form
        onSubmit={e => {
          e.preventDefault()
          formik.handleSubmit()
          return false
        }}
      >
        <Row className="text-center mt-3">
          <Col className="col-12">
            <div>
              <Label className="form-label">
                Do you want to send to <b>MailWizZ</b> ?
              </Label>
            </div>
            <Switch
              className="me-1 mb-sm-8 mb-2"
              onColor="#626ed4"
              onChange={() => {
                setWantToSentMailWiz(!wantToSentMailWiz)
              }}
              checked={wantToSentMailWiz}
              name="sendToMailWiz"
            />
          </Col>
        </Row>

        {wantToSentMailWiz && (
          <Row className="justify-content-center mt-3">
            {!mailWizz && (
              <Col className="col-8">
                <Alert color="danger" className="text-center">
                  Oops! You are unable to create campaign with{" "}
                  <a
                    href="https://forum.mailwizz.com/tags/login/"
                    rel="noreferrer"
                    target={"_blank"}
                  >
                    <b>MailWizZ</b>
                  </a>{" "}
                  until you configure your mailWizz account.
                  <br />
                  <Link to="/settings" className="alert-link text-primary">
                    click here
                  </Link>{" "}
                  to configure mailWizz account.
                </Alert>
              </Col>
            )}

            {mailWizz && (
              <Col className="col-12">
                <Card className="my-2" color={"light"}>
                  <CardBody>
                    <CardTitle className="mb-3">
                      <i className="bx bx-mail-send bx-spin"></i> MailWizz List
                    </CardTitle>

                    <Row>
                      <Col className="col-6">
                        {/* campaign name  */}
                        <div className="mb-3">
                          <Label className="form-label">List Name*</Label>
                          <Input
                            name="list.name"
                            type="text"
                            placeholder="Enter List Name Here"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={getIn(formik.values, "list.name") || ""}
                            invalid={
                              getIn(formik.touched, "list.name") &&
                              getIn(formik.errors, "list.name")
                                ? true
                                : false
                            }
                          />
                          {getIn(formik.touched, "list.name") &&
                          getIn(formik.errors, "list.name") ? (
                            <FormFeedback type="invalid">
                              {getIn(formik.errors, "list.name")}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col className="col-6">
                        {/* campaign name  */}
                        <div className="mb-3">
                          <Label className="form-label">Description*</Label>
                          <Input
                            name="list.description"
                            type="text"
                            placeholder="Enter List Description Here"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={
                              getIn(formik.values, "list.description") || ""
                            }
                            invalid={
                              getIn(formik.touched, "list.description") &&
                              getIn(formik.errors, "list.description")
                                ? true
                                : false
                            }
                          />
                          {getIn(formik.touched, "list.description") &&
                          getIn(formik.errors, "list.description") ? (
                            <FormFeedback type="invalid">
                              {getIn(formik.errors, "list.description")}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="col-6">
                        {/* campaign name  */}
                        <div className="mb-3">
                          <Label className="form-label">From Email*</Label>
                          <Input
                            name="list.fromEmail"
                            type="text"
                            placeholder="Enter From Email here"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={getIn(formik.values, "list.fromEmail") || ""}
                            invalid={
                              getIn(formik.touched, "list.fromEmail") &&
                              getIn(formik.errors, "list.fromEmail")
                                ? true
                                : false
                            }
                          />
                          {getIn(formik.touched, "list.fromEmail") &&
                          getIn(formik.errors, "list.fromEmail") ? (
                            <FormFeedback type="invalid">
                              {getIn(formik.errors, "list.fromEmail")}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col className="col-6">
                        {/* campaign name  */}
                        <div className="mb-3">
                          <Label className="form-label">From Name*</Label>
                          <Input
                            name="list.fromName"
                            type="text"
                            placeholder="Enter From Name here"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={getIn(formik.values, "list.fromName") || ""}
                            invalid={
                              getIn(formik.touched, "list.fromName") &&
                              getIn(formik.errors, "list.fromName")
                                ? true
                                : false
                            }
                          />
                          {getIn(formik.touched, "list.fromName") &&
                          getIn(formik.errors, "list.fromName") ? (
                            <FormFeedback type="invalid">
                              {getIn(formik.errors, "list.fromName")}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="col-6">
                        {/* campaign name  */}
                        <div className="mb-3">
                          <Label className="form-label">Subject*</Label>
                          <Input
                            name="list.subject"
                            type="text"
                            placeholder="Enter Subject Here"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={getIn(formik.values, "list.subject") || ""}
                            invalid={
                              getIn(formik.touched, "list.subject") &&
                              getIn(formik.errors, "list.subject")
                                ? true
                                : false
                            }
                          />
                          {getIn(formik.touched, "list.subject") &&
                          getIn(formik.errors, "list.subject") ? (
                            <FormFeedback type="invalid">
                              {getIn(formik.errors, "list.subject")}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col className="col-6">
                        {/* campaign name  */}
                        <div className="mb-3">
                          <Label className="form-label">Reply To Email*</Label>
                          <Input
                            name="list.replyTo"
                            type="text"
                            placeholder="Enter Reply To Email Here"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={getIn(formik.values, "list.replyTo") || ""}
                            invalid={
                              getIn(formik.touched, "list.replyTo") &&
                              getIn(formik.errors, "list.replyTo")
                                ? true
                                : false
                            }
                          />
                          {getIn(formik.touched, "list.replyTo") &&
                          getIn(formik.errors, "list.replyTo") ? (
                            <FormFeedback type="invalid">
                              {getIn(formik.errors, "list.replyTo")}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <div className="mb-3">
                          <label className="control-label">Email Status</label>
                          <Select
                            value={emailStatus}
                            styles={{
                              control: (baseStyles, state) => {
                                return {
                                  ...baseStyles,
                                  borderColor: state.hasValue ? "grey" : "red",
                                }
                              },
                            }}
                            isMulti={true}
                            onChange={e => {
                              onEmailStatusSelect(e)
                            }}
                            options={[
                              { value: "ok", label: "Ok" },
                              { value: "unknown", label: "Unknown" },
                              { value: "fail", label: "Fail" },
                              { value: "pending", label: "Pending" },
                            ]}
                            className="select2-selection"
                          />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        )}

        <Row>
          <Col>
            <div className="text-end">
              <Button
                className="btn btn-primary mx-3"
                color="primary"
                onClick={onBack}
                disabled={UpdateCampaignAPI.isLoading}
              >
                Previous
              </Button>
              <Button
                type="submit"
                className="btn btn-primary"
                color="primary"
                disabled={UpdateCampaignAPI.isLoading}
              >
                {!UpdateCampaignAPI.isLoading ? (
                  "Finish"
                ) : (
                  <>
                    <span>
                      {!wantToSentMailWiz
                        ? "Finishing Campaign..."
                        : "Creating List & Finishing Campaign..."}
                    </span>
                    <Spinner size="sm"></Spinner>
                  </>
                )}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  )
}

export default FourthStep
