import moment from "moment"
import momentDurationFormat from "moment-duration-format"
momentDurationFormat(moment)
import prettyBytes from "pretty-bytes"
const wordTitleCase = (value, valueSetOnEmpty = "") => {
  if (!value) {
    return valueSetOnEmpty
  }
  return value?.charAt(0).toUpperCase() + value.toLowerCase().slice(1)
}

const wordsTitleCase = (value, valueSetOnEmpty = "") => {
  if (!value) {
    return valueSetOnEmpty
  }
  return value
    .toLowerCase()
    .split(" ")
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1)
    })
    .join(" ")
}

const upperCase = (value, valueSetOnEmpty = "") => {
  if (!value) {
    return valueSetOnEmpty
  }
  return value.toString().toUpperCase()
}

const lowerCase = (value, valueSetOnEmpty = "") => {
  if (!value) {
    return valueSetOnEmpty
  }
  return value.toString().toLowerCase()
}

const formatDate = (date, format = "Do MMM YY, h:mm a") => {
  const momentDate = moment(date)
  const todayDate = moment().startOf("day")
  if (momentDate.isSame(todayDate)) {
    return momentDate.fromNow()
  }
  return moment(date).format(format)
}

const formatFileSize = (
  sizeInBytes = 0,
  options = { bits: false, maximumFractionDigits: 2 }
) => {
  return prettyBytes(sizeInBytes, options)
}

const formatBigNumber = (number = 0, full = false) => {
  if (full) return new Intl.NumberFormat().format(number)
  return new Intl.NumberFormat("en-US", {
    maximumSignificantDigits: 3,
    notation: "compact",
  }).format(number)
}

const formatDuration = (data, unit = "seconds", format = "m [m], s [s]") => {
  return moment.duration(data, unit).format(format)
}

export {
  wordTitleCase,
  wordsTitleCase,
  upperCase,
  lowerCase,
  formatDate,
  formatFileSize,
  formatBigNumber,
  formatDuration
}
