import { apiSlice } from "../api"
const API_URL_PREFIX = "email"
export const emailsApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    emailList: builder.mutation({
      query: payload => ({
        url: `/${API_URL_PREFIX}/list`,
        method: "POST",
        body: { ...payload },
      }),
    }),
    verifySingleEmail: builder.mutation({
      query: payload => ({
        url: `/${API_URL_PREFIX}/verify/single`,
        method: "POST",
        body: { ...payload },
      }),
    }),
  }),
})

export const { useEmailListMutation, useVerifySingleEmailMutation } = emailsApiSlice
