import { formatBigNumber } from "helpers/data-formatters"
import PropTypes from "prop-types"
import React from "react"
import { Card, CardBody, Col, UncontrolledTooltip } from "reactstrap"
const MiniCard = props => {
  const { title, text, iconClass, color, hoverText } = props
  return (
    <React.Fragment>
      <Col md="4" xl="4">
        <Card className="mini-stats-wid">
          <CardBody>
            <div className="d-flex">
              <div className="flex-grow-1">
                <p className="text-muted fw-medium mb-2">{title}</p>
                <h4 className="mb-0 pe-2 d-inline" id={`ID${title.replace(/[^a-zA-Z]/g, '')}`}>
                  {formatBigNumber(text)}
                </h4>
                <UncontrolledTooltip
                  placement="right"
                  target={`ID${title.replace(/[^a-zA-Z]/g, '')}`}
                >
                {formatBigNumber(text,true)}
                </UncontrolledTooltip>
              </div>
              <div
                className={`mini-stat-icon avatar-sm align-self-center rounded-circle bg-primary`}
                title={hoverText ? hoverText : ""}
              >
                <span className={`avatar-title bg-${color || "primary"}`}>
                  <i className={"bx " + iconClass + " font-size-24"} />
                </span>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

MiniCard.propTypes = {
  iconClass: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
}

export default MiniCard
