import React, { useEffect, useState } from 'react'
import {
  Col,
  Card,
  CardBody,
  Table,
} from "reactstrap"
import { useDispatch, useSelector } from 'react-redux';
import { useFilesRecordMutation } from 'redux/dashboard/api';
import { selectFileRecord, setFileRecord } from 'redux/dashboard/slice';
import { formatBigNumber, formatFileSize, wordTitleCase } from 'helpers/data-formatters';
import { showError } from 'helpers';

const File = () => {
  const dispatch = useDispatch();
  const [getFilesRecord] = useFilesRecordMutation()
  const filesRecord = useSelector(selectFileRecord)
  useEffect(() => {
    const getData = async () => {
      try {
        const result = await getFilesRecord().unwrap()
        dispatch(setFileRecord(result.data))
      } catch (error) {
        showError(error)
      }
    }
    getData()
  }, [])
  return (
    <React.Fragment >
      <Col xl="12">
        <Card >
          <CardBody>
            <h4 className="card-title mb-4">Files</h4>
            <div className="table-responsive">
              <Table className="table align-middle table-nowrap m-0">
                <thead>
                  <tr >
                    <th>
                      <div>
                        <h5 className="font-size-14 mb-1 text-black">Name
                        </h5>
                      </div>
                    </th>
                    <th>
                      <div className="text-end">
                        <h5 className="font-size-14 mb-0 text-black"># of Files</h5>
                      </div>
                    </th>
                    <th>
                      <div className="text-end">
                        <h5 className="font-size-14 mb-0 text-black me-2">
                          Size
                        </h5>
                      </div>
                    </th>
                  </tr>
                </thead>
              </Table>
              <div className="table-responsive" style={{ height: "365px", overflow: "auto" }} >
                <Table className="table align-middle table-nowrap m-0" >
                  <tbody>
                    {filesRecord?.map((file, index) => {
                      return (<tr key={index} className='m-5'>
                        <td>
                          <div>
                            <p className="font-size-14 mb-1">{wordTitleCase(file?.file)
                            }</p>
                          </div>
                        </td>
                        <td>
                          <div className="text-end">
                            <p className="font-size-14 mb-0">{formatBigNumber(file?.count)}</p>
                          </div>
                        </td>
                        <td>
                          <div className="text-end">
                            <p className="font-size-14 text-muted mb-0">
                              {formatFileSize(file.size)}
                            </p>
                          </div>
                        </td>
                      </tr>)
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default File