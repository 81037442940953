import React, { useState } from "react"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Helmet } from "react-helmet"
import {
  Row,
  Container,
  CardBody,
  Card,
  Col,
  CardTitle,
  Button,
  CardImg,
  CardSubtitle,
  CardText,
} from "reactstrap"
import TagInput from "../../components/Common/TagInput"
import { EMAIL_REGEX_2 } from "constants/regex"
import { useVerifySingleEmailMutation } from "redux/emails/api"
import { v4 as uuidv4 } from "uuid"
import config from "../../config"
import { showToaster } from "helpers"
import { useBeforeunload } from "react-beforeunload"
import emailVerifyVectorImage from "../../assets/images/email-verify.jpg"
import "../../assets/scss/custom.scss"
const requestLimit = config.manualEmailVerify.requestLimit || 20
const KeyCodes = {
  comma: 188,
  enter: 13,
  newLine: 10,
}

const status = {
  pending: {
    color: "primary",
    icon: "mdi mdi-timelapse",
    text: "pending",
  },
  cancelled: {
    color: "danger",
    icon: "mdi mdi-close-octagon-outline",
    text: "cancelled / error",
  },
  inProgress: {
    color: "info",
    icon: "bx bx-loader-circle bx-spin",
    text: "verifying",
  },
  ok: {
    color: "success",
    icon: "bx bxs-badge-check",
    text: "valid",
  },
  fail: {
    color: "secondary",
    icon: "bx bx-tired",
    text: "failed",
  },
  unknown: {
    color: "warning",
    icon: "bx bxs-help-circle",
    text: "unknown",
  },
}

const delimiters = [KeyCodes.comma, KeyCodes.enter, KeyCodes.newLine]
const VerifyEmails = props => {
  const [requests, setRequests] = useState({})
  useBeforeunload(() => "You may loss your data")
  const [emails, setEmails] = useState([])
  const [loading, setLoading] = useState(false)
  const [discardedEmails, setDiscardedEmails] = useState([])
  const [verifySingleEmail, API] = useVerifySingleEmailMutation()
  const [wantToSearchInMasterEmail, setWantToSearchInMasterEmail] =
    useState(false)

  const BreadcrumbDetails = {
    breadcrumbItem: {
      label: "Verify Emails",
      link: "verify-emails",
    },
    paths: [],
  }

  const onRemoveEmail = idx => {
    setEmails(emails.filter((email, index) => index !== idx))
  }

  const setEmailStatus = (id, status) => {
    setEmails(emails => {
      return emails.map(item => {
        if (item.id === id) {
          return {
            ...item,
            status,
          }
        }
        return item
      })
    })
  }

  const handleAddition = tag => {
    const text = tag.text.trim().replaceAll(/(\n||\n)/g, "")
    tag.text = text.replaceAll(/["']/g, "")
    if (!EMAIL_REGEX_2.test(tag.text)) {
      if (discardedEmails.findIndex(value => value.text === tag.text) >= 0) {
        console.log("Discarded Email Already Exists")
        return
      }

      return setDiscardedEmails(previousDiscardedEmails => {
        return [...previousDiscardedEmails, tag]
      })
    }

    // check email is already exists or not, if exists skip that email
    if (emails.findIndex(value => value.text === tag.text) >= 0) {
      console.log("Email Already Exists")
      return
    }

    setEmails(previousEmails => {
      if (previousEmails.length === requestLimit) {
        showToaster("warning", `Max ${requestLimit} Emails Allowed At Time`)
        return previousEmails
      }
      const id = uuidv4()
      return [...previousEmails, { ...tag, status: "pending", id: id }]
    })
  }

  const handleAPISuccess = (response, id) => {
    const result = response?.data
    if (result && result?.status === 200) {
      const { email, status } = result.data
      setEmailStatus(id, status)
    } else {
      setEmailStatus(id, "cancelled")
    }
    setRequests(prevState => {
      const state = { ...prevState }
      state[id] = undefined
      return state
    })
  }

  const handleAPIError = id => {
    setEmailStatus(id, "cancelled")
    setRequests(prevState => {
      const state = { ...prevState }
      state[id] = undefined
      return state
    })
  }

  const onSubmit = async () => {
    const tasks = []
    const pendingEmails = emails.filter(email =>
      ["pending", "cancelled"].includes(email.status)
    )

    if (pendingEmails.length) {
      for (const email of pendingEmails) {
        const request = verifySingleEmail({
          email: email.text,
          checkInEmailMaster: wantToSearchInMasterEmail,
        })
        setRequests(previousRequests => {
          return { ...previousRequests, [email.id]: request }
        })
        tasks.push(
          request
            .then(response => handleAPISuccess(response, email.id))
            .catch(() => {
              handleAPIError(email.id)
            })
        )
        setEmailStatus(email.id, "inProgress")
      }
      setLoading(true)
      await Promise.allSettled(tasks)
      setLoading(false)
    }
  }

  const onCancel = () => {
    if (Object.keys(requests).length) {
      for (const id in requests) {
        if (requests[id]) {
          requests[id]?.abort()
          setEmailStatus(id, "cancelled")
        }
      }
    }
  }

  const oncopy = async email => {
    const text = email.text
    await navigator.clipboard.writeText(text)
    showToaster("info", `${text}, copied to clipboard !!`)
  }

  const onReset = () => {
    setEmails([])
    setDiscardedEmails([])
    setRequests({})
    setLoading(false)
    setWantToSearchInMasterEmail(false)
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Verify Emails | Email-Hunter</title>
      </Helmet>

      <div className="verify-content">
        <Container fluid className="p-0">
          {/* Render Breadcrumb */}
          <Breadcrumbs breadcrumbDetails={BreadcrumbDetails} />

          <Row>
            <Col className="col-xl-9 col-12">
              <div className="email-wrapper">
                <div className="d-flex h-100 flex-column">
                  <div className="row justify-content-center h-100">
                    <div className="col-lg-9 align-self-center">
                      <div className="text-center">
                        <h4 className="mb-5">
                          <i className="bx bxs-quote-alt-left text-primary h1 align-middle me-3"></i>
                          <span className="text-primary">Verify</span> Email
                        </h4>
                        <TagInput
                          handleAddition={handleAddition}
                          delimiters={delimiters}
                          placeholder={
                            "Write Or Paste Comma Separated Emails Here"
                          }
                          allowUnique={true}
                        />

                        <Row className="mt-3 mb-5">
                          <div className="col-md">
                            <div className="form-check form-switch form-switch-lg mb-3 ms-2">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="wantToSearchInMasterEmail"
                                name="wantToSearchInMasterEmail"
                                disabled={loading}
                                onChange={() => {
                                  setWantToSearchInMasterEmail(
                                    !wantToSearchInMasterEmail
                                  )
                                }}
                                checked={wantToSearchInMasterEmail}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="wantToSearchInMasterEmail"
                              >
                                Validate From Internal Database?
                              </label>
                            </div>
                          </div>
                          <div className="col-md-auto">
                            {loading ? (
                              <Button
                                className="btn btn-danger me-1"
                                onClick={onCancel}
                                disabled={!loading}
                              >
                                Cancel
                              </Button>
                            ) : (
                              <></>
                            )}
                            {!loading ? (
                              <Button
                                color={"primary"}
                                className="btn me-1"
                                onClick={onReset}
                                disabled={loading}
                              >
                                Reset
                              </Button>
                            ) : (
                              <></>
                            )}
                            <Button
                              className="btn me-2"
                              color={"success"}
                              onClick={onSubmit}
                              disabled={
                                loading ||
                                Boolean(
                                  !emails.filter(email => {
                                    return ["pending", "cancelled"].includes(
                                      email.status
                                    )
                                  }).length
                                )
                              }
                            >
                              Verify
                            </Button>
                          </div>
                        </Row>

                        {emails.length ? (
                          <div className="d-flex flex-wrap gap-2 justify-content-center mb-4">
                            {emails.map((email, idx) => {
                              return (
                                <button
                                  type="button"
                                  title="Copy Email"
                                  onClick={e => {
                                    oncopy(email)
                                  }}
                                  className={`btn btn-${
                                    status[email.status].color
                                  } btn-label`}
                                  key={idx}
                                >
                                  <span className="label-icon">
                                    <i
                                      className={`${
                                        status[email.status].icon
                                      }  pe-none`}
                                    ></i>
                                  </span>
                                  {email.text}
                                  {email.status === "pending" ? (
                                    <i
                                      title="Remove Email"
                                      className="mdi mdi-close-octagon-outline ms-2 pe-auto"
                                      onClick={e => {
                                        e.stopPropagation();
                                        onRemoveEmail(idx)
                                      }}
                                    ></i>
                                  ) : (
                                    <></>
                                  )}
                                </button>
                              )
                            })}
                          </div>
                        ) : (
                          <></>
                        )}

                        {discardedEmails.length ? (
                          <div className="w-100">
                            <div className="py-3 invalid-wrapper">
                              <CardTitle>Invalid Emails</CardTitle>
                              <div className="d-flex flex-wrap gap-2 justify-content-center">
                                {discardedEmails.map((email, idx) => {
                                  return (
                                    <button
                                      type="button"
                                      className="btn btn-danger pe-none"
                                      key={idx}
                                    >
                                      {email.text}
                                    </button>
                                  )
                                })}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col className="col-xl-3 col-md-12">
              <div>
                <CardImg
                  className="img-fluid mb-3"
                  src={emailVerifyVectorImage}
                  alt="Skote"
                />
                <CardBody className="px-4">
                  <CardTitle className="text-center mb-4">
                    How it works ?{" "}
                  </CardTitle>
                  <CardText>
                    <div className="d-flex mb-3">
                      <i
                        className={
                          "fa fa-caret-right font-size-16 align-middle text-primary me-2"
                        }
                      />
                      We consider top {requestLimit} emails, rest will be
                      ignored.
                    </div>
                    <div className="d-flex mb-2">
                      <i
                        className={
                          "fa fa-caret-right font-size-16 align-middle text-primary me-2"
                        }
                      />
                      All other text will be consider as discarded emails.
                    </div>
                    <div className="d-flex mb-2">
                      <i
                        className={
                          "fa fa-caret-right font-size-16 align-middle text-primary me-2"
                        }
                      />
                      Write or paste comma separated emails.
                    </div>
                    <div className="d-flex mb-2">
                      <i
                        className={
                          "fa fa-caret-right font-size-16 align-middle text-primary me-2"
                        }
                      />
                      Click on button "Verify" for verifying the emails.
                    </div>
                    <div className="d-flex mb-2">
                      <i
                        className={
                          "fa fa-caret-right font-size-16 align-middle text-primary me-2"
                        }
                      />
                      After verification completes you can clean page with
                      "Reset" button.
                    </div>
                    <div className="d-flex mb-2">
                      <i
                        className={
                          "fa fa-caret-right font-size-16 align-middle text-primary me-2"
                        }
                      />
                      You can cancel verification in between with "Cancel"
                      button.
                    </div>
                    <div className="d-flex mb-2">
                      <i
                        className={
                          "fa fa-caret-right font-size-16 align-middle text-primary me-2"
                        }
                      />
                      You can copy an email to clipboard by clicking on it.
                    </div>
                  </CardText>
                  <CardText>
                    <div className="d-flex flex-wrap gap-2 justify-content-center mb-4 mt-5">
                      {Object.values(status).map((item, idx) => {
                        return (
                          <button
                            type="button"
                            className={`btn btn-${item.color} btn-label`}
                            key={idx}
                          >
                            <span className="label-icon">
                              <i className={`${item.icon} pe-none`}></i>
                            </span>
                            {item.text}
                          </button>
                        )
                      })}
                    </div>
                  </CardText>
                </CardBody>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default VerifyEmails
