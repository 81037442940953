import React, { useCallback, useState } from "react"
import { Col, Row, Card, CardBody, Container, Button } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Helmet } from "react-helmet"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import AddEditModal from "../Modals/AddEdit/Index"
import { showError } from "helpers"
import { useDispatch, useSelector } from "react-redux"
import List from "./List"
import { useCampaignGetMutation } from "redux/campaigns/api"
import { setCampaign } from "redux/campaigns/slice"
import ArchiveModal from "../Modals/ArchiveModal"
import CreateMailWizzList from "../Modals/AddListModal"
import { selectCurrentUser } from "redux/auth/slice"

const Campaigns = props => {
  const dispatch = useDispatch()
  const [isEdit, setIsEdit] = useState(false)
  const [loadList, setLoadList] = useState(true)
  const [openAddCampaignModal, setOpenAddCampaignModal] = useState(false)
  const [openArchiveModal, setOpenArchiveModal] = useState(false)
  const [openListAddModal, setOpenListAddModal] = useState(false)
  const [getCampaign, GetCampaignApi] = useCampaignGetMutation()
  const currentLoggedInUser = useSelector(selectCurrentUser)

  const onEditCampaign = async id => {
    try {
      const result = await getCampaign(id).unwrap()
      dispatch(setCampaign(result.data))
      setIsEdit(true)
      setOpenAddCampaignModal(true)
    } catch (error) {
      showError(error)
    }
  }

  const onAddCampaign = () => {
    setOpenAddCampaignModal(true)
  }

  const onArchiveCampaign = async id => {
    try {
      const result = await getCampaign(id).unwrap()
      dispatch(setCampaign(result.data))
      setOpenArchiveModal(true)
    } catch (error) {
      showError(error)
    }
  }

  const onSend = async id => {
    try {
      const result = await getCampaign(id).unwrap()
      dispatch(setCampaign(result.data))
      setOpenListAddModal(true)
    } catch (error) {
      showError(error)
    }
  }

  const BreadcrumbDetails = {
    breadcrumbItem: {
      label: "List",
      link: "campaigns",
    },
    paths: [
      {
        label: "Campaign",
        link: "campaigns",
      },
    ],
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Campaigns | Email-Hunter</title>
      </Helmet>

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs breadcrumbDetails={BreadcrumbDetails} />
          <Row>
            {currentLoggedInUser && currentLoggedInUser.role == "admin" ? (
              <Col xs="12">
                <Card>
                  <CardBody>
                    <div className="text-sm-end">
                      <Button
                        type="button"
                        color="primary"
                        className="btn mb-2 me-2"
                        onClick={onAddCampaign}
                      >
                        <i className="mdi mdi-plus-circle-outline me-1" />
                        Create Campaign
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ) : (
              <></>
            )}

            <Col xs="12">
              <Card>
                <CardBody>
                  <List
                    load={loadList}
                    onEditCampaign={onEditCampaign}
                    onArchiveCampaign={onArchiveCampaign}
                    onSend={onSend}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <AddEditModal
            open={openAddCampaignModal}
            setOpen={setOpenAddCampaignModal}
            setLoadList={setLoadList}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
          />

          <ArchiveModal
            show={openArchiveModal}
            setShow={setOpenArchiveModal}
            setLoadList={setLoadList}
          />

          <CreateMailWizzList
            open={openListAddModal}
            setOpen={setOpenListAddModal}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Campaigns
