import { apiSlice } from "../api"
const API_URL_PREFIX = "url"
export const urlApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    urlList: builder.mutation({
      query: payload => ({
        url: `/${API_URL_PREFIX}/list`,
        method: "POST",
        body: { ...payload },
      }),
    }),
    urlGet: builder.mutation({
      query: id => ({
        url: `/${API_URL_PREFIX}/get/${id}`,
        method: "GET",
      }),
    }),
  }),
})

export const { useUrlListMutation, useUrlGetMutation } = urlApiSlice
