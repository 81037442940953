module.exports = {
  API_URL: process.env.REACT_APP_API_URL,

  TOASTR_DEFAULT_OPTIONS: {
    closeButton: true,
    closeMethod: "fadeOut",
    closeEasing: "swing",
    showMethod: "fadeIn",
    showEasing: "swing",
    closeDuration: 300,
    hideDuration: 300,
    newestOnTop: true,
    progressBar: false,
    rtl: false,
    timeOut: 2000,
    positionClass: "toast-top-right mt-4",
    tapToDismiss: true,
    preventDuplicates: true,
    toastClass: "opacity-100 mt-4",
  },

  userRoles: {
    developer: {
      key: "developer",
      level: 0,
    },
    root: {
      key: "root",
      level: 1,
    },
    admin: {
      key: "admin",
      level: 2,
    },
  },

  campaign: {
    status: {
      draft: {
        key: "Draft",
        value: "draft",
        color: "dark",
        note: "Campaign is not ready for process.",
      },
      readyForProcessFile: {
        key: "Ready For File Processing",
        value: "readyForProcessFile",
        color: "primary",
        note: "Campaign is ready for file processing",
      },
      inFileProcessingQueue: {
        key: "File In Queue",
        value: "inFileProcessingQueue",
        color: "danger",
        note: "Campaign is in file processing queue",
      },
      fileProcessing: {
        key: "File Processing",
        value: "fileProcessing",
        color: "info",
        note: "Processing file is in progress",
      },
      readyForUploadToMailVerify: {
        key: "Ready For Mail Verify",
        value: "readyForUploadToMailVerify",
        color: "primary",
        note: "Campaign file is ready for upload to mail verify",
      },
      inMailVerifyQueue: {
        key: "in Mail Verify Queue",
        value: "inMailVerifyQueue",
        color: "danger",
        note: "Campaing file is in mail verify queue",
      },
      mailVerifying: {
        key: "Mail Verifying",
        value: "mailVerifying",
        color: "info",
        note: "Mail verifying",
      },
      mailVerified: {
        key: "Mails Verified",
        value: "mailVerified",
        color: "primary",
        note: "Campaing file is verified through mail verify",
      },
      inFinalizeEmailQueue: {
        key: "In Finalize Email Queue",
        value: "inFinalizeEmailQueue",
        color: "danger",
        note: "Campaign is in finalize email queue",
      },
      finalizingEmails: {
        key: "Finalizing Emails",
        value: "finalizingEmails",
        color: "info",
        note: "Campaign is finilizing verified emails",
      },
      readyForMailWizz: {
        key: "Ready For Mailwizz",
        value: "readyForMailWizz",
        color: "primary",
        note: "Campaign is ready for mailwizz",
      },
      mailWizzProcessing: {
        key: "Mailwizz Processing",
        value: "mailWizzProcessing",
        color: "info",
        note: "Campaign is sending mail to mailwizz lists",
      },
      completed: {
        key: "Completed",
        value: "completed",
        color: "success",
        note: "Campaign is completed",
      },
      noValidEmailInFile: {
        key: "No Valid Emails In File",
        value: "noValidEmailInFile",
        color: "secondary",
        note: "No valid emails found in file",
      },
      // status related to scraping
      readyForScraping: {
        key: "Ready For Scraping",
        value: "readyForScraping",
        color: "primary",
        note: "Campaign is ready for scraping",
      },
      scraping: {
        key: "Scraping",
        value: "scraping",
        color: "info",
        note: "Campaign is scraping emails.",
      },
      scrapingCompleted: {
        key: "Scraping Completed",
        value: "scrapingCompleted",
        color: "success",
        note: "Scraping completed for the campaign",
      },
      inEmailsFilePreparingQueue: {
        key: "In Email's File Preparing Queue",
        value: "inEmailsFilePreparingQueue",
        color: "danger",
        note: "Campaign is in prapare file from scraped emails queue",
      },
      scrapedEmailsFinalizing: {
        key: "Scraped Email's Finalizing",
        value: "scrapedEmailsFinalizing",
        color: "info",
        note: "Campaign is praparing file from scraped emails",
      },
      noValidURLInFile: {
        key: "No Valid Urls In File",
        value: "noValidURLInFile",
        color: "secondary",
        note: "No valid urls found in file",
      },
    },
    files: {
      all: {
        key: "All",
        value: "all",
        color: "dark",
        note: "All Emails (valid/invalid/unknown)",
      },
      verified: {
        key: "Verfied",
        value: "verified",
        color: "info",
        note: "All Verified Emails",
      },
      unknown: {
        key: "Unknown",
        value: "unknown",
        color: "warning",
        note: "All Unknown Emails.",
      },
      failed: {
        key: "Failed",
        value: "failed",
        color: "danger",
        note: "All Invalid Emails.",
      },
      uploaded: {
        key: "Uploaded",
        value: "uploaded",
        color: "primary",
        note: "File Uploaded By Campaign Creator.",
      },
      processed: {
        key: "Processed",
        value: "processed",
        color: "success",
        note: "File Processed At Our End.",
      },
      "scraped-emails": {
        key: "Scraped Emails",
        value: "scraped-emails",
        color: "info",
        note: "Scraped Emails",
      },
    },
  },
  url: {
    status: {
      completed: "success",
      aborted: "danger",
      progress: "info",
      pending: "warning"
    },
  },
  exportfile: {
    status: {
      completed: {key:"Completed",color:"success"},
      "in-progress": {key:"In-Progress",color:"info"},
      pending: {key:"Pending",color:"warning"},
      fail:{key:"Fail",color:"danger"},
      url:{key:"Url",icon:"mdi-link-variant"},
      email:{key:"Email",icon:"mdi-email-outline"}
    },
  },
  manualEmailVerify: {
    requestLimit: 20,
  },
}
