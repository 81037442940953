import React, { Fragment, useEffect, useMemo, useState } from "react"
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  useFilters,
  usePagination,
} from "react-table"

import {
  Table,
  Row,
  Col,
  Button,
  Input,
  UncontrolledTooltip,
} from "reactstrap"
import {  DateFormat, DateColumn, Name, Status, Text, Type } from "./ColumnFormatters"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { showError } from "helpers"
import LoadingSpinner from "components/Common/LoadingSpinner"
import {
  selectExportRequestList,
  selectExportRequestTotalRecords,
  setExportRequestList,
} from "redux/exportRequest/slice"
import { useExportRequestListQuery } from "redux/exportRequest/api"
import { TableRangeFooterLabel } from "components/Common/TableRangeFooterLabel"
import { selectCurrentUser } from "redux/auth/slice"
import { AdminFilter, DateRangeFilter, DefaultColumnFilter, Filter, StatusFilter, TypeFilter } from "./Filters"

const List = ({ load, onDownload }) => {
  const data = useSelector(selectExportRequestList)
  const totalRecords = useSelector(selectExportRequestTotalRecords)
  const dispatch = useDispatch()
  const [pSize, setPSize] = useState(10)
  const currentLoggedInUser = useSelector(selectCurrentUser)
  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "user",
        // filterable: true,
        Cell: cellProps => {
          return <Text {...cellProps} />
        },
      },
      {
        Header: "Created On",
        accessor: "createdOn",
        // disableSortBy: true,
        Filter: DateRangeFilter,
        maxWidth: 250,
        filterable: true,
        Cell: cellProps => {
          const expirdate=cellProps?.row?.original?.expiryDate
          return (cellProps?.row?.original?.status!=="fail"?<DateColumn cellProps={cellProps} expirdate={expirdate}/>:<DateFormat {...cellProps}/>)
        },
      },
      {
        Header: "Created By",
        accessor: "createdBy",
        filterable: true,
        // Filter: AdminFilter,
        // disableFilters: true,
        // disableSortBy: true,
        maxWidth: 150,
        Cell: cellProps => {
          return <Name {...cellProps} />
        },
      },
      {
        Header: "Export Type",
        accessor: "type",
        filterable: true,
        Filter: TypeFilter, 
        Cell: cellProps => {
          return <Type {...cellProps} />
        },
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        Filter: StatusFilter,
        Cell: cellProps => {
          return <Status {...cellProps} />
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        disableSortBy: true,
        Cell: cellProps => {
          return <div className="d-flex gap-3" >
            {cellProps?.row?.original?.status == "completed" && <Link
              to="#"
              className="text-primary"
              onClick={() => {
                const user = cellProps.row.original
                onDownload(user.user, user.file, user.createdOn, user.type)
              }}
            >
              <i
                className="mdi mdi-download  font-size-20"
                id={`id${cellProps?.row?.original?.file.replace(/[-.:]/g, '')}`}
              />
              <UncontrolledTooltip
                placement="top"
                target={`id${cellProps?.row?.original?.file.replace(/[-.:]/g, '')}`}
              >
                Download CSV File
              </UncontrolledTooltip>
            </Link>}
          </div>
        },
      },
    ],
    []
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    visibleColumns,
    setHiddenColumns,
    state: { pageIndex, pageSize, globalFilter, filters },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: pSize,
        sortBy: [
          // {
          //   desc: true,
          //   id: "createdOn",
          // },
        ],
        hiddenColumns: ["user"],
      },
      autoResetFilters: false,
      autoResetGlobalFilter: false,
      autoResetSortBy: false,
      manualPagination: true,
      pageCount: Math.ceil((totalRecords || 10) / pSize),
      manualSortBy: true,
      manualFilters: true,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  )

  // UseEffect hooks for handling user role-based column visibility
  useEffect(() => {
    if (
      currentLoggedInUser?.role == "admin"
    ) {
      setHiddenColumns(state => {
        const filters = state.filter((column) => column)
        return [...filters, "createdBy"]
      })
    }
  }, [currentLoggedInUser])

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }

  const onChangeInPageSize = event => {
    setPageSize(Number(event.target.value))
    setPSize(Number(event.target.value))
  }

  const onGoToPage = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    gotoPage(page)
  }

  useEffect(() => {
    if (!data.length && totalRecords && pageIndex > 0) {
      gotoPage(pageIndex - 1)
    }
  }, [data, totalRecords, gotoPage])

  const list = useExportRequestListQuery(state)
  if (list.isSuccess) {
    dispatch(setExportRequestList(list.data.data))
  }

  if (list.isError) {
    showError(list.error)
  }

  useEffect(() => {
    list.refetch()

    return () => {
      dispatch(setExportRequestList({
        files: [],
        count: 0,
      })
      )
    }
  }, [load, filters, state])

  return (
    <Fragment>
      <Row className="mb-2">
        <Col md={2}>
          <select
            className="form-select"
            value={pageSize}
            onChange={onChangeInPageSize}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
      </Row>

      {/* Table component */}
      <div className="table-responsive react-table">
        <Table bordered hover {...getTableProps()}>
          <thead className="table-light table-nowrap">
            {/* <tr>
              <th
                colSpan={visibleColumns.length}
                style={{
                  textAlign: "left",
                }}
              >
                <GlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={state.globalFilter}
                  setGlobalFilter={setGlobalFilter}
                />
              </th>
            </tr> */}

            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.id}>
                    <div className="mb-2" {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                    <Filter column={column} />
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {(list.isFetching || list.isLoading) && (
              <tr>
                <td
                  colSpan={visibleColumns.length}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <LoadingSpinner />
                </td>
              </tr>
            )}

            {!page.length && !list.isFetching && !list.isLoading && (
              <tr>
                <td
                  colSpan={visibleColumns.length}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <p>
                    {" "}
                    No Request Found{" "}
                    <span
                      style={{
                        fontSize: "100px;",
                      }}
                    >
                      &#128581;
                    </span>
                  </p>
                </td>
              </tr>
            )}

            {!list.isFetching &&
              !list.isLoading &&
              page.map(row => {
                prepareRow(row)
                return (
                  <Fragment key={row.getRowProps().key}>
                    <tr>
                      {row.cells.map(cell => {
                        return (
                          <td key={cell.id} {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        )
                      })}
                    </tr>
                  </Fragment>
                )
              })}
          </tbody>
        </Table>
      </div>
      {/* Pagination and records count */}
      {totalRecords ? (
        <Row className="justify-content-md-end justify-content-center align-items-center">
          <Col className="col justify-content-start">
            <TableRangeFooterLabel
              pageIndex={pageIndex}
              pageSize={pageSize}
              totalRecords={totalRecords}
              label={"export request"}
            />
          </Col>
          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                color="primary"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </Button>
              <Button
                color="primary"
                onClick={previousPage}
                disabled={!canPreviousPage}
              >
                {"<"}
              </Button>
            </div>
          </Col>
          <Col className="col-md-auto d-none d-md-block">
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </Col>
          <Col className="col-md-auto">
            <Input
              type="number"
              min={1}
              style={{ width: 70 }}
              max={pageOptions.length}
              defaultValue={pageIndex + 1}
              onChange={onGoToPage}
            />
          </Col>

          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                color="primary"
                onClick={nextPage}
                disabled={!canNextPage}
              >
                {">"}
              </Button>
              <Button
                color="primary"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </Button>
            </div>
          </Col>
        </Row>
      ) : (
        <></>
      )}
    </Fragment>
  )
}
export default List