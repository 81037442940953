import React, { useEffect, useMemo, useState } from "react"
import { isEmpty } from "lodash"
import "../../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useSelector, useDispatch } from "react-redux"
import { useBeforeunload } from "react-beforeunload"
import classnames from "classnames"
import { Link } from "react-router-dom"

import {
  Modal,
  ModalHeader,
  ModalBody,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"

const steps = ["info", "file-upload", "file-field-mapping", "mailwizz"]

//steps related components
import Info from "./Steps/Info"
import FileUpload from "./Steps/FileUpload"
import FieldMapping from "./Steps/FieldMapping"
import MailWizz from "./Steps/MailWizz"
import { selectCampaign, setCampaign } from "redux/campaigns/slice"

function AddEditModal({ isEdit, setIsEdit, open, setOpen, setLoadList }) {
  const dispatch = useDispatch()
  const campaign = useSelector(selectCampaign)
  useBeforeunload(() => "You may loss your data");
  const [activeTab, setactiveTab] = useState(steps[0]);
  useEffect(() => {
    if (campaign && campaign.campaignProgress) {
      const step = steps.findIndex(step => step == campaign.campaignProgress)
      let stepTobeActive = step > -1 && step < steps.length - 1 ? step + 1 : 0
      setactiveTab(steps[stepTobeActive])
    }
  }, [campaign])

  const closeModal = () => {
    setIsEdit(false);
    setOpen(false);
    dispatch(setCampaign(null));
    setactiveTab(steps[0]);
    setLoadList(state => !state)
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={open}
        backdrop={"static"}
        toggle={closeModal}
        centered={false}
        size={"xl"}
        scrollable={true}
        keyboard={false}
      >
        <ModalHeader tag="h4">
          {campaign ? campaign.name : "Create Campaign"}
        </ModalHeader>
        <button
          type="button"
          onClick={closeModal}
          className="btn-close position-absolute end-0 top-0 m-3"
        ></button>
        <ModalBody>
          <div className="wizard clearfix">
            <div className="steps clearfix">
              <ul>
                <NavItem
                  className={classnames({ current: activeTab === steps[0] })}
                >
                  <NavLink
                    className={classnames({ current: activeTab === steps[0] })}
                    disabled={true}
                  >
                    <span className="number">1.</span> Basic Details
                  </NavLink>
                </NavItem>

                <NavItem
                  className={classnames({ current: activeTab === steps[1] })}
                >
                  <NavLink
                    className={classnames({ current: activeTab === steps[1] })}
                    disabled={true}
                  >
                    <span className="number">2.</span> Upload file
                  </NavLink>
                </NavItem>

                <NavItem
                  className={classnames({ current: activeTab === steps[2] })}
                >
                  <NavLink
                    className={classnames({ current: activeTab === steps[2] })}
                    disabled={true}
                  >
                    <span className="number">3.</span> Field Mapping
                  </NavLink>
                </NavItem>

                <NavItem
                  className={classnames({ current: activeTab === steps[3] })}
                >
                  <NavLink
                    className={classnames({ current: activeTab === steps[3] })}
                    disabled={true}
                  >
                    <span className="number">4.</span> MailWizZ
                  </NavLink>
                </NavItem>
              </ul>
            </div>
            <div className="content clearfix">
              <TabContent activeTab={activeTab} className="body">
                <TabPane tabId={steps[0]}>
                  <Info
                    activeTab={activeTab}
                    setactiveTab={setactiveTab}
                    closeModal={closeModal}
                  />
                </TabPane>
                <TabPane tabId={steps[1]}>
                  <FileUpload
                    activeTab={activeTab}
                    setactiveTab={setactiveTab}
                    closeModal={closeModal}
                  />
                </TabPane>
                <TabPane tabId={steps[2]}>
                  <FieldMapping
                    activeTab={activeTab}
                    setactiveTab={setactiveTab}
                    closeModal={closeModal}
                  />
                </TabPane>
                <TabPane tabId={steps[3]}>
                  <MailWizz
                    activeTab={activeTab}
                    setactiveTab={setactiveTab}
                    closeModal={closeModal}
                  />
                </TabPane>
              </TabContent>
            </div>
          </div>     
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default AddEditModal
