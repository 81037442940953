import { createSlice } from "@reduxjs/toolkit"

const urlEmailSlice = createSlice({
    name: 'urlEmail',
    initialState: { list: [], totalRecord: 0, },
    reducers: {
        setEmailList: (state, action) => {
            const { emails, countEmails } = action.payload;
            state.list = emails
            state.totalRecord = countEmails
        },
    },
})

export const { setEmailList } = urlEmailSlice.actions;
export default urlEmailSlice.reducer;

export const selectUrlEmailList = (state) => state.urlEmail.list;
export const selectUrlTotalEmailRecords = (state) => state.urlEmail.totalRecord;