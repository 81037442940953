import React, { Fragment, useEffect, useMemo, useState } from "react"
import {
    useTable,
    useGlobalFilter,
    useSortBy,
    useFilters,
    useExpanded,
    usePagination,
} from "react-table"
import {
    Table,
    Row,
    Col,
    Button,
    Input,
    CardBody,
    Card,
    CardTitle,
} from "reactstrap"
import { Text } from "../ColumnFormatters"
import { showError } from "helpers"
import { useDispatch, useSelector } from "react-redux"
import LoadingSpinner from "components/Common/LoadingSpinner"
import { TableRangeFooterLabel } from "components/Common/TableRangeFooterLabel"
import { useUrlMailListQuery } from "redux/urlMailList/api"
import { DefaultColumnFilter } from "../Filters"
import { useParams } from "react-router-dom"
import { selectUrlEmailList, selectUrlTotalEmailRecords, setEmailList } from "redux/urlMailList/slice"
import { formatBigNumber } from "helpers/data-formatters"

const CreateMailList = () => {
    const { id } = useParams()
    const data = useSelector(selectUrlEmailList)
    const totalRecords = useSelector(selectUrlTotalEmailRecords)
    const dispatch = useDispatch()
    const [pSize, setPSize] = useState(10)
    const columns = useMemo(
        () => [
            {
                Header: "Id",
                accessor: "_id",
                Cell: cellProps => {
                    return <Text {...cellProps} />
                },
            },
            {
                Header: "Email",
                accessor: "email",
                filterable: true,
                Cell: cellProps => {
                    return <Text {...cellProps} />
                },
            },
        ],
        []
    )
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state,
        visibleColumns,
        state: { pageIndex, pageSize, filters },
    } = useTable(
        {
            columns,
            data,
            defaultColumn: { Filter: DefaultColumnFilter },
            initialState: {
                pageIndex: 0,
                pageSize: pSize,
                sortBy: [
                    {
                        desc: true,
                        id: "_id",
                    },
                ],
                hiddenColumns: ["_id"],
            },
            autoResetFilters: false,
            autoResetGlobalFilter: false,
            autoResetSortBy: false,
            manualPagination: true,
            pageCount: Math.ceil((totalRecords || 10) / pSize),
            manualSortBy: true,
            manualFilters: true,
            manualGlobalFilter: true
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        usePagination
    )
    const generateSortingIndicator = column => {
        return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
    }
    const onChangeInPageSize = event => {
        setPageSize(Number(event.target.value))
        setPSize(Number(event.target.value))
    }
    const onGoToPage = event => {
        const page = event.target.value ? Number(event.target.value) - 1 : 0
        gotoPage(page)
    }

    useEffect(() => {
        if (!data.length && totalRecords && pageIndex > 0) {
            gotoPage(pageIndex - 1)
        }
    }, [data, totalRecords, gotoPage])
    const list = useUrlMailListQuery({ state: state, id: id });

    if (list.isSuccess) {
        dispatch(setEmailList(list.data.data))
    }

    if (list.isError) {
        showError(list.error)
    }

    useEffect(() => {
        list.refetch()
        return () => {
            dispatch(setEmailList({
                emails: [],
                count: 0,
            })
            )
        }
    }, [filters, state])
    return (
        <Fragment>
            <Card>
                <CardBody>
                    <CardTitle className="mb-4">
                        Email Found{" "}
                    </CardTitle>
                    <Row className="mb-2">
                        <Col md={2}>
                            <select
                                className="form-select"
                                value={pageSize}
                                onChange={onChangeInPageSize}
                            >
                                {[10, 20, 30, 40, 50].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        Show {pageSize}
                                    </option>
                                ))}
                            </select>
                        </Col>
                    </Row>
                    <div className="table-responsive react-table">
                        <Table bordered hover {...getTableProps()}>
                            <thead className="table-light table-nowrap">
                                {headerGroups.map(headerGroup => (
                                    <tr
                                        key={headerGroup.id}
                                        {...headerGroup.getHeaderGroupProps()}
                                    >
                                        {headerGroup.headers.map(column => (
                                            <th key={column.id}>
                                                <div
                                                    className="mb-2"
                                                    {...column.getSortByToggleProps()}
                                                >
                                                    {column.render("Header")}
                                                    {generateSortingIndicator(column)}
                                                </div>
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>

                            <tbody {...getTableBodyProps()}>
                                {(list.isFetching || list.isLoading) && (
                                    <tr>
                                        <td
                                            colSpan={visibleColumns.length}
                                            style={{
                                                textAlign: "center",
                                            }}
                                        >
                                            <LoadingSpinner />
                                        </td>
                                    </tr>
                                )}

                                {!page.length &&
                                    !list.isFetching &&
                                    !list.isLoading && (
                                        <tr>
                                            <td
                                                colSpan={visibleColumns.length}
                                                style={{
                                                    textAlign: "center",
                                                }}
                                            >
                                                <p>
                                                    {" "}
                                                    No Email Found{" "}
                                                    <span
                                                        style={{
                                                            fontSize: "100px;",
                                                        }}
                                                    >
                                                        &#128581;
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                    )}

                                {!list.isFetching &&
                                    !list.isLoading &&
                                    page.map(row => {
                                        prepareRow(row)
                                        return (
                                            <Fragment key={row.getRowProps().key}>
                                                <tr>
                                                    {row.cells.map(cell => {
                                                        return (
                                                            <td key={cell.id} {...cell.getCellProps()}>
                                                                {cell.render("Cell")}
                                                            </td>
                                                        )
                                                    })}
                                                </tr>
                                            </Fragment>
                                        )
                                    })}
                            </tbody>
                        </Table>
                    </div>
                    {totalRecords > 0 && (
                        <Row className="justify-content-md-end justify-content-center align-items-center">
                            <Col className="col justify-content-start">
                                <TableRangeFooterLabel
                                    pageIndex={pageIndex}
                                    pageSize={pageSize}
                                    totalRecords={totalRecords}
                                />
                            </Col>
                            <Col className="col-md-auto">
                                <div className="d-flex gap-1">
                                    <Button
                                        color="primary"
                                        onClick={() => gotoPage(0)}
                                        disabled={!canPreviousPage}
                                    >
                                        {"<<"}
                                    </Button>
                                    <Button
                                        color="primary"
                                        onClick={previousPage}
                                        disabled={!canPreviousPage}
                                    >
                                        {"<"}
                                    </Button>
                                </div>
                            </Col>
                            <Col className="col-md-auto d-none d-md-block">
                                Page{" "}
                                <strong>
                                    {pageIndex + 1} of {formatBigNumber(pageOptions.length)}
                                </strong>
                            </Col>
                            <Col className="col-md-auto">
                                <Input
                                    type="number"
                                    min={1}
                                    style={{ width: 70 }}
                                    max={pageOptions.length}
                                    defaultValue={pageIndex + 1}
                                    onChange={onGoToPage}
                                />
                            </Col>

                            <Col className="col-md-auto">
                                <div className="d-flex gap-1">
                                    <Button
                                        color="primary"
                                        onClick={nextPage}
                                        disabled={!canNextPage}
                                    >
                                        {">"}
                                    </Button>
                                    <Button
                                        color="primary"
                                        onClick={() => gotoPage(pageCount - 1)}
                                        disabled={!canNextPage}
                                    >
                                        {">>"}
                                    </Button>
                                </div>
                            </Col>
                        </Row>)}
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default CreateMailList
