import { showToaster } from "helpers"
import { apiSlice } from "../api"
const API_URL_PREFIX = "file"
export const fileAPISlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getFile: builder.mutation({
      query: ({ campaignId, fileName, campaignName }) => ({
        url: `/${API_URL_PREFIX}/${campaignId}/${fileName}.csv`,
        method: "GET",
        responseHandler: async response => {
          if(response.status !== 200) {
            showToaster("error", 'Unable to download !', 'Error');
            return;
          }
          const link = document.createElement("a")
          link.href = URL.createObjectURL(await response.blob());
          link.download = `${fileName}-${campaignName}-${Date.now()}.csv`;
          document.body.append(link)
          link.click()
          link.remove();
        },
      }),
    }),
  }),
})

export const { useGetFileMutation } = fileAPISlice
