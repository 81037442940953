import React from "react"
import { Badge } from "reactstrap"
import { wordTitleCase, formatDate } from "../../helpers/data-formatters"

const Email = cell => {
  return cell.value ? cell.value : "-"
}

const Name = cell => {
  return cell.value ? cell.value : "-"
}

const Date = cell => {
  return formatDate(cell.value)
}

const Status = cell => {
  let color
  switch (cell.value) {
    case "ok":
      color = "success"
      break
    case "fail":
      color = "danger"
      break
    case "unknown":
      color = "info"
      break
    default:
      color = "warning"
  }
  const label = cell.value || "pending"
  return (
    <Badge className={"font-size-12 badge-soft-" + color} pill>
      {wordTitleCase(label)}
    </Badge>
  )
}

export { Email, Status, Date, Name }
