import React, { Fragment, useEffect, useMemo, useState } from "react"
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  useFilters,
  usePagination,
} from "react-table"

import { Table, Form, Row, Col, Button, Input, Spinner, FormFeedback, Label, ModalBody, ModalHeader, Modal } from "reactstrap"
import {
  Filter,
  DefaultColumnFilter,
  CampaignFilter,
  DateRangeFilter,
  StatusFilter,
  GlobalFilter,
} from "../Filters"
import { Email, Status, Date, Name } from "../ColumnFormatters"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { showError, showToaster } from "helpers"
import LoadingSpinner from "components/Common/LoadingSpinner"
import {
  selectEmailsList,
  selectEmailTotalRecords,
  setEmailsList,
} from "redux/emails/slice"
import { useEmailListMutation } from "redux/emails/api"
import { TableRangeFooterLabel } from "components/Common/TableRangeFooterLabel"
import { formatBigNumber } from "helpers/data-formatters"
import * as Yup from "yup"
import { EMAIL_REGEX } from "constants/regex"
import { useFormik } from "formik"

const List = ({ load }) => {
  const data = useSelector(selectEmailsList)
  const totalRecords = useSelector(selectEmailTotalRecords)
  const dispatch = useDispatch()
  const [pSize, setPSize] = useState(10)
  const [openAddEditModal, setOpenAppEditModal] = useState(false)
  const [getEmailList, EmailApi] = useEmailListMutation()
  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "_id",
        // filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />
        },
      },
      {
        Header: "Email",
        accessor: "email",
        filterable: true,
        Cell: cellProps => {
          return <Email {...cellProps} />
        },
      },
      // {
      //   Header: "Campaign",
      //   accessor: "campaign.name",
      //   id: "campaigns",
      //   Cell: cellProps => {
      //     return <Name {...cellProps} />
      //   },
      // },
      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        Filter: StatusFilter,
        Cell: cellProps => {
          return <Status {...cellProps} />
        },
      },
      {
        Header: "Date",
        accessor: "createdOn",
        filterable: true,
        Filter: DateRangeFilter,
        Cell: cellProps => {
          return <Date {...cellProps} />
        },
      },
    ],
    []
  )
  const toggle = () => {
    // e.preventDefault(); 
    setOpenAppEditModal(!openAddEditModal)
    // validation.resetForm()

  }
  const validationSchema = {
    email: Yup.string()
      .trim()
      .matches(/^\S*$/, 'Whitespace is not allowed')
      .matches(EMAIL_REGEX, "Enter valid Email")
      .required("Please enter Email")
      .email("Enter valid Email"),
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    visibleColumns,
    state: { pageIndex, pageSize, globalFilter, filters },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: pSize,
        sortBy: [
          {
            desc: true,
            id: "_id",
          },
        ],
        hiddenColumns: ["_id"],
      },
      autoResetFilters: false,
      autoResetGlobalFilter: false,
      autoResetSortBy: false,
      manualPagination: true,
      pageCount: Math.ceil((totalRecords || 10) / pSize),
      manualSortBy: true,
      manualFilters: true,
      manualGlobalFilter: true
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  )

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }

  const onChangeInPageSize = event => {
    setPageSize(Number(event.target.value))
    setPSize(Number(event.target.value))
  }

  const onGoToPage = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    gotoPage(page)
  }

  useEffect(() => {
    if (!data.length && totalRecords && pageIndex > 0) {
      gotoPage(pageIndex - 1)
    }
  }, [data, totalRecords, gotoPage])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: { email: " " },

    validationSchema: Yup.object(validationSchema),
    onSubmit: async values => {
      try {
        const newstate = {
          ...state, email: values.email,
          isExport: true
        }
        const result = await getEmailList(newstate).unwrap()
        dispatch(setEmailsList(result.data))
        showToaster("success", "We have your request, go to Export Request for more.", "Success")
        toggle()
        validation.resetForm()
      } catch (error) {
        showError(error)
      }
    },
  })
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getEmailList(state).unwrap()
        dispatch(setEmailsList(result.data))
      } catch (error) {
        showError(error)
      }
    }

    fetchData()
  }, [load, filters, state])

  return (
    <Fragment>
      <Row className="mb-2 clearfix">
        <Col md={2} className="float-start">
          <select
            className="form-select"
            value={pageSize}
            onChange={onChangeInPageSize}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        <Col className="float-end">
          <div className="text-sm-end">
            <Button
              type="button"
              color="primary"
              disabled={totalRecords == 0}
              className="btn mb-2 me-2 "
              onClick={(e) => { toggle(e) }}
            >
              Export To CSV
              <i className="mdi mdi-export font-size-15  ms-1" />
            </Button>
          </div>
        </Col>
      </Row>

      <div className="table-responsive react-table">
        <Table bordered hover {...getTableProps()}>
          <thead className="table-light table-nowrap">

            <tr>
              <th
                colSpan={visibleColumns.length}
                style={{
                  textAlign: "left",
                }}
              >
                <GlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={state.globalFilter}
                  setGlobalFilter={setGlobalFilter}
                />
              </th>
            </tr>

            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.id}>
                    <div className="mb-2" {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                    <Filter column={column} />
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {(EmailApi.isFetching || EmailApi.isLoading) && (
              <tr>
                <td
                  colSpan={visibleColumns.length}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <LoadingSpinner />
                </td>
              </tr>
            )}

            {!page.length && !EmailApi.isFetching && !EmailApi.isLoading && (
              <tr>
                <td
                  colSpan={visibleColumns.length}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <p>
                    {" "}
                    No Emails Found{" "}
                    <span
                      style={{
                        fontSize: "100px;",
                      }}
                    >
                      &#128581;
                    </span>
                  </p>
                </td>
              </tr>
            )}

            {!EmailApi.isFetching &&
              !EmailApi.isLoading &&
              page.map(row => {
                prepareRow(row)
                return (
                  <Fragment key={row.getRowProps().key}>
                    <tr>
                      {row.cells.map(cell => {
                        return (
                          <td key={cell.id} {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        )
                      })}
                    </tr>
                  </Fragment>
                )
              })}
          </tbody>
        </Table>
      </div>
      {totalRecords ? (
        <Row className="justify-content-md-end justify-content-center align-items-center">
          <Col className="col justify-content-start">
            <TableRangeFooterLabel
              pageIndex={pageIndex}
              pageSize={pageSize}
              totalRecords={totalRecords}
              label={"emails"}
            />
          </Col>
          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                color="primary"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </Button>
              <Button
                color="primary"
                onClick={previousPage}
                disabled={!canPreviousPage}
              >
                {"<"}
              </Button>
            </div>
          </Col>
          <Col className="col-md-auto d-none d-md-block">
            Page{" "}
            <strong>
              {pageIndex + 1} of {formatBigNumber(pageOptions.length)}
            </strong>
          </Col>
          <Col className="col-md-auto">
            <Input
              type="number"
              min={1}
              style={{ width: 70 }}
              max={pageOptions.length}
              defaultValue={pageIndex + 1}
              onChange={onGoToPage}
            />
          </Col>

          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                color="primary"
                onClick={nextPage}
                disabled={!canNextPage}
              >
                {">"}
              </Button>
              <Button
                color="primary"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </Button>
            </div>
          </Col>
          <Modal isOpen={openAddEditModal} toggle={toggle} backdrop={"static"} centered={true}>
            <ModalHeader toggle={toggle} tag="h4">
              Export Email
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <div className="mb-3">
                  <Label className="form-label">Email*</Label>
                  <Input
                    name="email"
                    type="text"
                    placeholder="Enter Email"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ""}
                    invalid={
                      validation.touched.email && validation.errors.email
                        ? true
                        : false
                    }
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <FormFeedback type="invalid">
                      {validation.errors.email}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="text-danger">
                  Note: Your export request will be expire after 10 days
                  <div className="text-end">
                    <Button
                      type="submit"
                      className="btn btn-success save-user"
                      color="success"
                      disabled={(validation.errors.email)}
                    >
                      send
                    </Button>
                  </div>
                </div>
              </Form>
            </ModalBody>
          </Modal>
        </Row>
      ) : (
        <></>
      )}
    </Fragment>
  )
}
export default List
