import React from "react"
import { Input } from "reactstrap"
import { wordTitleCase } from "../../helpers/data-formatters"
import { useAsyncDebounce } from "react-table"

export const Filter = ({ column }) => {
  return (
    <div style={{ marginTop: 5 }}>
      {column.canFilter && column.render("Filter")}
    </div>
  )
}

export const DefaultColumnFilter = ({
    column: {
        filterValue,
        setFilter,
        preFilteredRows: { length },
    },
}) => {
    const [value, setValue] = React.useState(filterValue);
    const onChange = useAsyncDebounce(value => {
        setFilter(value || undefined); 
    }, 500)

    return (
        <Input
            value={value || ''}
            onChange={(e) => {
                setValue(e.target.value)
                onChange(e.target.value);
            }}
            placeholder={`Search...`}
        />
    );
};

export const SelectColumnFilter = ({ column }) => {
  const { filterValue, setFilter } = column
  return (
    <select
      id="custom-select"
      className="form-select"
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}
    >
      <option value="">All</option>
      <option value='true'>Active</option>
      <option value='false'>In-active</option>
    </select>
  )
}

export const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) => {
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 500)

  return (
    <Input
      value={value || ""}
      onChange={e => {
        setValue(e.target.value)
        onChange(e.target.value)
      }}
      placeholder={`Global Search...`}
    />
  )
}
