import React, { useState } from "react"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useBeforeunload } from "react-beforeunload"

import {
  Button,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Spinner,
} from "reactstrap"
import { showError, showToaster } from "helpers"
import { useChangePassowrdMutation } from "redux/users/api"
import { logOut } from "redux/auth/slice"
import { useDispatch } from "react-redux"

function PasswordChangeModal({ show, setShow }) {
  const [oldPasswordShow, setOldPasswordShow] = useState(false)
  const [passwordShow, setPasswordShow] = useState(false)
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
  const [changePassword, ChangePasswordAPI] = useChangePassowrdMutation();
  const dispatch = useDispatch();

  useBeforeunload(() => "You may loss your data")

  // formik validation & initialization
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: Yup.object().shape({
      oldPassword: Yup.string()
        .trim()
        .matches(/^\S*$/, "Whitespace is not allowed")
        .required("Please enter Old Password"),
      newPassword: Yup.string()
        .trim()
        .matches(/^\S*$/, "Whitespace is not allowed")
        .required("Please enter New Password")
        .min(8, "New Password should have minimum 8 characters")
        .max(20, "New Password should have maximum 20 characters"),
      confirmNewPassword: Yup.string()
        .required("Please enter Confirm New Password")
        .matches(/^\S*$/, "Whitespace is not allowed")
        .oneOf(
          [Yup.ref("newPassword"), null],
          "Confirm New Password must be same as New Password"
        ),
    }),
    onSubmit: async values => {
      try {
        const result = await changePassword(values).unwrap()
        showToaster("success", "Password Sucessfully Changed", "Success")
        setShow(false)
        validation.resetForm();
        dispatch(logOut());
      } catch (error) {
        showError(error);
      }
    },
  })

  const onCloseClick = () => {
    setShow(false)
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={show}
        toggle={onCloseClick}
        centered={true}
        backdrop={"static"}
      >
        <ModalHeader toggle={onCloseClick} tag="h4">
          Change Password
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <Row>
              <Col className="col-12">
                {/* old password */}
                <div className="mb-3">
                  <Label className="form-label">Old Password*</Label>
                  <div className="input-group auth-pass-inputgroup">
                    <Input
                      name="oldPassword"
                      type={oldPasswordShow ? "text" : "password"}
                      placeholder="Enter Old Password"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.oldPassword || ""}
                      invalid={
                        validation.touched.oldPassword &&
                        validation.errors.oldPassword
                          ? true
                          : false
                      }
                      onPaste={e => {
                        e.preventDefault()
                        return false
                      }}
                      onCopy={e => {
                        e.preventDefault()
                        return false
                      }}
                    />
                    <button
                      onClick={() => setOldPasswordShow(!oldPasswordShow)}
                      className="btn btn-light "
                      type="button"
                      id="password-addon"
                    >
                      <i
                        className={`mdi ${
                          !oldPasswordShow
                            ? "mdi-eye-outline"
                            : "mdi-eye-off-outline"
                        }`}
                      ></i>
                    </button>
                    {validation.touched.oldPassword &&
                    validation.errors.oldPassword ? (
                      <FormFeedback type="invalid">
                        {validation.errors.oldPassword}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>

                {/* password */}
                <div className="mb-3">
                  <Label className="form-label">New Password*</Label>
                  <div className="input-group auth-pass-inputgroup">
                    <Input
                      name="newPassword"
                      type={passwordShow ? "text" : "password"}
                      placeholder="Enter New Password"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.newPassword || ""}
                      invalid={
                        validation.touched.newPassword &&
                        validation.errors.newPassword
                          ? true
                          : false
                      }
                      onPaste={e => {
                        e.preventDefault()
                        return false
                      }}
                      onCopy={e => {
                        e.preventDefault()
                        return false
                      }}
                    />
                    <button
                      onClick={() => setPasswordShow(!passwordShow)}
                      className="btn btn-light "
                      type="button"
                      id="password-addon"
                    >
                      <i
                        className={`mdi ${
                          !passwordShow
                            ? "mdi-eye-outline"
                            : "mdi-eye-off-outline"
                        }`}
                      ></i>
                    </button>
                    {validation.touched.newPassword &&
                    validation.errors.newPassword ? (
                      <FormFeedback type="invalid">
                        {validation.errors.newPassword}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>

                {/* confirm password */}
                <div className="mb-3">
                  <Label className="form-label">Confirm New Password*</Label>
                  <div className="input-group auth-pass-inputgroup">
                    <Input
                      name="confirmNewPassword"
                      type={confirmPasswordShow ? "text" : "password"}
                      placeholder="Enter Confirm Password"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.confirmNewPassword || ""}
                      invalid={
                        validation.touched.confirmNewPassword &&
                        validation.errors.confirmNewPassword
                          ? true
                          : false
                      }
                      onPaste={e => {
                        e.preventDefault()
                        return false
                      }}
                      onCopy={e => {
                        e.preventDefault()
                        return false
                      }}
                    />
                    <button
                      onClick={() =>
                        setConfirmPasswordShow(!confirmPasswordShow)
                      }
                      className="btn btn-light "
                      type="button"
                      id="password-addon"
                    >
                      <i
                        className={`mdi ${
                          !confirmPasswordShow
                            ? "mdi-eye-outline"
                            : "mdi-eye-off-outline"
                        }`}
                      ></i>
                    </button>
                    {validation.touched.confirmNewPassword &&
                    validation.errors.confirmNewPassword ? (
                      <FormFeedback type="invalid">
                        {validation.errors.confirmNewPassword}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <Button
                    type="submit"
                    className="btn btn-success save-user"
                    color="success"
                    disabled={ChangePasswordAPI.isLoading || !validation.isValid}
                  >
                    {!ChangePasswordAPI.isLoading ? (
                      "Change Password"
                    ) : (
                      <>
                        <span>Saving New Password...</span>
                        <Spinner size="sm"></Spinner>
                      </>
                    )}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default PasswordChangeModal
