import React from "react"
import { Badge } from "reactstrap"
import { wordTitleCase, formatDate, formatDuration } from "../../helpers/data-formatters"
import { Link, useLocation } from "react-router-dom"


const   Domain = cell => {
    const url = cell.row.original
    const path = `/urls/${url._id}`
    const location = useLocation()
    return (
      <Link
        to={path}
        state={{ from: location }}
        title="Click here to view url details"
      >
        {wordTitleCase(cell.value)}
      </Link>
    )
}

const Text = cell => {
  return cell.value ? cell.value : "-"
}

const Date = cell => {
  return formatDate(cell.value)
}

const ResponseTime = cell => {
  return formatDuration(cell.value || 0);
}

const Status = cell => {
  let color
  switch (cell.value) {
    case "completed":
      color = "success"
      break
    case "aborted":
      color = "danger"
      break
    case "in-progress":
      color = "info"
      break
    default:
      color = "warning"
  }
  const label = cell.value;
  return (
    <Badge className={"font-size-12 badge-soft-" + color} pill>
      {wordTitleCase(label)}
    </Badge>
  )
}

export { Status, Date, Domain, Text, ResponseTime }
