import { createSlice, createSelector} from "@reduxjs/toolkit"

const usersSlice = createSlice({
    name: 'users',
    initialState: { list: [], totalRecord: 0,  user: null },
    reducers: {
        setUsersList: (state, action) => {
            const { list, count } = action.payload;
            state.list = list
            state.totalRecord = count
        },
        setUser: (state, action) => {
            state.user = action.payload;
        }
    },
})

export const { setUsersList, setUser } = usersSlice.actions;

export default usersSlice.reducer;

export const selectUsersList = createSelector(
    state => state.users.list,
    users => users
)

export const selectTotalRecordsCount = createSelector(
    state => state.users.totalRecord,
    totalRecord => totalRecord
)

export const selectUser = (state) => state.users.user;