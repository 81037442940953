import React from "react"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Helmet } from "react-helmet"
import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  Container,
  Button,
  CardSubtitle
} from "reactstrap"
import EmailVerifierSetting from "./modules/EmailVerifier"
import MailWizzSettings from "./modules/MailWizz"
import { useSelector } from "react-redux"
import { selectCurrentUser } from "redux/auth/slice"
import config from "../../config"

const Settings = props => {
  const loggedInUser = useSelector(selectCurrentUser);
  const BreadcrumbDetails = {
    breadcrumbItem : {
      label : 'Settings',
      link : 'settings'
    },
    paths : []
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Settings | Email-Hunter</title>
      </Helmet>

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs breadcrumbDetails={BreadcrumbDetails}/>

          <Row>
            {loggedInUser && loggedInUser.role == config.userRoles.root.key && (
              <Col xs="6">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-3"> <i className="bx bx-mail-send bx-spin"></i> Email Verifier</CardTitle>
                    <EmailVerifierSetting />
                  </CardBody>
                </Card>
              </Col>
            )}

            {loggedInUser && loggedInUser.role == config.userRoles.admin.key && (
              <Col xs="6">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-3"> <i className="bx bx-mail-send bx-spin"></i>  MailWizz</CardTitle>
                    <MailWizzSettings />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Settings
