import React, { useRef } from "react"
import { Input } from "reactstrap"
import Flatpickr from "react-flatpickr";
import AsyncSelect from "react-select/async"
import { useAsyncDebounce } from "react-table"
import { useUserFilterMutation } from "redux/users/api";

export const Filter = ({ column }) => {
    return (
      <div style={{ marginTop: 5 }}>
        {column.canFilter && column.render("Filter")}
      </div>
    )
  }
// request status filter
export const StatusFilter = ({ column }) => {
    const { filterValue, setFilter } = column
    return (
        <select
            id="custom-select"
            className="form-select"
            value={filterValue}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
        >
            <option value="">All</option>
            <option value="completed">Completed</option>
            <option value="fail">Fail</option>
            <option value="in-progress">In-Progress</option>
            <option value='pending'>Pending</option>
        </select>
    )
}
//request type filter
export const TypeFilter = ({ column }) => {
    const { filterValue, setFilter } = column
    return (
        <select
            id="custom-select"
            className="form-select"
            value={filterValue}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
        >
            <option value="">All</option>
            <option value="email">Email</option>
            <option value="url">Url</option>
        </select>
    )
}
// Date range filter 
export const DateRangeFilter = ({ column }) => {
    const { filterValue, setFilter } = column;
    const flatPickerRef = useRef(null);
    const handelOpen = () => {
        // If date range is selected, clear minDate and maxDate restrictions
        flatPickerRef.current.flatpickr.set("maxDate", new Date())
    }
    return (
        <Flatpickr
            className="form-control d-block "
            placeholder="Search By Date Range..."
            ref={flatPickerRef}
            value={filterValue}
            onOpen={handelOpen}
            options={
                {
                    mode: "range",
                    showMonths: 2,
                    dateFormat: "YYYY-MM-DD",
                    enableTime: false,
                    altInput: true,
                }
            }
            onChange={(dates) => {
                if (dates.length == 2 || dates.length == 0) {
                    setFilter(dates);
                }
            }}
        />
    )
}
// DefaultColumnFilter
export const DefaultColumnFilter = ({
    column: {
        filterValue,
        setFilter,
        preFilteredRows: { length },
    },
}) => {
    const [value, setValue] = React.useState(filterValue);
    const onChange = useAsyncDebounce(value => {
        setFilter(value || undefined); 
    }, 500)

    return (
        <Input
            value={value || ''}
            onChange={(e) => {
                setValue(e.target.value)
                onChange(e.target.value);
            }}
            placeholder={`Search...`}
        />
    );
};
// AdminFilter
export const AdminFilter = ({ column }) => {
    const { filterValue, setFilter } = column
    const [userFilter, UserFilterAPI] = useUserFilterMutation()
    const loadUsers = useAsyncDebounce(async value => {
      try {
        const result = await userFilter(value).unwrap()
        return result.data || []
      } catch (error) {
        console.log(error);
        console.log("Error during fetching campaign filter")
        return []
      }
    }, 500)
  
    const styles = {
      // container: base => {
      //   return {
      //     ...base,
      //     width: "400px",
      //   }
      // },
    }
  
    return (
      <div className="ajax-select mt-lg-0 select2-container">
        <AsyncSelect
          isMulti
          cacheOptions
          loadOptions={loadUsers}
          value={filterValue}
          onChange={selectedOptions => {
            setFilter(selectedOptions)
          }}
  
          placeholder={"Search..."}
          noOptionsMessage={() => "No Users Found"}
          loadingMessage={() => "Searching Users"}
          styles={styles}
        />
      </div>
    )
  }
