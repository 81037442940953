import { createSlice, createSelector} from "@reduxjs/toolkit"

const campaignSlice = createSlice({
    name: 'campaigns',
    initialState: { list: [], totalRecord: 0,  campaign: null, uploadProgress : 0 },
    reducers: {
        setCampaign: (state, action) => {
            state.campaign = action.payload;
        },
        setFileUploadProgress: (state, action) => {
            state.uploadProgress = action.payload;
        },
        setCampaignList: (state, action) => {
            const { list, count } = action.payload;
            state.list = list
            state.totalRecord = count
        },
    },
})

export const { setCampaign, setFileUploadProgress, setCampaignList } = campaignSlice.actions;
export default campaignSlice.reducer;

export const selectCampaign = (state) => state.campaigns.campaign;
export const selectFileUploadProgress = (state) => state.campaigns.uploadProgress;
export const selectCampaignList = (state) => state.campaigns.list;
export const selectCampaignTotalRecords = (state) => state.campaigns.totalRecord;