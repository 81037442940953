import React, { useEffect, useMemo, useState } from "react"
import { isEmpty } from "lodash"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useSelector, useDispatch } from "react-redux"
import { useBeforeunload } from "react-beforeunload"
import { isMobilePhone } from "../../../helpers/validators"

import {
  Button,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Spinner,
} from "reactstrap"
import { selectUser, setUser } from "redux/users/slice"
import { EMAIL_REGEX, MOBILE_NUMBER_REGEX, PASSWORD_REGEX } from "constants/regex"
import { useUserCreateMutation, useUserUpdateMutation } from "redux/users/api"
import { showError, showToaster } from "helpers"

function AddEditModal({
  isEdit,
  openModal,
  setOpenModal,
  setLoadList,
  setIsEdit,
}) {
  const dispatch = useDispatch()
  const user = useSelector(selectUser)

  const [passwordShow, setPasswordShow] = useState(false)
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false)

  useBeforeunload(() => "You may loss your data")

  const validationSchema = {
    firstname: Yup.string()
      .trim()
      .matches(/^\S*$/, 'Whitespace is not allowed')
      .required("Please enter First Name")
      .min(3, "Firstname should have minimum 3 characters")
      .max(20, "Firstname should have maximum 20 characters"),

    lastname: Yup.string()
      .trim()
      .matches(/^\S*$/, 'Whitespace is not allowed')
      .required("Please enter Last Name")
      .min(3, "Lastname should have minimum 3 characters")
      .max(20, "Lastname should have maximum 20 characters"),

    email: Yup.string()
      .trim()
      .matches(/^\S*$/, 'Whitespace is not allowed')
      .matches(EMAIL_REGEX, "Enter valid Email")
      .required("Please enter Email")
      .email("Enter valid Email"),

    phoneNumber: Yup.string()
      .trim()
      .optional()
      // .matches(/^\S*$/, 'Whitespace is not allowed')
      // .test(
      //   'validate-mobile-phone',
      //   function (value) {
      //     const valid = isMobilePhone(value);
      //     if (!valid) {
      //       return this.createError({
      //         path: this.path,
      //         message: 'Enter valid Phone Number',
      //       });
      //     } else {
      //       return true;
      //     }
      //   }
      // )
  }

  const initialValues = {
    firstname: (user && user.firstname) || "",
    lastname: (user && user.lastname) || "",
    email: (user && user.email) || "",
    phoneNumber: (user && user.phoneNumber) || "",
  }

  if (!isEdit) {
    validationSchema["password"] = Yup.string()
      .trim()
      .matches(/^\S*$/, 'Whitespace is not allowed')
      // .matches(
      //   PASSWORD_REGEX,
      //   'Password must contain 8 to 20 alphanumeric characters including 1 uppercase, 1 digit, 1 lowercase and 1 special characters'
      // )
      .required("Please enter Password")
      .min(8, "Password should have minimum 8 characters")
      .max(20, "Password should have maximum 20 characters")

    // validationSchema["confirmPassword"] = Yup.string()
    // .matches(/^\S*$/, 'Whitespace is not allowed')
    // .required("Please enter Confirm Password")
    // .oneOf(
    //   [Yup.ref("password"), null],
    //   "Confirm password must be same as passoword"
    // )

    initialValues.password = ""
    // initialValues.confirmPassword = ""
  }

  const [update, UpdateAPI] = useUserUpdateMutation()
  const [create, CreateAPI] = useUserCreateMutation()

  // formik validation & initialization
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: initialValues,

    validationSchema: Yup.object(validationSchema),
    onSubmit: async values => {
      if (!isEdit) {
        try {
          const result = await create(values).unwrap()
          setLoadList(state => !state)
          showToaster("success", result.message, "Success")
          toggle() 
          validation.resetForm()
        } catch (error) {
          showError(error)
        }
      } else {
        try {
          const result = await update({
            id: user._id,
            payload: values,
          }).unwrap()
          setLoadList(state => !state)
          showToaster("success", result.message, "Success")
          toggle()
          validation.resetForm();
        } catch (error) {
          showError(error)
        }
      }
    },
  })

  const toggle = () => {
    if (openModal) {
      setOpenModal(!openModal)
      dispatch(setUser(null))
      validation.resetForm()
      setIsEdit(false)
    }
  }

  return (
    <React.Fragment>
      <Modal isOpen={openModal} toggle={toggle} backdrop={"static"} centered={true}>
        <ModalHeader toggle={toggle} tag="h4">
          {!isEdit ? "Create User" : "Edit User"}
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <Row>
              <Col className="col-12">
                {/* first name  */}
                <div className="mb-3">
                  <Label className="form-label">First Name*</Label>
                  <Input
                    name="firstname"
                    type="text"
                    placeholder="Enter First Name"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.firstname || ""}
                    invalid={
                      validation.touched.firstname &&
                      validation.errors.firstname
                        ? true
                        : false
                    }
                  />
                  {validation.touched.firstname &&
                  validation.errors.firstname ? (
                    <FormFeedback type="invalid">
                      {validation.errors.firstname}
                    </FormFeedback>
                  ) : null}
                </div>

                {/* last name */}
                <div className="mb-3">
                  <Label className="form-label">Last Name*</Label>
                  <Input
                    name="lastname"
                    type="text"
                    placeholder="Enter Last Name"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.lastname || ""}
                    invalid={
                      validation.touched.lastname && validation.errors.lastname
                        ? true
                        : false
                    }
                  />
                  {validation.touched.lastname && validation.errors.lastname ? (
                    <FormFeedback type="invalid">
                      {validation.errors.lastname}
                    </FormFeedback>
                  ) : null}
                </div>

                {/* email */}
                <div className="mb-3">
                  <Label className="form-label">Email*</Label>
                  <Input
                    name="email"
                    type="text"
                    placeholder="Enter Email"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ""}
                    invalid={
                      validation.touched.email && validation.errors.email
                        ? true
                        : false
                    }
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <FormFeedback type="invalid">
                      {validation.errors.email}
                    </FormFeedback>
                  ) : null}
                </div>

                {/* phone number */}
                <div className="mb-3">
                  <Label className="form-label">Phone Number</Label>
                  <Input
                    name="phoneNumber"
                    type="text"
                    placeholder="Enter Phone Number"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.phoneNumber || ""}
                    invalid={
                      validation.touched.phoneNumber &&
                      validation.errors.phoneNumber
                        ? true
                        : false
                    }
                  />
                  {validation.touched.phoneNumber &&
                  validation.errors.phoneNumber ? (
                    <FormFeedback type="invalid">
                      {validation.errors.phoneNumber}
                    </FormFeedback>
                  ) : null}
                </div>

                {!isEdit && (
                  <>
                    {/* password */}
                    <div className="mb-3">
                      <Label className="form-label">Password*</Label>
                      <div className="input-group auth-pass-inputgroup">
                        <Input
                          name="password"
                          type={passwordShow ? "text" : "password"}
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                          onPaste={e => {
                            e.preventDefault()
                            return false
                          }}
                          onCopy={e => {
                            e.preventDefault()
                            return false
                          }}
                        />
                        <button
                          onClick={() => setPasswordShow(!passwordShow)}
                          className="btn btn-light"
                          type="button"
                          id="password-addon"
                        >
                          <i className={`mdi ${!passwordShow ? 'mdi-eye-outline' : 'mdi-eye-off-outline'}`}></i>
                        </button>
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>

                    {/* confirm password */}
                    {/* <div className="mb-3">
                      <Label className="form-label">Confirm Password*</Label>
                      <div className="input-group auth-pass-inputgroup">
                        <Input
                          name="confirmPassword"
                          type={confirmPasswordShow ? "text" : "password"}
                          placeholder="Enter Confirm Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.confirmPassword || ""}
                          invalid={
                            validation.touched.confirmPassword &&
                            validation.errors.confirmPassword
                              ? true
                              : false
                          }
                          onPaste={e => {
                            e.preventDefault()
                            return false
                          }}
                          onCopy={e => {
                            e.preventDefault()
                            return false
                          }}
                        />
                        <button
                          onClick={() => setConfirmPasswordShow(!confirmPasswordShow)}
                          className="btn btn-light "
                          type="button"
                          id="password-addon"
                        >
                           <i className={`mdi ${!confirmPasswordShow ? 'mdi-eye-outline' : 'mdi-eye-off-outline'}`}></i>
                        </button>
                        {validation.touched.confirmPassword &&
                        validation.errors.confirmPassword ? (
                          <FormFeedback type="invalid">
                            {validation.errors.confirmPassword}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div> */}
                  </>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <Button
                    type="submit"
                    className="btn btn-success save-user"
                    color="success"
                    disabled={ ( CreateAPI.isLoading || UpdateAPI.isLoading ) }
                  >
                    {!CreateAPI.isLoading && !UpdateAPI.isLoading ? (
                      <span>
                         { isEdit ? "Update" : "Create" }
                      </span>
                    ) : (
                      <>
                        <span>
                          {!isEdit ? "Creating..." : "Updating changes..."}
                        </span>
                        <Spinner size="sm"></Spinner>
                      </>
                    )}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default AddEditModal
