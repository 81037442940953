import React, { useState, useEffect } from "react"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import * as Yup from "yup"
import { useFormik, getIn } from "formik"
import { useSelector, useDispatch } from "react-redux"

import {
  Button,
  Col,
  Row,
  Form,
  Input,
  FormFeedback,
  Label,
  Spinner,
} from "reactstrap"
import { showError, showToaster } from "helpers"
import {
  useUserSettingValidateMutation,
  useUserUpdateMutation,
} from "redux/users/api"
import { selectCurrentUser, setAuthUser } from "redux/auth/slice"

function EmailVerifierSetting() {
  const dispatch = useDispatch()
  const user = useSelector(selectCurrentUser)
  const [tokenShow, setTokenShow] = useState(false)
  const [userUpdate, UserUpdateAPI] = useUserUpdateMutation()

  // formik validation & initialization
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      emailVerify: {
        token: user?.settings?.emailVerify?.token || "",
      },
    },
    validationSchema: Yup.object().shape({
      emailVerify: Yup.object().shape({
        token: Yup.string().trim()
        .matches(/^\S*$/, 'Whitespace is not allowed')
        .required("Please enter Token"),
      }),
    }),
    onSubmit: async values => {
      try {
        const result = await userUpdate({
          id: user._id,
          payload: { settings: values },
        }).unwrap();
        showToaster("success", "Changes Successfully Saved", "Success");
        formik.resetForm();
        dispatch(setAuthUser(result.data));
      } catch (error) {
        showError(error)
      }
    },
  });

  return (
    <React.Fragment>
      <Form
        onSubmit={e => {
          e.preventDefault()
          formik.handleSubmit()
          return false
        }}
      >
        <Row>
          <Col className="col-12">
            {/* password */}
            <div className="mb-3">
              <Label className="form-label">Token*</Label>
              <div className="input-group auth-pass-inputgroup">
                <Input
                  autoComplete="new-password"
                  name="emailVerify.token"
                  type={tokenShow ? "text" : "password"}
                  placeholder="Enter Token"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={getIn(formik.values, "emailVerify.token") || ""}
                  invalid={
                    getIn(formik.touched, "emailVerify.token") &&
                    getIn(formik.errors, "emailVerify.token")
                      ? true
                      : false
                  }
                />
                <button
                  onClick={() => setTokenShow(!tokenShow)}
                  className="btn btn-light "
                  type="button"
                  id="password-addon"
                >
                  <i
                    className={`mdi ${
                      !tokenShow ? "mdi-eye-outline" : "mdi-eye-off-outline"
                    }`}
                  ></i>
                </button>
                {getIn(formik.touched, "emailVerify.token") &&
                getIn(formik.errors, "emailVerify.token") ? (
                  <FormFeedback type="invalid">
                    {getIn(formik.errors, "emailVerify.token")}
                  </FormFeedback>
                ) : null}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-end">
              <Button
                type="submit"
                className="btn btn-success save-user"
                color="success"
                disabled={ UserUpdateAPI.isLoading }
              >
                {!UserUpdateAPI.isLoading ? (
                  "Validate & Save"
                ) : (
                  <>
                    <span>Validating Token...</span>
                    <Spinner size="sm"></Spinner>
                  </>
                )}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  )
}

export default EmailVerifierSetting;
