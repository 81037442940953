import PropTypes from "prop-types"
import React, { useState } from "react"

import {
  Row,
  Col,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
  Button,
  Spinner,
} from "reactstrap"

//redux
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// actions
import { setCredentials, logOut } from "../../redux/auth//slice"
import { useLoginMutation } from "redux/auth/api"
// import images
import logodark from "../../assets/images/email-op-4.png"
import logolight from "../../assets/images/logo-light.png"
import { Helmet } from "react-helmet"
import CarouselPage from "./CarouselPage"
import {  showError } from "helpers"
import { EMAIL_REGEX } from "constants/regex"

const Login = props => {
  const [passwordShow, setPasswordShow] = useState(false)
  const dispatch = useDispatch()
  const [login, loginAPI] = useLoginMutation()
  // const navigate = useNavigate()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .matches(/^\S*$/, "Whitespace is not allowed")
        .required("Please enter your email")
        .email("Please enter valid email")
        .matches(EMAIL_REGEX, "Please enter valid email"),
      password: Yup.string()
        .matches(/^\S*$/, "Whitespace is not allowed")
        .required("Please enter your password")
        .min(8, "Password should have minimum 8 characters")
        .max(20, "Password should have maximum 20 characters"),
    }),
    onSubmit: async values => {
      try {
        const result = await login(values).unwrap()
        localStorage.removeItem('IsSwitch')
        // console.log(result.data);
        dispatch(setCredentials(result.data))
      } catch (error) {
        showError(error)
      }
    },
  })

  return (
    <React.Fragment>
      <Helmet>
        <title>Sign In | Email Hunter</title>
      </Helmet>

      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />

            <Col xl={3}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5">
                      <Link to="/dashboard" className="d-block auth-logo">
                        <img
                          src={logodark}
                          alt=""
                          height="18"
                          className="logo-dark-element"
                        />
                        <img
                          src={logolight}
                          alt=""
                          height="18"
                          className="logo-light-element"
                        />
                      </Link>
                    </div>
                    <div className="my-auto">
                      <div>
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p className="text-muted">
                          Sign in to continue with email hunter.
                        </p>
                      </div>

                      <div className="mt-4">
                        <Form
                          className="form-horizontal"
                          onSubmit={e => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                          }}
                        >
                          <div className="mb-3">
                            <Label className="form-label">Email</Label>
                            <Input
                              name="email"
                              className="form-control"
                              placeholder="Enter Email"
                              type="text"
                              autoComplete="false"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email &&
                                validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <div className="float-end">
                              <Link
                                to="/forgot-password"
                                className="fw-medium text-muted"
                              >
                                Forgot Password?
                              </Link>
                            </div>
                            <Label className="form-label">Password</Label>
                            <div className="input-group auth-pass-inputgroup">
                              <Input
                                name="password"
                                value={validation.values.password || ""}
                                type={passwordShow ? "text" : "password"}
                                placeholder="Enter Password"
                                autoComplete="false"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.password &&
                                  validation.errors.password
                                    ? true
                                    : false
                                }
                              />
                              <button
                                onClick={() => setPasswordShow(!passwordShow)}
                                className="btn btn-light "
                                type="button"
                                id="password-addon"
                              >
                                <i
                                  className={`mdi ${
                                    !passwordShow
                                      ? "mdi-eye-outline"
                                      : "mdi-eye-off-outline"
                                  }`}
                                ></i>
                              </button>

                              {validation.touched.password &&
                              validation.errors.password ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.password}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>

                          <div className="mt-3 d-grid">
                            <Button
                              className="btn btn-primary btn-block "
                              color="primary"
                              type="submit"
                              disabled={loginAPI.isLoading}
                            >
                              {!loginAPI.isLoading ? (
                                "Sign In"
                              ) : (
                                <>
                                  <span>Signing In...</span>
                                  <Spinner size="sm"></Spinner>
                                </>
                              )}
                            </Button>
                          </div>
                        </Form>
                      </div>
                    </div>

                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} Email Hunter. Built with{" "}
                        <i className="mdi mdi-heart text-danger"></i> by
                        Webcodegenie
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
