import { createSlice } from "@reduxjs/toolkit"

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {fileRecord:[], campaignRecord: [],emailRecord:[],userRecords:[] },
    reducers: {
        setFileRecord: (state, action) => {
            const  data  = action.payload;
            state.fileRecord= data
        },
        setCampaignRecord: (state, action) => {
            const  data  = action.payload;
            state.campaignRecord = data
        },
        setEmailRecord: (state, action) => {
            const  data  = action.payload;
            state.emailRecord = data
        },
        setUserRecord: (state, action) => {
            const  data  = action.payload;
            state.userRecords = data
        },
    },
})

export const {setFileRecord, setCampaignRecord,setEmailRecord,setUserRecord } = dashboardSlice.actions;
export default dashboardSlice.reducer;

export const selectFileRecord = (state) => state.dashboard.fileRecord;
export const selectCompaingRecords = (state) => state.dashboard.campaignRecord;
export const selectEmailRecords = (state) => state.dashboard.emailRecord;
export const selectUserRecords = (state) => state.dashboard.userRecords;