import React from 'react';
import { Badge } from 'reactstrap';
import { wordTitleCase } from '../../helpers/data-formatters';

const FirstName = (cell) => {
    return wordTitleCase(cell.value);
};

const LastName = (cell) => {
    return wordTitleCase(cell.value);
};

const Email = (cell) => {
    return cell.value || '';
};

const ActivationStatus = (cell) => {
    return (
        <Badge
            className={"font-size-12 badge-soft-" +
                (cell.value ? "success" : "danger")}
        >
            {cell.value ? "Active" : 'In-active'}
        </Badge>
    );
};

export {
    FirstName,
    LastName,
    Email,
    ActivationStatus
};