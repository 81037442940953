import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setCredentials, logOut } from './auth/slice'
import config from '../config'
import { head } from 'lodash'

const baseQuery = fetchBaseQuery({
    baseUrl: config.API_URL,
    credentials: 'include',
    prepareHeaders: (headers, { getState, endpoint }) => {
        const token = getState().auth.token;
        if (token) {
            headers.set("authorization", `Bearer ${token}`);
        };
        return headers
    },
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    if ([401, 403].includes(result?.error?.status) && !extraOptions?.customRefresh) {
        // send refresh token to get new access token 
        const refreshResult = await baseQuery('/refresh', api, extraOptions);
        if (refreshResult?.data) {
            const user = api.getState().auth.user
            // store the new token 
            api.dispatch(setCredentials({ ...refreshResult.data, user }));
            // retry the original query with new access token 
            result = await baseQuery(args, api, extraOptions);
        } else {
            api.dispatch(logOut());
        }
    };
    return result
}

export const apiSlice = createApi({
    baseQuery: baseQueryWithReauth,
    endpoints: builder => ({}),
})