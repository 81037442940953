import React, { useCallback, useState } from "react"
import { Col, Row, Card, CardBody, Container, Button } from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
//Import Breadcrumb
import { Helmet } from "react-helmet"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import { showError } from "helpers"
import { useDispatch } from "react-redux"
import List from "./List"
const Campaigns = props => {
  const dispatch = useDispatch()
  const [loadList, setLoadList] = useState(true)
  const BreadcrumbDetails = {
    breadcrumbItem : {
      label : 'Emails',
      link : 'emails'
    },
    paths : []
  }
  return (
    <React.Fragment>
      <Helmet>
        <title>Emails | Email-Hunter</title>
      </Helmet>

      <div className="page-content">
        <Container fluid>
        <Breadcrumbs breadcrumbDetails={BreadcrumbDetails} />
          <Row>
            {/* <Col xs="12">
              <Card>
                <CardBody>
                  <div className="text-sm-end">
                    <Button
                      type="button"
                      color="primary"
                      className="btn mb-2 me-2"
                      // onClick={onAddCampaign}
                    >
                      <i className="mdi mdi-plus-circle-outline me-1" />
                      Export
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col> */}

            <Col xs="12">
              <Card>
                <CardBody>
                  <List
                    load={loadList}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Campaigns
