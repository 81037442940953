import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, UncontrolledTooltip } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import { useSelector, useDispatch } from "react-redux";
import { useEmailsRecordMutation } from "redux/dashboard/api";
import { selectEmailRecords, setEmailRecord } from "redux/dashboard/slice";
import { showError } from "helpers";
import { formatBigNumber } from "helpers/data-formatters";

const EmailsWithStatus = () => {
  const dispatch = useDispatch();
  const [getEmailsRecord, GetEmailApi] = useEmailsRecordMutation()
  const emailsRecord = useSelector(selectEmailRecords)
  const [chartData, setChartData] = useState([])
  // Fetch emails data on component mount
  useEffect(() => {
    const getData = async () => {
      try {
        const result = await getEmailsRecord().unwrap()
        dispatch(setEmailRecord(result.data))
      } catch (error) {
        showError(error)
      }
    }
    getData()

  }, [])
  // Convert email status counts to percentages when emailsRecord changes
  useEffect(() => {
    const convertToPercentage = (value) => {
      return formatBigNumber((100 * value) / emailsRecord?.all);
    };
    setChartData([
      convertToPercentage(emailsRecord?.ok),
      convertToPercentage(emailsRecord?.fail),
      convertToPercentage(emailsRecord?.unknown),
    ]);
  }, [emailsRecord]);
  const series = [...chartData]
  const options = {
    series: [...chartData],
    chart: {
      height: 370,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: 0,
        endAngle: 290,
        hollow: {
          margin: 5,
          size: "35%",
          background: "transparent",
          image: void 0,
        },
        track: {
          show: !0,
          startAngle: void 0,
          endAngle: void 0,
          background: "#f2f2f2",
          strokeWidth: "97%",
          opacity: 1,
          margin: 12,
          dropShadow: {
            enabled: !1,
            top: 0,
            left: 0,
            blur: 3,
            opacity: 0.5,
          },
        },
        dataLabels: {
          name: {
            show: !0,
            fontSize: "16px",
            fontWeight: 600,
            offsetY: -10,
          },
          value: {
            show: !0,
            fontSize: "14px",
            offsetY: 4,
            formatter: function (e) {
              return e + "%";
            },
          },
          total: {
            show: !0,
            label: "All",
            color: "#373d3f",
            fontSize: "16px",
            fontFamily: void 0,
            fontWeight: 600,
            formatter: function (e) {
              return (formatBigNumber(emailsRecord?.all))
            },
          },
        },
      },
    },
    stroke: {
      lineCap: "round",
    },
    legend: { show: !1 },
    colors: ["#14A44D", "#DC4C64", "#54B4D3"],
    labels: ["Ok", "Fail", "Unknown"],
  }

  return (
    <React.Fragment>
      <Col xl="12" md="12"   >
        <Card  >
          <CardBody  >
            <h4 className="card-title mb-3">Emails</h4>
            <Row>
              <Col lg="4" sm="12 " className=" d-flex gap-3 flex-column justify-content-center align-item-center align-self-center ">
                <div className="mx-5 col-auto">
                  <p className="mb-2">All</p>
                  <h5 className="pe-2 d-inline" id="all"> {formatBigNumber(emailsRecord?.all)}</h5>
                  <UncontrolledTooltip
                  placement="right"
                  target="all"
                >
                {formatBigNumber(emailsRecord?.all,true)}
                </UncontrolledTooltip>
                </div>
                <div className="mx-5">
                  <p className="mb-2">Uploaded</p>
                  <h5 className="pe-2 d-inline" id="uploaded"> {formatBigNumber(emailsRecord?.total)}</h5>
                  <UncontrolledTooltip
                  placement="right"
                  target="uploaded"
                >
                {formatBigNumber(emailsRecord?.total,true)}
                </UncontrolledTooltip>
                </div>
                <div className="mx-5">
                  <p className="mb-2">Valid</p>
                  <h5 className="pe-2 d-inline" id="valid">{formatBigNumber(emailsRecord?.valid)}</h5>
                  <UncontrolledTooltip
                  placement="right"
                  target="valid"
                >
                {formatBigNumber(emailsRecord?.valid,true)}
                </UncontrolledTooltip>
                </div>
              </Col>
              <Col lg="8" sm="12" className=" align-self-center" >
                <Row >
                  <div>
                    <div id="Email-Record-chart">
                      <ReactApexChart
                        options={options}
                        series={series}
                        type="radialBar"
                        height={370}
                        className="apex-charts"
                      />
                    </div>
                  </div>
                </Row>
                <Col lg="12" sm="12" className=" justify-content-center align-self-center d-flex gap-4   py-3  flex-end">
                  <p className="mb-2">
                    <i className="mdi mdi-circle align-middle font-size-20 me-2 " style={{ color: '#14A44D' }} />
                    Ok ( {`${formatBigNumber(emailsRecord?.ok)}`} )
                  </p>
                  <p className="mb-2">
                    <i className="mdi mdi-circle align-middle font-size-20 me-2 " style={{ color: '#DC4C64' }} />
                    Failed ( {formatBigNumber(emailsRecord?.fail)} )
                  </p>
                  <p className="mb-2">
                    <i className="mdi mdi-circle align-middle font-size-20 me-2 " style={{ color: '#54B4D3' }} />
                    Unknown ( {formatBigNumber(emailsRecord?.unknown)} )
                  </p>
                </Col>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default EmailsWithStatus;