import { apiSlice } from "../api"
import axios from "axios"
import config from "../../config"
import { setFileUploadProgress } from "./slice"
const API_URL_PREFIX = "black-list-domain"
export const campaignsApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    uploadBlackListedDomains: builder.mutation({
      queryFn: async (payload, api) => {
        try {
          const token = api.getState().auth.token
          const result = await axios.post(
            `${config.API_URL}/${API_URL_PREFIX}/`,
            payload,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              onUploadProgress: upload => {
                //Set the progress value to show the progress bar
                const uploadloadProgress = Math.round(
                  (100 * upload.loaded) / upload.total
                )
                api.dispatch(setFileUploadProgress(uploadloadProgress))
              },
            }
          )
          api.dispatch(setFileUploadProgress(0))
          return { data: result.data }
        } catch (error) {
          api.dispatch(setFileUploadProgress(0))
          throw error
        }
      },
    }),
    blackListedDomainList: builder.query({
      query: payload => ({
        url: `/${API_URL_PREFIX}/list`,
        method: "POST",
        body: { ...payload },
      }),
    }),
    removeBlackListedDomain: builder.mutation({
      query: id => {
        return {
          url: `/${API_URL_PREFIX}/${id}`,
          method: "DELETE",
        }
      },
    }),
  }),
})

export const {
  useUploadBlackListedDomainsMutation,
  useBlackListedDomainListQuery,
  useRemoveBlackListedDomainMutation
} = campaignsApiSlice
