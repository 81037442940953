import React from "react"
import ShowMoreText from "react-show-more-text"
import { formatDate } from "helpers/data-formatters"

const URL = cell => {
  return (
    <ShowMoreText lines={3} width={400} expanded={false}>
      {cell.value || ""}
    </ShowMoreText>
  )
}

const Domain = cell => {
  return cell.value || ""
}

const Text = cell => {
  return cell.value || ""
}

const Date = cell => {
  return formatDate(cell.value)
}

export { URL, Domain, Text, Date }
