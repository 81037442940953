import React, { Fragment, useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table"
import {
  Table,
  Row,
  Col,
  Button,
  Input,
  CardBody,
  Card,
  CardTitle,
  UncontrolledTooltip,
} from "reactstrap"
// import { Filter, DefaultColumnFilter } from "./filters"
import { ListId, ListStatus, MailCount, ListName } from "../ColumnFormatters"
import {
  useMailWizzListByCampaignMutation,
  useMailWizzListUpdateMutation,
} from "redux/mailWizzLists/api"
import { confirmAlert, showError, showToaster } from "helpers"
import { useSelector } from "react-redux"
import { selectCampaign } from "redux/campaigns/slice"
import LoadingSpinner from "components/Common/LoadingSpinner"
import { Link } from "react-router-dom"
import { selectCurrentUser } from "redux/auth/slice"

const MailWizzLists = () => {
  const campaign = useSelector(selectCampaign);
  const currentUser = useSelector(selectCurrentUser);
  const [list, setList] = useState([])
  const [listAPI, ListAPIStates] = useMailWizzListByCampaignMutation()
  const [updateList, UpdateListAPI] = useMailWizzListUpdateMutation()
  const [reload, setReload] = useState(false)

  const onReload = () => {
    setReload(!reload)
  }

  if (ListAPIStates.isError) {
    showError(ListAPIStates.error)
  }

  const onPauseResume = async data => {
    try {
      const confirmationMessage = `Are you sure want to ${
        data.status == "pause" ? "resume" : "pause"
      } this list ?`
      const confirmation = await confirmAlert(confirmationMessage)
      const payload = {
        status: data.status == "pause" ? "in-progress" : "pause",
      }
      if (confirmation.isConfirmed) {
        await updateList({ id: data._id, payload }).unwrap();
        setReload(!reload);
        showToaster('success', `List ${data.status == "pause" ? "resumed" : "paused"} Successfully !`);
      }
    } catch (error) {
        showError(error);
    }
  }

  useEffect(() => {
    (async () => {
      const result = await listAPI(campaign._id).unwrap()
      setList(result.data);
      setReload(false);
    })()

    return () => {
      setList([])
    }
  }, [campaign, reload])

  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        Cell: cellProps => {
          return <ListId {...cellProps} />
        },
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: cellProps => {
          return <ListName {...cellProps} />
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: cellProps => {
          return <ListStatus {...cellProps} />
        },
      },
      {
        Header: "Sent",
        accessor: "sent",
        Cell: cellProps => {
          return <MailCount {...cellProps} />
        },
      },
      {
        Header: "Rejected",
        accessor: "rejected",
        Cell: cellProps => {
          return <MailCount {...cellProps} />
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        disableSortBy: true,
        Cell: cellProps => {
          const currentList = cellProps.row.original
          return (
            <div className="d-flex gap-3">
              { currentUser && currentUser.role === 'admin' && ["pause", "in-progress"].includes(currentList.status) && (
                <Link
                  to="#"
                  className={`text-${
                    currentList.status == "pause" ? "success" : "primary"
                  }`}
                  onClick={() => {
                    onPauseResume(currentList)
                  }}
                >
                  <i
                    className={`mdi mdi-motion-${
                      currentList.status == "pause" ? "play" : "pause"
                    } font-size-20`}
                    id="pauseResumeTooltip"
                  />
                  <UncontrolledTooltip
                    placement="top"
                    target="pauseResumeTooltip"
                  >
                    {currentList.status == "pause"
                      ? "Resume List"
                      : "Pause List"}
                  </UncontrolledTooltip>
                </Link>
              )}
            </div>
          )
        },
      },
    ],
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    visibleColumns,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: list,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  )

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    gotoPage(page)
  }
  return (
    <Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">
            MailWizz Lists{" "}
            <i
              className={`bx bx-rotate-right bx-border bx-tada-hover`}
              onClick={onReload}
              title="reload"
              style={{ cursor: "pointer" }}
            ></i>
          </CardTitle>
          <Row className="mb-2">
            <Col md={2}>
              <select
                className="form-select"
                value={pageSize}
                onChange={onChangeInSelect}
              >
                {[10, 20, 30, 40, 50].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </Col>
          </Row>

          <div className="table-responsive react-table">
            <Table bordered hover {...getTableProps()}>
              <thead className="table-light table-nowrap">
                {headerGroups.map(headerGroup => (
                  <tr
                    key={headerGroup.id}
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map(column => (
                      <th key={column.id}>
                        <div
                          className="mb-2"
                          {...column.getSortByToggleProps()}
                        >
                          {column.render("Header")}
                          {generateSortingIndicator(column)}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody {...getTableBodyProps()}>
                {(ListAPIStates.isFetching || ListAPIStates.isLoading) && (
                  <tr>
                    <td
                      colSpan={visibleColumns.length}
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <LoadingSpinner />
                    </td>
                  </tr>
                )}

                {!page.length &&
                  !ListAPIStates.isFetching &&
                  !ListAPIStates.isLoading && (
                    <tr>
                      <td
                        colSpan={visibleColumns.length}
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <p>
                          {" "}
                          No List Found{" "}
                          <span
                            style={{
                              fontSize: "100px;",
                            }}
                          >
                            &#128581;
                          </span>
                        </p>
                      </td>
                    </tr>
                  )}

                {!ListAPIStates.isFetching &&
                  !ListAPIStates.isLoading &&
                  page.map(row => {
                    prepareRow(row)
                    return (
                      <Fragment key={row.getRowProps().key}>
                        <tr>
                          {row.cells.map(cell => {
                            return (
                              <td key={cell.id} {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            )
                          })}
                        </tr>
                      </Fragment>
                    )
                  })}
              </tbody>
            </Table>
          </div>

          <Row className="justify-content-md-end justify-content-center align-items-center">
            <Col className="col-md-auto">
              <div className="d-flex gap-1">
                <Button
                  color="primary"
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                >
                  {"<<"}
                </Button>
                <Button
                  color="primary"
                  onClick={previousPage}
                  disabled={!canPreviousPage}
                >
                  {"<"}
                </Button>
              </div>
            </Col>
            <Col className="col-md-auto d-none d-md-block">
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length ? pageOptions.length : 1}
              </strong>
            </Col>
            <Col className="col-md-auto">
              <Input
                type="number"
                min={1}
                style={{ width: 70 }}
                max={pageOptions.length}
                defaultValue={pageIndex + 1}
                onChange={onChangeInInput}
              />
            </Col>

            <Col className="col-md-auto">
              <div className="d-flex gap-1">
                <Button
                  color="primary"
                  onClick={nextPage}
                  disabled={!canNextPage}
                >
                  {">"}
                </Button>
                <Button
                  color="primary"
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  {">>"}
                </Button>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Fragment>
  )
}

export default MailWizzLists
