import { showError, showToaster } from "helpers"
import PropTypes from "prop-types"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Modal, ModalBody, Button, Spinner } from "reactstrap"
import { useCampaignUpdateMutation } from "redux/campaigns/api"
import { selectCampaign, setCampaign } from "redux/campaigns/slice"

const ArchiveModal = ({ show, setShow, setLoadList }) => {
  const dispatch = useDispatch()
  const campaign = useSelector(selectCampaign)
  const [update, UpdateAPI] = useCampaignUpdateMutation()

  const onCloseClick = () => {
    setShow(false)
    dispatch(setCampaign(null))
  }

  const onConfirmArchive = async () => {
    try {
      const result = await update({
        id: campaign._id,
        payload: { isArchived: true },
      }).unwrap()
      showToaster("success", "Campaign Successfully Archived", "Success")
      setLoadList(state => !state)
      setShow(false)
      dispatch(setCampaign(null))
    } catch (error) {
      showError(error)
    }
  }

  return (
    <Modal size="md" isOpen={show} toggle={onCloseClick} centered={true} keyboard={false}>
      <div className="modal-content">
        <ModalBody className="px-4 py-5 text-center">
          <button
            type="button"
            onClick={onCloseClick}
            className="btn-close position-absolute end-0 top-0 m-3"
          ></button>
            <p className="font-size-16 mb-4">
            <h3>Archive Campaign</h3>
          </p>
          <p className="text-muted font-size-16 mb-4">
            Are you sure you want to archive this campaign ?
          </p>

          <div className="hstack gap-2 justify-content-center mb-0">
            <Button
              type="button"
              color="danger"
              className="btn btn-danger"
              onClick={onConfirmArchive}
              disabled={UpdateAPI.isLoading}
            >
              {!UpdateAPI.isLoading ? (
                "Archive Now"
              ) : (
                <>
                  <span>{"Archiving Campaign..."}</span>
                  <Spinner size="sm"></Spinner>
                </>
              )}
            </Button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onCloseClick}
            >
              Cancel
            </button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default ArchiveModal
