import React, { useEffect, useRef, useState } from 'react'
import { Row, Col, Card, CardBody } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from 'react-redux';
import { selectCompaingRecords, setCampaignRecord } from 'redux/dashboard/slice';
import { useCompaingsRecordMutation } from 'redux/dashboard/api';
import { showError } from 'helpers';
import Flatpickr from "react-flatpickr";
import { addDays, subDays } from 'date-fns';
import { formatBigNumber } from 'helpers/data-formatters';
import moment from 'moment';
const Campaign = () => {
    // State variables
    const [duration, setDuration] = useState(7);
    const [selectedRange, setSelectedRange] = useState([addDays(new Date(), -6), new Date()]);
    const [chartData, setChartData] = useState({
        Evalidation: [],
        Escrapping: [],
        Xaxis: [],
        vTotal: 0,
        sTotal: 0,
    });

    // Ref and hooks
    const flatpickrRef = useRef(null);
    const dispatch = useDispatch();
    const compaingsRecord = useSelector(selectCompaingRecords);
    const [getCampaignRecord] = useCompaingsRecordMutation();

    // Utility functions
    // Function to handle the click of the duration buttons
    const handeClick = (day) => {
        setDuration(day)
        const rangeDate = subDays(new Date(), day)
        setSelectedRange([rangeDate, new Date()])
    }
    // Function to handle date changes in the Flatpickr
    const handleDateChange = (selectedDates) => {
        setSelectedRange(selectedDates);
        if (selectedDates?.length === 1) {
            const maxDate = addDays(selectedDates[0], 30);
            flatpickrRef.current.flatpickr.set('minDate', new Date(selectedDates[0]));
            flatpickrRef.current.flatpickr.set('maxDate', maxDate>new Date()?new Date():maxDate)
        } else {
            setDuration(selectedDates)
            // If date range is selected, clear minDate and maxDate restrictions
            flatpickrRef.current.flatpickr.set('maxDate', null);
            flatpickrRef.current.flatpickr.set('minDate', null);
        }
    };
    
    const handelOpen =()=>{
      // If date range is selected, clear minDate and maxDate restrictions
     flatpickrRef.current.flatpickr.set("maxDate", new Date())
   }

    // Function to generate chart data from campaigns data
    const generateChartData = () => {
        const chartdata = { Evalidation: [], Escrapping: [], Xaxis: [], vTotal: 0, sTotal: 0 }
        if (compaingsRecord?.campaigns) {
            compaingsRecord?.campaigns.map(data => {
                const { date, emailValidationCount, emailScrapingCount } = data
                chartdata.Evalidation = [...chartdata.Evalidation, emailValidationCount]
                chartdata.Escrapping = [...chartdata.Escrapping, emailScrapingCount]
                chartdata.Xaxis = [...chartdata.Xaxis, date]
                chartdata.vTotal += emailValidationCount
                chartdata.sTotal += emailScrapingCount
            })
            // Set the chart data
            setChartData({ Evalidation: chartdata.Evalidation, Escrapping: chartdata.Escrapping, Xaxis: chartdata.Xaxis, vTotal: chartdata.vTotal, sTotal: chartdata.sTotal })
        }
    }
    // Effect to fetch data when duration changes
    useEffect(() => {
        const getData = async () => {
            const startdate = moment(selectedRange[0]).format("YYYY-MM-DD");
            const enddate = moment(selectedRange[1]).format("YYYY-MM-DD");
            const timeDuration = { startDate: startdate, endDate: enddate }
            try {
                const result = await getCampaignRecord(timeDuration).unwrap()
                dispatch(setCampaignRecord(result.data))
            } catch (error) {
                showError(error)
            }
        }
        getData()

    }, [duration])
    // Effect to generate chart data when campaigns data changes
    useEffect(() => {
        generateChartData()
    }, [compaingsRecord?.campaigns])

    // Chart options and series
    const series = [
        {
            name: "Email Validation",
            data: chartData.Evalidation,
        },
        {
            name: "Email Scrapping",
            data: chartData.Escrapping,
        }
    ]
    const options = {
        chart: {
            height: 350,
            type: "area",
            toolbar: {
                show: false,
            },
        },
        colors: ["#FFC154", "#5b55aa"],
        dataLabels: {
            enabled: false,
            trim: false,
        },
        stroke: {
            curve: "smooth",
            width: 2,
        },
        fill: {
            type: "gradient",
            gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.45,
                opacityTo: 0.05,
                stops: [20, 100, 100, 100],
            },
        },
        xaxis: {
            categories: chartData.Xaxis.map(value => value[8] + value[9]),
        },

        markers: {
            size: 3,
            strokeWidth: 3,

            hover: {
                size: 4,
                sizeOffset: 2,
            },
        },
        legend: {
            position: "top",
            horizontalAlign: "right",
        },
        tooltip: {
            x: {
                formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                    return chartData.Xaxis[value - 1]
                }
            },
            y : {
                formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                    return formatBigNumber(value)
                }
            }
        }
    };
    return (
        <React.Fragment>
            <Col xl={12}>
                <Card>
                    <CardBody>
                        <Row>
                            <div className="clearfix">
                                <h4 className="card-title mb-4 float-start">Campaigns</h4>
                                <div className="float-end">
                                    <div className="input-group input-group-sm ">
                                        <div className="toolbar d-flex flex-wrap gap-2 text-end">
                                            <button type="button" className={`btn btn-light btn-sm ${duration == 7 ? "active" : ""}`} onClick={() => handeClick(7)}>
                                                Last 7 Day
                                            </button>{" "}
                                            <button type="button" className={`btn btn-light btn-sm ${duration == 15 ? "active" : ""}`} onClick={() => handeClick(15)}>
                                                Last 15 Day
                                            </button>{" "}
                                            <button type="button" className={`btn btn-light btn-sm me-1 ${duration == 31 ? "active" : ""}`} onClick={() => handeClick(31)}>
                                                Last Month
                                            </button>{" "}
                                        </div>
                                        <Flatpickr
                                            className="form-control d-block "
                                            placeholder="Search By Date Range..."
                                            ref={flatpickrRef}
                                            value={selectedRange}
                                            onOpen={handelOpen}
                                            onChange={handleDateChange}
                                            options={
                                                {
                                                    // Flatpickr options here (e.g., dateFormat, minDate, maxDate, etc.)
                                                    mode: "range",
                                                    maxDate: null,
                                                    showMonths: 2,
                                                    dateFormat: "YYYY-MM-DD",
                                                    type: 'range',
                                                    enableTime: false,
                                                    altInput: true,
                                                }
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <Col xl={3}>
                                <Row>
                                    <Col xl="12" lg='4' sm="4" className="mt-4">
                                        <div className="social-source text-center mt-3">
                                            <div className="avatar-xs mx-auto mb-3">

                                                <span
                                                    className={
                                                        "avatar-title rounded-circle font-size-20"
                                                    }
                                                >
                                                    <i
                                                        className={"mdi mdi-archive-arrow-down font-size-18"}
                                                    ></i>
                                                </span>
                                            </div>
                                            <p className="font-size-15">Archived</p>
                                            <p className="text-black mb-0 font-size-15"> {formatBigNumber(compaingsRecord?.archivedCampaign)}</p>
                                        </div>
                                    </Col >
                                    <Col xl="12" lg='4' sm="4" className="mt-4">
                                        <div className="social-source text-center mt-3">
                                            <div className="avatar-xs mx-auto mb-3">

                                                <span
                                                    className={
                                                        "avatar-title rounded-circle font-size-16"
                                                    }
                                                >
                                                    <i
                                                        className={"mdi mdi-archive-arrow-up font-size-18 text-white"}
                                                    ></i>
                                                </span>
                                            </div>
                                            <p className="font-size-15">UnArchived</p>
                                            <p className="text-black mb-0 font-size-15"> {formatBigNumber(compaingsRecord?.unArchivedCampaign)}</p>
                                        </div>
                                    </Col >
                                    <Col xl="12" lg='4' sm="4" className="mt-4">
                                        <div className="social-source text-center mt-3">
                                            <div className="avatar-xs mx-auto mb-3">

                                                <span
                                                    className={
                                                        "avatar-title rounded-circle font-size-16"
                                                    }
                                                >
                                                    <i
                                                        className={"mdi mdi-pause-circle font-size-18 text-white"}
                                                    ></i>
                                                </span>
                                            </div>
                                            <p className="font-size-15">Pause</p>
                                            <p className="text-black font-size-15 mb-0">{formatBigNumber(compaingsRecord?.pauseCampaign)}</p>
                                        </div>
                                    </Col >
                                </Row>
                            </Col>
                            <Col xl={9}>
                                <Row className="text-center">
                                    <Col >
                                        <div className="mt-4">
                                            <p className="text-muted mb-1">Email Validation</p>
                                            <h5>{formatBigNumber(chartData.vTotal)}</h5>
                                        </div>
                                    </Col>

                                    <Col >
                                        <div className="mt-4">
                                            <p className="text-muted mb-1">Email Scrapping</p>
                                            <h5>
                                                {formatBigNumber(chartData.sTotal)}
                                            </h5>
                                        </div>
                                    </Col>
                                </Row>
                                <hr className="mb-4" />
                                <Row>
                                    <Col>
                                        <div>
                                            <div id="area-chart" dir="ltr">
                                                <ReactApexChart
                                                    options={options}
                                                    series={series}
                                                    type="area"
                                                    height="350"
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    )
}

export default Campaign